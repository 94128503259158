import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  PrimaryButton,
  Stack,
  TooltipHost,
} from '@fluentui/react';
import { loader } from 'graphql.macro';
import { PurchaseOrderSearch_purchaseOrderSearch } from 'purchaseOrder/list/__generated__/PurchaseOrderSearch';
import { PurchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  NewPurchaseOrderData,
  NewPurchaseOrderDataVariables,
} from './__generated__/NewPurchaseOrderData';
import {
  PurchaseOrderCopy,
  PurchaseOrderCopyVariables,
} from './__generated__/PurchaseOrderCopy';
const NEW_PO = loader('./NewPurchaseOrderData.graphql');
const PURCHASE_ORDER_COPY = loader('./PurchaseOrderCopy.graphql');
const PURCHASE_ORDER_LIST_FIELDS = loader(
  '../../PurchaseOrderListFields.graphql'
);
const PURCHASE_ORDER_FRAGMENT_NAME = 'PurchaseOrderListFields';

interface POCopyProps {
  isNew: boolean;
  dataLoading: boolean;
  dirty: boolean;
  isSubmitting: boolean;
  purchaseOrderData: PurchaseOrder | undefined;
}
export const POCopy: React.FC<POCopyProps> = (props) => {
  const { purchaseOrderData } = { ...props };
  const history = useHistory();
  const client = useApolloClient();

  const [dialogVisibility, setDialogVisibility] = useState(false);
  const { addToast } = useToasts();
  const [copyPurchaseOrder] = useMutation<
    PurchaseOrderCopy,
    PurchaseOrderCopyVariables
  >(PURCHASE_ORDER_COPY);

  const [getNewPurchaseOrder, { data: newPOdata, loading: newPOLoading }] =
    useLazyQuery<NewPurchaseOrderData, NewPurchaseOrderDataVariables>(NEW_PO);

  const onConfirm = async () => {
    const { errors, data: responseData } = await copyPurchaseOrder({
      variables: {
        input: {
          entityId: purchaseOrderData?.purchaseOrder?.id!,
          rowTimestamp: purchaseOrderData?.purchaseOrder?._rowTimestamp!,
        },
      },
    });

    if (errors?.length) {
      addToast(errors?.[0].message, { appearance: 'error' });
    } else {
      addToast('Purchase order copied', { appearance: 'success' });

      getNewPurchaseOrder({
        variables: { id: responseData?.purchaseOrderCopy?.newPurchaseOrder! },
      });
    }
  };
  useEffect(() => {
    if (newPOdata) {
      history.push(
        `/purchase-orders/purchase-order/${newPOdata?.purchaseOrder?.id}`
      );
      client.cache.modify({
        fields: {
          purchaseOrderSearch(
            existing: PurchaseOrderSearch_purchaseOrderSearch
          ) {
            const newPORef = client.cache.writeFragment({
              data: newPOdata.purchaseOrder,
              fragment: PURCHASE_ORDER_LIST_FIELDS,
              fragmentName: PURCHASE_ORDER_FRAGMENT_NAME,
            });
            return {
              ...existing,
              nodes: [{ ...newPORef }, ...existing.nodes],
            };
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPOdata]);

  return (
    <Stack>
      <TooltipHost content={'Clone'}>
        <IconButton
          iconProps={{ iconName: 'Copy' }}
          onClick={() => setDialogVisibility(true)}
        />
      </TooltipHost>
      {dialogVisibility && (
        <Dialog
          hidden={!dialogVisibility}
          onDismiss={() => setDialogVisibility(false)}
          dialogContentProps={{
            title: `Create Purchase Order (Clone)`,
            type: DialogType.largeHeader,
            subText:
              'Please confirm to create a new purchase order using the values from the currently viewed purchase order',
          }}
          minWidth={400}
        >
          <DialogFooter>
            <PrimaryButton
              text="Confirm"
              onClick={() => {
                setDialogVisibility(false);
                onConfirm();
              }}
            />
            <DefaultButton
              onClick={() => {
                setDialogVisibility(false);
              }}
              text="Cancel"
            />
          </DialogFooter>
        </Dialog>
      )}
    </Stack>
  );
};
