import {
  NetworkStatus,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  IColumn,
  Icon,
  IconButton,
  Spinner,
  Stack,
  Text,
  TooltipHost,
  useTheme,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

import { BlockBlobClient } from '@azure/storage-blob';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { DownloadButton } from 'common/components/DownloadButton';
import { HighLightActiveLink } from 'common/components/HighLight';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { CompanyCurrencies } from 'common/graphql/__generated__/CompanyCurrencies';
import {
  DocumentPoolFiles,
  DocumentPoolFilesVariables,
  DocumentPoolFiles_documentPools_nodes,
} from 'common/graphql/__generated__/DocumentPoolFiles';
import {
  EntityDocumentDelete,
  EntityDocumentDeleteVariables,
} from 'common/graphql/__generated__/EntityDocumentDelete';
import {
  OnDocumentUploadStatus,
  OnDocumentUploadStatus_documentUploadStatus_document,
} from 'common/graphql/__generated__/OnDocumentUploadStatus';
import { useCommonStyles } from 'common/styles';
import {
  DocumentPoolsOrderBy,
  EntityDeleteInput,
  EntityDocumentFilter,
  EntityDocumentsOrderBy,
  UploadStatusType,
} from 'common/types/globalTypes';
import { EntityType } from 'common/types/utility';
import { formatDropdownOptions } from 'common/utils';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection } from 'common/utils/commonTypes';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
  invoiceDateFormat,
} from 'common/utils/dateFormats';
import { fileType, fileTypeColor } from 'common/utils/fileType';
import { historyFilterObject } from 'documents/Filters/ListFilterCallout';
import {
  GetDocumentPoolCommonData,
  GetDocumentPoolCommonDataVariables,
} from 'documents/__generated__/GetDocumentPoolCommonData';
import { DocumentTypeOptions } from 'documents/personalPool/UploadDocumentsForm';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  expandedNav,
  setActivePoolDocument,
  setGlobalSearchText,
} from 'utility/cache/ui';
import { DocumentAssignmentLocationState } from 'utility/locationStates';
import {
  DocumentPoolUploadDocument,
  DocumentPoolUploadDocumentVariables,
} from '../../__generated__/DocumentPoolUploadDocument';
import { DocumentLoaderShimmer } from '../Shimmer';
import {
  DocumentPoolDocuments,
  DocumentPoolDocumentsVariables,
  DocumentPoolDocuments_documentPool_entityDocumentsByDocumentPoolIdAndEntityTypeId_nodes,
} from '../__generated__/DocumentPoolDocuments';
import { useColumns } from '../column.data';
import {
  getFiltersFromState,
  nodeMapType,
  toFilterOrVariable,
  toFilterVariable,
  toOrderByVariable,
} from '../utils';
import { FolderViewHeader, filterOptionProps } from './FolderViewHeader';
import { UploadDocumentForm } from './FolderViewHeader/UploadForm';
import { LeftPanel } from './LeftPanel';
import { useStyles } from './index.styles';

const UPLOAD_DOCUMENT = loader('../../DocumentPoolUploadDocument.graphql');
const GENERAL_DATA = loader(
  '../../../../../documents/GetDocumentPoolCommonData.graphql'
);
const DOCUMENT_POOL_DATA = loader(
  '../../../../../common/graphql/DocumentPool.graphql'
);
const DOCUMENT_POOL_DOCUMENTS = loader('../DocumentPoolDocuments.graphql');
const CURRENCY_DATA = loader(
  '../../../../../common/graphql/CompanyCurrencies.graphql'
);
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);
const DOCUMENT_DELETE = loader(
  '../../../../../common/graphql/EntityDocumentDelete.graphql'
);

type DocumentListItemType =
  DocumentPoolDocuments_documentPool_entityDocumentsByDocumentPoolIdAndEntityTypeId_nodes;
export type DocumentEntity =
  DocumentPoolDocuments_documentPool_entityDocumentsByDocumentPoolIdAndEntityTypeId_nodes & {
    documentType: string;
    usedTotal: string;
    remainingTotal: string;
    isoCode: string;
    _uploadDate: string;
  };
interface FolderViewProps {
  documentPool: nodeMapType;
  rootNodes: nodeMapType[] | undefined;
}
export const FolderView: React.FC<FolderViewProps> = ({
  documentPool,
  rootNodes,
}) => {
  const { addToast, updateToast } = useToasts();
  const { cache } = useApolloClient();
  const { columns } = useColumns();
  const theme = useTheme();
  const [selectedList, setSelectedList] = useState<DocumentEntity[]>([]);
  const windowHeight = window.innerHeight;
  const [hideUploadDialog, { toggle: toggleUploadDialog }] = useBoolean(false);
  const [deleteDocuments] = useMutation<
    EntityDocumentDelete,
    EntityDocumentDeleteVariables
  >(DOCUMENT_DELETE, { errorPolicy: 'all' });
  const [leftPanelWidth, setLeftPanelWidth] = useState<number>(350);
  const [documentOptions, setDocumentOptions] = useState<DocumentTypeOptions[]>(
    []
  );

  const globalSearchText = useReactiveVar(setGlobalSearchText);
  const client = useApolloClient();
  const [isHistory, setIsHistory] = useState(false);
  const [defaultDocumentTypeId, setDefaultDocumentTypeId] = useState<number>();
  const [extractionTypeId, setExtractionTypeId] = useState<number | null>(null);
  const [isDocumentTypeSelector, setIsDocumentTypeSelector] =
    useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] =
    useState<DocumentListItemType | null>(null);
  const documentPoolIdRef = useRef(documentPool.id);
  const updateDocumentPoolIdRef = (newItems: string) => {
    documentPoolIdRef.current = newItems;
  };
  // Function to handle resizing of the left panel
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const startX = e.clientX;
    const startWidth = leftPanelWidth;

    const handleMouseMove = (moveEvent: MouseEvent) => {
      const newWidth = startWidth + (moveEvent.clientX - startX);
      setLeftPanelWidth(newWidth);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };
  const { data: GeneralData, loading: generalDataLoading } = useQuery<
    GetDocumentPoolCommonData,
    GetDocumentPoolCommonDataVariables
  >(GENERAL_DATA, {
    variables: {
      isDocumentUpload: true,
    },
  });

  const [filterOptions, setFilterOptions] = useState<
    filterOptionProps | undefined
  >({ filterTypes: [historyFilterObject], startsWith: true });
  const { data: currencyData } = useQuery<CompanyCurrencies>(CURRENCY_DATA);

  const currencyTypes = formatDropdownOptions(
    currencyData?.companyCurrencies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.isoCode + '-' + item.name!,
      includeAll: false,
    }
  );
  const filterDocumentOptions = (
    documentPoolItem: DocumentPoolFiles_documentPools_nodes
  ) => {
    const dataArr = GeneralData?.documentPoolAvailableDocumentTypes?.nodes
      .filter(
        (defaultDocumentTypeId) =>
          defaultDocumentTypeId.id === documentPoolItem.defaultDocumentTypeId
      )
      .map((item) => ({
        key: item.id,
        text: item?.documentType! || '',
        isAccountingDocument: item?.isAccountingDocument!,
        isSigningRequired: item.isSigningRequired,
        extractionTypes: item.extractionTypes,
      }));

    return dataArr;
  };
  const withoutFilterDocumentOptions = () => {
    const documentTypes =
      GeneralData?.documentPoolAvailableDocumentTypes?.nodes.map((item) => ({
        key: item.id,
        text: item?.documentType! || '',
        isAccountingDocument: item?.isAccountingDocument!,
        isSigningRequired: item.isSigningRequired,
        extractionTypes: item.extractionTypes,
      })) || [];
    return documentTypes;
  };

  const documentTypesOptions = formatDropdownOptions(
    GeneralData?.documentTypeUploads?.nodes,
    {
      getKey: (item) => item.id!,
      getText: (item) => item.documentType || '',
      includeAll: false,
    }
  );
  const refreshDocumentList = (
    document: OnDocumentUploadStatus_documentUploadStatus_document
  ) => {
    if (activeDocument?.id) {
      const entityCacheData = cache.readQuery<
        DocumentPoolDocuments,
        DocumentPoolDocumentsVariables
      >({
        query: DOCUMENT_POOL_DOCUMENTS,
        variables: {
          ...entityDocumentVariables,
          id: activeDocument?.id,
        },
      });
      if (entityCacheData?.documentPool) {
        const entityNewData: DocumentPoolDocuments = {
          documentPool: {
            ...entityCacheData?.documentPool,
            id: activeDocument?.id,
            entityDocumentsByDocumentPoolIdAndEntityTypeId: {
              ...entityCacheData?.documentPool
                ?.entityDocumentsByDocumentPoolIdAndEntityTypeId,
              totalCount:
                entityCacheData?.documentPool
                  ?.entityDocumentsByDocumentPoolIdAndEntityTypeId?.totalCount +
                1,
              nodes: [
                { ...document },
                ...entityCacheData?.documentPool
                  ?.entityDocumentsByDocumentPoolIdAndEntityTypeId.nodes,
              ],
            },
          },
        };
        cache.writeQuery<DocumentPoolDocuments, DocumentPoolDocumentsVariables>(
          {
            query: DOCUMENT_POOL_DOCUMENTS,
            data: entityNewData,
            variables: {
              ...entityDocumentVariables,
              id: activeDocument?.id,
            },
          }
        );
        const poolsCacheData = cache.readQuery<
          DocumentPoolFiles,
          DocumentPoolFilesVariables
        >({
          query: DOCUMENT_POOL_DATA,
        });
        if (poolsCacheData) {
          const poolData = poolsCacheData?.documentPools?.nodes.map((pool) => {
            if (pool.id === activeDocument?.id) {
              const newPoolData: DocumentPoolFiles_documentPools_nodes = {
                ...pool,
                _documentFileCount: {
                  ...pool._documentFileCount!,
                  selectedDocuments:
                    pool._documentFileCount?.selectedDocuments || 0,
                  availableDocuments:
                    (pool._documentFileCount?.availableDocuments || 0) + 1,
                },
              };
              return newPoolData;
            }
            return pool;
          });
          if (poolData) {
            const updatedPools: DocumentPoolFiles = {
              ...poolsCacheData,
              documentPools: {
                ...poolsCacheData?.documentPools!,
                nodes: [...poolData!],
              },
            };
            cache.writeQuery<DocumentPoolFiles, DocumentPoolFilesVariables>({
              query: DOCUMENT_POOL_DATA,
              data: updatedPools,
            });
          }
        }
      }
    }
  };
  const [uploadDocument] = useMutation<
    DocumentPoolUploadDocument,
    DocumentPoolUploadDocumentVariables
  >(UPLOAD_DOCUMENT);

  useEffect(() => {
    const historyPos = filterOptions?.filterTypes?.findIndex(
      (item) => item.filterKey === '_isSelected'
    );
    if (historyPos !== -1) {
      setIsHistory(
        filterOptions?.filterTypes[historyPos!].value.toString() === 'true'
      );
    }
  }, [filterOptions]);
  useEffect(() => {
    setActivePoolDocument(documentPool);
  }, [documentPool?.id]);

  let documentListData: DocumentEntity[] = [];

  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const history = useHistory<DocumentAssignmentLocationState | undefined>();
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);

  const activeDocument = useReactiveVar(setActivePoolDocument);
  const [getDocumentPoolData] = useLazyQuery<
    DocumentPoolFiles,
    DocumentPoolFilesVariables
  >(DOCUMENT_POOL_DATA, {
    variables: {
      orderBy: [DocumentPoolsOrderBy.NAME_ASC],
      filter: {
        defaultDocumentTypes: {
          isReportingDocument: {
            equalTo: false,
          },
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const [
    getEntityDocuments,
    {
      data: entityDocumentsData,
      loading: entityDocumentsLoading,
      variables: entityDocumentVariables,
      networkStatus,
      fetchMore,
    },
  ] = useLazyQuery<DocumentPoolDocuments, DocumentPoolDocumentsVariables>(
    DOCUMENT_POOL_DOCUMENTS,
    {
      variables: {
        id: activeDocument?.id!,
        first: TABLE_ROWS,
        orderBy: [EntityDocumentsOrderBy._UPLOAD_DATE_DESC],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const { nodes, totalCount, pageInfo } = {
    ...entityDocumentsData?.documentPool
      ?.entityDocumentsByDocumentPoolIdAndEntityTypeId,
  };
  type DocumentPoolItemType = DocumentPoolFiles_documentPools_nodes;

  const loadMore = useCallback(async () => {
    const newVariables: DocumentPoolDocumentsVariables = {
      ...entityDocumentVariables,
      id: activeDocument?.id!,
      after: pageInfo?.endCursor,
    };

    await fetchMore?.({
      variables: { ...newVariables },
    });
  }, [fetchMore, entityDocumentVariables, pageInfo, activeDocument]);
  const refreshDocumentListOnDelete = (deletedRowsCount: number) => {
    const poolsCacheData = cache.readQuery<
      DocumentPoolFiles,
      DocumentPoolFilesVariables
    >({
      query: DOCUMENT_POOL_DATA,
    });
    if (poolsCacheData) {
      const poolData = poolsCacheData?.documentPools?.nodes.map((pool) => {
        if (pool.id === activeDocument?.id) {
          const newPoolData: DocumentPoolFiles_documentPools_nodes = {
            ...pool,
            _documentFileCount: {
              ...pool._documentFileCount!,
              selectedDocuments:
                pool._documentFileCount?.selectedDocuments || 0,
              availableDocuments:
                (pool._documentFileCount?.availableDocuments || 0) -
                deletedRowsCount,
            },
          };
          return newPoolData;
        }
        return pool;
      });
      if (poolData) {
        const updatedPools: DocumentPoolFiles = {
          ...poolsCacheData,
          documentPools: {
            ...poolsCacheData?.documentPools!,
            nodes: [...poolData!],
          },
        };
        cache.writeQuery<DocumentPoolFiles, DocumentPoolFilesVariables>({
          query: DOCUMENT_POOL_DATA,
          data: updatedPools,
        });
      }
    }
    setSelectedList([]);
    addToast('Record deleted Successfully', {
      appearance: 'success',
    });
  };
  const _onConfirm = async () => {
    const deletedRows: DocumentEntity[] = selectedList.filter(
      (item) => item._isDeletable
    );
    const selectedData: EntityDeleteInput[] = selectedList
      ?.filter((entity) => entity._isDeletable)
      .map((obj) => {
        return { id: obj.id, rowTimestamp: obj._rowTimestamp! };
      });

    const { errors } = await deleteDocuments({
      variables: {
        input: {
          entityDelete: selectedData,
        },
      },
      update(cache, { data: response }) {
        if (
          response?.entityDocumentDelete?.deletedEntities &&
          response?.entityDocumentDelete?.deletedEntities?.length > 0
        ) {
          deletedRows.forEach((item) => {
            const identity = cache.identify({
              ...item,
            });
            cache.evict({ id: identity });
            cache.gc();
          });
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: DOCUMENT_POOL_DATA,
          variables: {
            orderBy: [DocumentPoolsOrderBy.NAME_ASC],
            filter: {
              defaultDocumentTypes: {
                isReportingDocument: {
                  equalTo: false,
                },
              },
            },
          },
        },
      ],
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      setActivePoolDocument(
        activeDocument
          ? {
              ...activeDocument,
              _documentFileCount: {
                availableDocuments:
                  activeDocument?._documentFileCount?.availableDocuments! -
                  deletedRows.length,
                selectedDocuments:
                  activeDocument?._documentFileCount?.selectedDocuments ?? 0,
              },
            }
          : undefined
      );
      refreshDocumentListOnDelete(deletedRows.length);
    }
  };

  const _renderItemColumn = (
    item: DocumentEntity | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof DocumentEntity
      ] as string;

      switch (column?.key) {
        case '_isLoading':
          if (item._isLoading) return <Spinner />;
          break;
        case 'documentType':
          return (
            <HighLightActiveLink
              highlightText={globalSearchText}
              text={item?.documentType}
              to={`/doc/documentAssignment/document/${item.id}/${activeDocument?.id}`}
              onClick={() => {
                expandedNav(true);
                history.push(
                  `/doc/documentAssignment/document/${item.id}/${activeDocument?.id}`
                );
                setDocViewState({ isOpen: false, _fileType: 'pdf' });
              }}
            />
          );
        case '_uploadDate':
          return (
            <Stack
              className={styles.onrenderColumnStack}
              verticalAlign="center"
            >
              <Text className={styles.contentColumnAlignRight}>
                {getGlobalDateFormat(item._uploadDate || '')}
              </Text>
            </Stack>
          );
        case 'usedTotal':
        case 'indexAmount':
        case 'remainingTotal':
          return (
            <Stack
              verticalAlign="center"
              className={clsx(styles.textAlignEnd, styles.onrenderColumnStack)}
            >
              <AmountTextView value={fieldContent} />
            </Stack>
          );
        case 'fileReference':
          return (
            <Stack
              verticalAlign={'center'}
              horizontal
              className={styles.onrenderColumnStack}
              tokens={{ childrenGap: 10 }}
            >
              <Icon
                className={fileTypeColor(item.iconType || 'OTHER')}
                iconName={fileType(item.iconType || 'OTHER')}
              />
              <Text>{fieldContent}</Text>
            </Stack>
          );
        case 'view':
          const viewDocumentVisible =
            item._isProtected! || item._fileViewer !== 'browser';
          return (
            <Stack
              className={styles.columnHeight}
              tokens={{ childrenGap: 10 }}
              horizontal
              verticalAlign="center"
            >
              <TooltipHost content="View" id="tooltipId">
                <IconButton
                  disabled={viewDocumentVisible}
                  iconProps={{ iconName: 'View' }}
                  onClick={() =>
                    setDocViewState({
                      isOpen: true,
                      title: item.fileReference,
                      entityDocumentId: item.id,
                      _fileType: item._fileType!,
                    })
                  }
                />
              </TooltipHost>
            </Stack>
          );
        case 'download':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
              horizontalAlign={'center'}
            >
              <DownloadButton entityDocumentId={item.id} />
            </Stack>
          );

        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              {fieldContent && <Text>{fieldContent}</Text>}
            </Stack>
          );
      }
    }
  };
  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;

  const transformedData = refetching
    ? undefined
    : nodes
        ?.filter((item) => (!isHistory ? !item._isSelected : true))
        .map(
          (doc) =>
            ({
              ...doc,
              documentType: doc.documentTypes?.documentType,
              usedTotal: doc?.documentAppliedAmounts?.usedTotal,
              remainingTotal: doc?.documentAppliedAmounts?.remainingTotal,
              isoCode: doc?.currency?.isoCode,
              _uploadDate: getGlobalDateFormat(doc._uploadDate || ''),
              indexTransactionDate: doc.indexTransactionDate
                ? dateFormat(dateConvertions(doc.indexTransactionDate))
                : null,
            } as DocumentEntity)
        );
  if (transformedData) documentListData = transformedData;

  const _onColumnClick = (clickedColumn: ColumnData): void => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    getEntityDocuments({
      variables: {
        ...entityDocumentVariables,
        id: documentPoolIdRef.current,
        orderBy: toOrderByVariable({
          column: clickedColumn.key,
          direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
        }),
      },
    });
  };
  useEffect(() => {
    if (activeDocument?.id) {
      const { filterTypes: filters, startsWith } = {
        ...filterOptions,
      };
      const searchOption = startsWith
        ? 'startsWithInsensitive'
        : 'includesInsensitive';
      const filtersApplied =
        filters?.length && filters?.length > 0
          ? ({
              and: toFilterVariable(filters, searchOption),
              or: toFilterOrVariable(filters),
            } as EntityDocumentFilter)
          : undefined;

      setFilterOptions(filterOptions);
      getEntityDocuments({
        variables: {
          ...entityDocumentVariables,
          id: activeDocument?.id,
          filter: {
            and: [{ _isSelected: { equalTo: false } }],
            ...filtersApplied,
          },
        },
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDocument?.id]);

  const onFilterChange = (
    filterOptions: filterOptionProps,
    documentPool: DocumentPoolItemType
  ) => {
    const { filterTypes: filters, startsWith } = {
      ...filterOptions,
    };

    const historyPos = filterOptions?.filterTypes?.findIndex(
      (item) => item.filterKey === '_isSelected'
    );

    const searchOption = startsWith
      ? 'startsWithInsensitive'
      : 'includesInsensitive';

    const filtersApplied =
      filters?.length > 0
        ? ({
            and: toFilterVariable(filters, searchOption),
            or: toFilterOrVariable(filters),
          } as EntityDocumentFilter)
        : undefined;

    setFilterOptions(filterOptions);
    getEntityDocuments({
      variables: {
        ...entityDocumentVariables,
        id: activeDocument?.id!,
        filter: {
          ...filtersApplied,
        },
      },
    });

    setIsHistory(filters[historyPos].value === true);
  };

  const _headerOnClick = (
    poolId: string,
    documentPoolItem: nodeMapType,
    filterRouteState?: string | undefined
  ) => {
    updateDocumentPoolIdRef(documentPoolItem.id);
    if (documentPoolItem?.defaultDocumentTypeId) {
      if (documentPoolItem.isDocumentTypeSelector) {
        setDocumentOptions(filterDocumentOptions(documentPoolItem) || []);
      } else setDocumentOptions(withoutFilterDocumentOptions);
    } else {
      setDocumentOptions(withoutFilterDocumentOptions);
    }
    setDefaultDocumentTypeId(documentPoolItem?.defaultDocumentTypeId!);
    setIsDocumentTypeSelector(documentPoolItem?.isDocumentTypeSelector!);
    setExtractionTypeId(documentPoolItem?.extractionTypeId!);
    const { andFilters, orFilters } = getFiltersFromState(filterRouteState!);

    setFilterOptions({
      filterTypes: [...andFilters, ...orFilters],
      startsWith: true,
    });
    setFilterOptions(filterOptions);
    if (activeDocument?.id !== poolId) {
      getEntityDocuments({
        variables: {
          ...entityDocumentVariables,
          id: poolId,
        },
      });
    }
  };
  const onSuccess = (poolMovedToId: string, filesMovedCount: number) => {
    getDocumentPoolData();
    const cacheData = cache.readQuery<
      DocumentPoolDocuments,
      DocumentPoolDocumentsVariables
    >({
      query: DOCUMENT_POOL_DOCUMENTS,
      variables: entityDocumentVariables,
    });

    const poolsCacheData = cache.readQuery<
      DocumentPoolFiles,
      DocumentPoolFilesVariables
    >({
      query: DOCUMENT_POOL_DATA,
    });

    const filteredList =
      cacheData?.documentPool?.entityDocumentsByDocumentPoolIdAndEntityTypeId?.nodes.filter(
        (ele: any) =>
          selectedList.findIndex((item) => item.id === ele.id) === -1
      );

    const updatedEntityDocuments: DocumentPoolDocuments = {
      documentPool: {
        ...entityDocumentsData?.documentPool,
        id: documentPoolIdRef.current,
        _rowTimestamp: entityDocumentsData?.documentPool?._rowTimestamp ?? null,
        entityDocumentsByDocumentPoolIdAndEntityTypeId: {
          ...entityDocumentsData?.documentPool
            ?.entityDocumentsByDocumentPoolIdAndEntityTypeId,
          totalCount: totalCount! - selectedList.length,
          nodes: filteredList!,
          pageInfo: { ...pageInfo! },
        },
      },
    };
    const poolData = poolsCacheData?.documentPools?.nodes.map((pool) => {
      if (pool.id === poolMovedToId) {
        const newPoolData: DocumentPoolFiles_documentPools_nodes = {
          ...pool,
          _documentFileCount: {
            ...pool._documentFileCount!,
            availableDocuments:
              pool._documentFileCount?.availableDocuments! + filesMovedCount,
          },
        };
        return newPoolData;
      }
      if (pool.id === activeDocument?.id) {
        const newPoolData: DocumentPoolFiles_documentPools_nodes = {
          ...pool,
          _documentFileCount: {
            ...pool._documentFileCount!,
            availableDocuments:
              pool._documentFileCount?.availableDocuments! - filesMovedCount,
          },
        };
        return newPoolData;
      }
      return pool;
    });

    const updatedPools: DocumentPoolFiles = {
      ...poolsCacheData,
      documentPools: {
        ...poolsCacheData?.documentPools!,
        nodes: [...poolData!],
      },
    };
    cache.writeQuery<DocumentPoolFiles, DocumentPoolFilesVariables>({
      query: DOCUMENT_POOL_DATA,
      data: updatedPools,
    });

    cache.writeQuery<DocumentPoolDocuments, DocumentPoolDocumentsVariables>({
      query: DOCUMENT_POOL_DOCUMENTS,
      variables: entityDocumentVariables,
      data: updatedEntityDocuments,
    });
  };
  const onBuildComplete = () => {
    selectedList.forEach((item) => {
      cache.evict({ id: `EntityDocument:${item.id}` });
    });

    cache.gc();

    const nodesLength = selectedList.length;
    cache.modify({
      id: `DocumentPool:${activeDocument?.id}`,
      fields: {
        _documentFileCount: (existingData) => {
          return {
            ...existingData,
            selectedDocuments: existingData.selectedDocuments + nodesLength,
            availableDocuments: existingData.availableDocuments - nodesLength,
          };
        },
      },
    });
  };
  if (generalDataLoading) return <DocumentLoaderShimmer />;
  return (
    <Stack horizontal tokens={{ childrenGap: 0 }} style={{ overflow: 'auto' }}>
      {hideUploadDialog && (
        <UploadDocumentForm
          documentOptions={documentOptions}
          companyCurrencies={currencyTypes}
          documentData={selectedDocument || null}
          defaultDocumentTypeId={defaultDocumentTypeId}
          isDocumentTypeSelector={isDocumentTypeSelector}
          extractionTypeId={extractionTypeId}
          visible={hideUploadDialog}
          onDismiss={toggleUploadDialog}
          uploadDocument={{
            uploadDocumentData: (
              documentType,
              fileSelected,
              content,
              indexName,
              indexDescription,
              indexReferenceNumber,
              indexTransactionDate,
              indexAmount,
              indexCurrencyId,
              comment,
              extractionTypeId
            ) => {
              fileSelected.map(async (fileEntity, fileIndex) => {
                const toastId = `file.name.${fileEntity?.name}.${fileIndex}`;
                addToast(`Uploading ${fileEntity?.name}...`, {
                  appearance: 'info',
                  id: toastId,
                  autoDismiss: false,
                });
                const uploadMutationResults = await uploadDocument({
                  variables: {
                    document: {
                      documentTypeId: parseInt(documentType.key.toString()),
                      description: content ? content : '',
                      comment: comment,
                      filename: fileEntity.name,
                      indexName: indexName ? indexName : '',
                      indexDescription: indexDescription
                        ? indexDescription
                        : '',
                      indexReferenceNumber: indexReferenceNumber
                        ? indexReferenceNumber
                        : '',
                      indexTransactionDate: indexTransactionDate
                        ? invoiceDateFormat(indexTransactionDate)
                        : null,
                      indexAmount: indexAmount ? indexAmount : null,
                      indexCurrencyId: indexCurrencyId ? indexCurrencyId : null,
                      extractionTypeId: extractionTypeId,
                    },
                    documentPoolId: activeDocument?.id!,
                  },
                });

                if (uploadMutationResults.errors)
                  updateToast(toastId!, {
                    content: `Upload of ${fileEntity.name} failed`,
                    appearance: 'error',
                    autoDismiss: true,
                  });

                if (
                  uploadMutationResults.data?.documentPoolUploadDocument
                    ?.document &&
                  uploadMutationResults.data.documentPoolUploadDocument.document
                    ._documentFileId
                ) {
                  const observer = client.subscribe({
                    query: DOCUMENT_UPLOAD_STATUS,
                    variables: {
                      documentId:
                        uploadMutationResults.data.documentPoolUploadDocument
                          .document._documentFileId,
                    },
                  });

                  const subscription = observer.subscribe((data) => {
                    const subscribedData = data.data as OnDocumentUploadStatus;

                    const { status, document } = {
                      ...subscribedData.documentUploadStatus,
                    };

                    if (status.type === UploadStatusType.VALIDATING) {
                      updateToast(toastId!, {
                        content: status.message
                          ? `Validating files ${fileEntity.name} - ${status.message}`
                          : `Validating files ${fileEntity.name}`,
                        appearance: 'info',
                        autoDismiss: false,
                      });
                    } else if (status.type === UploadStatusType.EXTRACTING) {
                      updateToast(toastId!, {
                        content: status.message
                          ? `Extracting data from ${fileEntity.name} - ${status.message}`
                          : `Extracting data from ${fileEntity.name}`,
                        appearance: 'info',
                        autoDismiss: false,
                      });
                    } else if (status.type === UploadStatusType.FAILURE) {
                      subscription.unsubscribe();
                      updateToast(toastId!, {
                        content: status.message
                          ? `Upload of ${fileEntity.name} failed - ${status.message}`
                          : `Upload of ${fileEntity.name} failed`,
                        appearance: 'error',
                        autoDismiss: true,
                      });
                    } else if (status.type === UploadStatusType.WARNING) {
                      subscription.unsubscribe();
                      updateToast(toastId!, {
                        content: status.message
                          ? `Warning for file ${fileEntity.name}: ${status.message}`
                          : `Warning for file ${fileEntity.name}`,
                        appearance: 'warning',
                        autoDismiss: true,
                      });
                      if (document) refreshDocumentList(document);
                      const activeDocCount =
                        activeDocument?._documentFileCount
                          ?.availableDocuments ?? 0;

                      setActivePoolDocument(
                        activeDocument
                          ? {
                              ...activeDocument,
                              _documentFileCount: {
                                availableDocuments:
                                  activeDocCount + fileSelected.length,
                                selectedDocuments:
                                  activeDocument?._documentFileCount
                                    ?.selectedDocuments ?? 0,
                              },
                            }
                          : undefined
                      );
                      getDocumentPoolData();
                    } else {
                      subscription.unsubscribe();
                      updateToast(toastId!, {
                        content: status.message
                          ? `Successfully uploaded ${fileEntity.name}: ${status.message}`
                          : `Successfully uploaded ${fileEntity.name}`,
                        appearance: 'success',
                        autoDismiss: true,
                      });

                      if (document) refreshDocumentList(document);
                      const activeDocCount =
                        activeDocument?._documentFileCount
                          ?.availableDocuments ?? 0;

                      setActivePoolDocument(
                        activeDocument
                          ? {
                              ...activeDocument,
                              _documentFileCount: {
                                availableDocuments:
                                  activeDocCount + fileSelected.length,
                                selectedDocuments:
                                  activeDocument?._documentFileCount
                                    ?.selectedDocuments ?? 0,
                              },
                            }
                          : undefined
                      );
                      getDocumentPoolData();
                    }
                  });

                  const clientBlob = new BlockBlobClient(
                    uploadMutationResults?.data?.documentPoolUploadDocument?.uploadLink!
                  );
                  await clientBlob.uploadData(fileEntity);
                }
              });
            },
          }}
        />
      )}

      <Stack horizontal>
        <Stack
          className={styles.customScroll}
          styles={{
            root: {
              width: leftPanelWidth,
              height: windowHeight - 200,
            },
          }}
        >
          <LeftPanel
            DocumentFilesData={rootNodes}
            _headerOnClick={_headerOnClick}
            key={documentPool?.id}
            setDocumentOptions={setDocumentOptions}
            filterDocumentOptions={filterDocumentOptions}
            withoutFilterDocumentOptions={withoutFilterDocumentOptions}
            setDefaultDocumentTypeId={setDefaultDocumentTypeId}
            setIsDocumentTypeSelector={setIsDocumentTypeSelector}
            setExtractionTypeId={setExtractionTypeId}
          />
        </Stack>
        <div
          style={{
            width: '1px',
            cursor: 'col-resize',
            backgroundColor: theme.palette.neutralLighter,
            zIndex: 1,
          }}
          onMouseDown={handleMouseDown}
        />
      </Stack>
      <Stack grow tokens={{ childrenGap: 10 }}>
        <>
          <Stack horizontal>
            <Stack>
              <Text
                variant="large"
                className={clsx(commonStyles.semibold)}
                style={{ paddingLeft: 20 }}
              >
                {activeDocument?.name}
              </Text>
              <Text
                className={clsx(
                  commonStyles.semibold,
                  commonStyles.colorThemePrimary
                )}
                style={{ paddingLeft: 20, paddingTop: 10 }}
              >
                ( {activeDocument?._documentFileCount?.availableDocuments ?? 0}
                {' Active, '}
                {activeDocument?._documentFileCount?.selectedDocuments ?? 0}
                {' History'} )
              </Text>
            </Stack>
            <Stack
              horizontal
              grow
              horizontalAlign="end"
              verticalAlign="center"
              tokens={{ childrenGap: 20, padding: '20px 20px 0px 20px' }}
            >
              <FolderViewHeader
                columns={gridColumns}
                onToggleVisibility={(columns) => {
                  setGridColumns(columns);
                }}
                filterOptions={filterOptions!}
                onFilterChange={(filterOptions) =>
                  onFilterChange(filterOptions, documentPool)
                }
                documentTypeOptions={documentTypesOptions}
                onUploadClick={() => {
                  setSelectedDocument(null);
                  toggleUploadDialog();
                }}
                entityType={EntityType.Document}
                disabled={
                  !selectedList.some((selected) => selected._isDeletable)
                }
                multiple={{
                  validCount: selectedList.filter(
                    (selected) => selected._isDeletable
                  ).length,
                  invalidNames: selectedList
                    .filter((selected) => !selected._isDeletable)
                    .map((cannotDelete) => cannotDelete.fileReference),
                }}
                onConfirm={_onConfirm}
                poolSelectedID={activeDocument?.id}
                documentsSelected={selectedList}
                onSuccess={onSuccess}
                onCloseDocumentView={() =>
                  setDocViewState({ isOpen: false, _fileType: 'pdf' })
                }
                allowMultiFileBundleToggle
                companyBusinessUnits={GeneralData?.companyBusinessUnits}
                companyDepartments={GeneralData?.companyDepartments}
                onBuildComplete={onBuildComplete}
              />
            </Stack>
          </Stack>

          <InfiniteList
            items={documentListData}
            loading={entityDocumentsLoading}
            hasNextPage={pageInfo?.hasNextPage}
            columns={gridColumns.filter((_column) => _column.isVisible)}
            onRenderItemColumn={_renderItemColumn}
            onColumnHeaderClick={(_, column) => {
              if (column) _onColumnClick(column);
            }}
            onLoadMore={loadMore}
            onSelectionChanged={setSelectedList}
            isSelectedOnFocus={false}
          />

          <DocumentViewModal
            centerAlign
            onDismiss={() =>
              setDocViewState({ isOpen: false, _fileType: 'pdf' })
            }
            {...docViewState}
          />
        </>
      </Stack>
    </Stack>
  );
};

export default FolderView;
