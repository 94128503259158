import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import {
  ActionButton,
  DefaultButton,
  IColumn,
  Icon,
  IconButton,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  SelectionMode,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
  TooltipHost,
} from '@fluentui/react';
import {
  AttachDocumentFilters,
  filterOptionProps,
} from 'common/components/AttachDocumentFilters';
import { toFilterVariable } from 'common/components/AttachDocumentModal/utils';
import { CloseButton } from 'common/components/Buttons/CloseButton';
import { CustomDropdown } from 'common/components/CustomDropdown';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { ATTACHABLE_TABLE_ROWS } from 'common/constants';
import {
  EntityDocumentFilter,
  EntityDocumentsOrderBy,
} from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import { fileType, fileTypeColor } from 'common/utils/fileType';
import { useFormikContext } from 'formik';
import { loader } from 'graphql.macro';
import React, { useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Approval_approval } from 'settings/account/approvalSetup/view/__generated__/Approval';
import { ApprovalAvailableDocumentTypes } from '../__generated__/ApprovalAvailableDocumentTypes';
import {
  AttachableApprovalDocuments,
  AttachableApprovalDocumentsVariables,
  AttachableApprovalDocuments_attachableApprovalDocuments_nodes,
} from '../__generated__/AttachableApprovalDocuments';
import {
  AttachApprovalDocument,
  AttachApprovalDocumentVariables,
} from '../__generated__/AttachApprovalDocument';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import { OrderDirection } from './types';
import { toOrderByVariable } from './utils';

const ATTACHABLE_APPROVAL_DOCUMENTS = loader(
  '../../ApprovalSetupAttachSection//AttachableApprovalDocuments.graphql'
);
const ATTACH_APPROVAL_DOCUMENTS = loader(
  '../../ApprovalSetupAttachSection//AttachApprovalDocument.graphql'
);
const APPROVAL_DETAILS = loader('../../../Approval.graphql');

type AttachableDataType =
  AttachableApprovalDocuments_attachableApprovalDocuments_nodes;
interface AttachModalFormProps {
  AvailableDocumentTypes: ApprovalAvailableDocumentTypes | undefined;
  approvalSetup?: Approval_approval | null | undefined;
}

export const AttachFormModal: React.FC<AttachModalFormProps> = ({
  ...props
}) => {
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const { dirty } = useFormikContext();

  return (
    <Stack>
      <TooltipHost content="Attach new files">
        <ActionButton
          onClick={() => setDialogVisible(true)}
          iconProps={{ iconName: 'Attach' }}
          text="Attach"
          checked
          disabled={dirty}
        />
      </TooltipHost>
      {dialogVisible && (
        <AttachForm {...props} onDismiss={() => setDialogVisible(false)} />
      )}
    </Stack>
  );
};

type AttachFormProps = AttachModalFormProps & { onDismiss: () => void };
export const AttachForm: React.FC<AttachFormProps> = ({
  AvailableDocumentTypes,
  approvalSetup,
  onDismiss,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [filterOptions, setFilterOptions] = useState<filterOptionProps>({
    filterTypes: [],
    startsWith: true,
  });
  const [gridColumns, setGridColumns] = useState<IColumn[]>(columns);
  const [selectedList, setSelectedList] = useState<AttachableDataType[]>([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState<
    number | null | undefined
  >();
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });

  const {
    data: documents,
    loading,
    networkStatus,
    variables,
    fetchMore,
    refetch,
  } = useQuery<
    AttachableApprovalDocuments,
    AttachableApprovalDocumentsVariables
  >(ATTACHABLE_APPROVAL_DOCUMENTS, {
    variables: {
      first: ATTACHABLE_TABLE_ROWS,
      approvalId: approvalSetup?.id!,
      orderBy: [
        EntityDocumentsOrderBy._UPLOAD_DATE_DESC,
        EntityDocumentsOrderBy.PRIMARY_KEY_DESC,
      ],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [attachDocuments, { loading: attachLoading }] = useMutation<
    AttachApprovalDocument,
    AttachApprovalDocumentVariables
  >(ATTACH_APPROVAL_DOCUMENTS, { errorPolicy: 'all' });

  const documentTypeOptions =
    AvailableDocumentTypes?.approvalAvailableDocumentTypes?.nodes.map(
      (doctype) => ({
        key: doctype.id,
        text: doctype.documentType || '',
      })
    ) || [];

  const _onColumnClick = useCallback(
    async (_ev?: React.MouseEvent<HTMLElement>, clickedColumn?: ColumnData) => {
      if (clickedColumn) {
        const { newColumns, desc } = getSortedColumns(
          clickedColumn,
          gridColumns
        );
        setGridColumns(newColumns);
        refetch({
          after: null,
          orderBy: toOrderByVariable({
            column: clickedColumn.key,
            direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
          }),
        });
      }
    },
    [gridColumns, refetch]
  );

  const _onAttach = async () => {
    const selectedEntities = selectedList.map((item, index) => {
      return documents?.attachableApprovalDocuments?.nodes[index].id || null;
    });
    onDismiss();
    const { errors } = await attachDocuments({
      variables: {
        input: {
          approvalId: approvalSetup?.id!,
          entityDocumentId: selectedEntities,
          documentTypeId: selectedDocumentType,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: APPROVAL_DETAILS,
          variables: {
            id: approvalSetup?.id,
          },
        },
      ],
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else addToast('Document attached successfully', { appearance: 'success' });
  };

  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;

  const _renderItemColumn = (
    item: AttachableDataType | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item?.[
        column?.fieldName as keyof AttachableDataType
      ] as string;
    

      switch (column?.key) {
        case 'indexAmount':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text className={styles.contentColumnAlignRight}>
                {fieldContent}
              </Text>
            </Stack>
          );
        case 'fileReference':
          return (
            <Stack
              tokens={{ childrenGap: 10 }}
              horizontal
              verticalAlign={'center'}
            >
              <Icon
                className={fileTypeColor(item.iconType || 'OTHER')}
                iconName={fileType(item.iconType || 'OTHER')}
              />
              <Text>{item.fileReference}</Text>
            </Stack>
          );
        case 'view':
            const viewDocumentVisible =
              item._isProtected! || item._fileViewer !== 'browser';
          return (
            <Stack
              className={styles.columnHeight}
              tokens={{ childrenGap: 10 }}
              horizontal
              verticalAlign="center"
            >
              <TooltipHost content="View" id="tooltipId">
                <IconButton
                  disabled={viewDocumentVisible}
                  iconProps={{ iconName: 'View' }}
                  onClick={() =>
                    setDocViewState({
                      isOpen: true,
                      title: item.fileReference,
                      entityDocumentId: item.id,
                      _fileType: item._fileType!,
                    })
                  }
                />
              </TooltipHost>
            </Stack>
          );

        case '_uploadDate':
          return (
            <Stack verticalAlign="center">
              <Text>{getGlobalDateFormat(item._uploadDate!)}</Text>
            </Stack>
          );

        case 'indexTransactionDate':
          return (
            <Stack verticalAlign="center">
              {item.indexTransactionDate && (
                <Text>
                  {dateFormat(dateConvertions(item.indexTransactionDate))}
                </Text>
              )}
            </Stack>
          );
        default:
          return (
            <Stack verticalAlign="center">
              <Text>
                {fieldContent === null || !fieldContent ? '' : fieldContent}
              </Text>
            </Stack>
          );
      }
    }
  };

  const onFilterChange = (filterOptions: filterOptionProps) => {
    setFilterOptions(filterOptions);
    const entityDocumentsFilterArray: EntityDocumentFilter | undefined =
      filterOptions.filterTypes?.length
        ? ({ and: toFilterVariable(filterOptions) } as EntityDocumentFilter)
        : undefined;
    refetch({ ...variables, filter: entityDocumentsFilterArray });
  };

  // Handle Search query
  const onLoadMore = () => {
    const newVariables: AttachableApprovalDocumentsVariables = {
      ...variables,
      approvalId: approvalSetup?.id!,
      after: documents?.attachableApprovalDocuments?.pageInfo.endCursor,
    };

    fetchMore?.({ variables: newVariables });
  };

  const attachDisabled = selectedList.length === 0;

  return (
    <Modal isOpen isBlocking onDismiss={onDismiss}>
      <Stack className={styles.modalInsideStack}>
        <Sticky stickyPosition={StickyPositionType.Header}>
          <Stack
            horizontal
            horizontalAlign="space-between"
            className={styles.stickyInsideStack}
          >
            <Text variant="xLarge">Attach Documents</Text>
            <CloseButton onClick={onDismiss} />
          </Stack>
        </Sticky>

        <Stack
          styles={{ root: { margin: 25 } }}
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign="end">
            <CustomDropdown
              className={styles.documentTypeDropdown}
              label="Document Type"
              placeholder="All"
              selectedKey={selectedDocumentType}
              options={documentTypeOptions}
              onChange={(_, option) => {
                const optionSelected = parseInt(option?.key + '');
                refetch({ ...variables, documentTypeId: optionSelected });
                setSelectedDocumentType(optionSelected || null);
              }}
              onClear={() => {
                refetch({ ...variables, documentTypeId: null });
                setSelectedDocumentType(null);
              }}
            />
            <AttachDocumentFilters
              filterOptions={filterOptions}
              onFilterChange={onFilterChange}
            />
          </Stack>
        </Stack>

        <InfiniteList
          loading={loading}
          hasNextPage={
            documents?.attachableApprovalDocuments?.pageInfo.hasNextPage
          }
          items={
            refetching
              ? undefined
              : documents?.attachableApprovalDocuments?.nodes!
          }
          selectionMode={SelectionMode.multiple}
          columns={gridColumns}
          onRenderItemColumn={_renderItemColumn}
          onColumnHeaderClick={_onColumnClick}
          onLoadMore={onLoadMore}
          onSelectionChanged={setSelectedList}
          isSelectedOnFocus={false}
        />
        <DocumentViewModal
          onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
          {...docViewState}
        />

        <Sticky stickyPosition={StickyPositionType.Footer}>
          {attachLoading && <ProgressIndicator />}
          <Stack horizontal verticalAlign="end" tokens={{ padding: 25 }}>
            <Stack
              horizontal
              tokens={{ childrenGap: 20 }}
              horizontalAlign="end"
              grow={1}
              className={styles.footerStack}
            >
              <PrimaryButton
                onClick={_onAttach}
                text="Attach Documents"
                disabled={attachDisabled}
              />
              <DefaultButton onClick={onDismiss} text="Cancel" />
            </Stack>
          </Stack>
        </Sticky>
      </Stack>
    </Modal>
  );
};
