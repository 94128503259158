import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  DatePicker,
  DayOfWeek,
  DefaultButton,
  DialogFooter,
  DialogType,
  IPanelHeaderRenderer,
  IPanelProps,
  IRenderFunction,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { DangerButton } from 'common/components/DangerButton';
import { DialogWrapper } from 'common/components/DialogWrapper';
import DraggablePanel from 'common/components/DraggablePanel';
import { PanelHeader } from 'common/components/PanelHeader';
import { SignatureView } from 'common/components/Signatures';
import { StampOptions, StamperView } from 'common/components/StamperView';
import { UrgencyToggle } from 'common/components/UrgencySelector/UrgencyToggle';
import {
  PurchaseOrderStatementReportStatus,
  PurchaseOrderStatementReportStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/ReportStatus/__generated__/PurchaseOrderStatementReportStatus';
import {
  PurchaseOrderDocumentPackageStatus,
  PurchaseOrderDocumentPackageStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/__generated__/PurchaseOrderDocumentPackageStatus';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalRequestInput,
  DocumentPackageStatusType,
  PurchaseOrderAccountingUnstamperInput,
  PurchaseOrderApprovalRevokeInput,
  ReportStatusType,
  UploadStatusType,
} from 'common/types/globalTypes';
import { EntityType, PanelCommonProps } from 'common/types/utility';
import { dateFormat, onFormatDate } from 'common/utils/dateFormats';
import Decimal from 'decimal.js';
import { loader } from 'graphql.macro';
import {
  PurchaseOrderApprovalCreate,
  PurchaseOrderApprovalCreateVariables,
} from 'purchaseOrder/__generated__/PurchaseOrderApprovalCreate';
import {
  PurchaseOrderDelete,
  PurchaseOrderDeleteVariables,
} from 'purchaseOrder/__generated__/PurchaseOrderDelete';
import { PurchaseOrderSearch_purchaseOrderSearch } from 'purchaseOrder/list/__generated__/PurchaseOrderSearch';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { PurchaseOrder } from '../__generated__/PurchaseOrder';
import { PurchaseOrderCommonData } from '../__generated__/PurchaseOrderCommonData';
import { PurchaseOrderValues } from '../types';
import { ActionMenu } from './ActionMenu';
import { ApprovalHistory } from './ApprovalHistory';
import { BasicForm, PurchaseOrderItemProps } from './BasicForm';
import { DeliveryAddressCallout } from './DeliveryAddressCallout';
import { Footer } from './Footer';
import { Revise } from './Footer/Revise';
import { Tracking } from './Footer/TrackingModal';
import { Header } from './Header';
import { InvoiceSchedule } from './InvoiceSchedule';
import { POItems } from './POItems';
import { PreHierarchyModal } from './PreHierarchyModal';
import { RevisionHistory } from './RevisionHistory';
import { ShimmerView } from './Shimmer/ShimmerViews';
import { ShippingAddress } from './ShippingAddress';
import { UrgencyFolder } from './UrgencyFolder';
import {
  PurchaseOrderAccountingStamper,
  PurchaseOrderAccountingStamperVariables,
} from './__generated__/PurchaseOrderAccountingStamper';
import {
  PurchaseOrderAccountingUnstamper,
  PurchaseOrderAccountingUnstamperVariables,
} from './__generated__/PurchaseOrderAccountingUnstamper';
import {
  PurchaseOrderApprovalRevoke,
  PurchaseOrderApprovalRevokeVariables,
} from './__generated__/PurchaseOrderApprovalRevoke';
import { useStyles } from './index.styles';
import { PurchaseOrderSearchFilterTotals_purchaseOrderSearchFilterTotals } from 'purchaseOrder/list/__generated__/PurchaseOrderSearchFilterTotals';
import { POCopy } from './POCopy';
const PO_DOCUMENT_PACKAGE_STATUS = loader(
  '../../../common/graphql/DocumentPackageSubscription/PurchaseOrderDocumentPackageStatus.graphql'
);

const PO_REPORT_STATUS = loader(
  '../../../common/graphql/DocumentPackageSubscription/ReportStatus/PurchaseOrderStatementReportStatus.graphql'
);
const PURCHASE_ORDER_TYPES = loader('../PurchaseOrderCommonData.graphql');
const PURCHASE_ORDER_DELETE = loader('../../PurchaseOrderDelete.graphql');
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);

const PURCHASE_ORDER_APPROVAL_CREATE = loader(
  '../../PurchaseOrderApprovalCreate.graphql'
);
const PURCHASE_ORDER_APPROVAL_REVOKE = loader(
  './PurchaseOrderApprovalRevoke.graphql'
);
const PURCHASE_ORDER_ACCOUNTING_STAMPER = loader(
  './PurchaseOrderAccountingStamper.graphql'
);
const PURCHASE_ORDER_ACCOUNTING_UNSTAMPER = loader(
  './PurchaseOrderAccountingUnstamper.graphql'
);

const CONFIRM_REQUEST_DIALOG_TITLE =
  'Are you sure you want to request this purchase order for approval?';
const CONFIRM_REQUEST_DIALOG_SUBTEXT =
  'Your purchase order will be requested for approval.';
const CONFIRM_AMEND_DIALOG_TITLE = 'Are you sure?';
const CONFIRM_AMEND_DIALOG_SUBTEXT =
  'This will remove the purchase order from the approval cycle and require re-approval.';
const CONFIRM_STAMP_REMOVE_DIALOG_TITLE = 'Are you sure?';
const CONFIRM_STAMP_REMOVE_DIALOG_SUBTEXT =
  'This will remove the stamp from the purchase order.';
const FINALIZED_BY_DATE_TOOLTIP =
  'Automatically promotes to urgent notifications when not approved by this date';
interface FormViewProps {
  onSave: () => void;
  isSaveAnother: (saveAnother: boolean) => void;
  isNew: boolean;
  purchaseOrderData: PurchaseOrder | undefined;
  dataLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  userDefaultsData: UserDefaults_userDefaults_nodes[];
  refetch: () => void;
}
const PANEL_WIDTH = 1000;

export const FormView: React.FC<FormViewProps> = ({
  onSave,
  isSaveAnother,
  isNew,
  purchaseOrderData,
  dataLoading,
  isCreating,
  isUpdating,
  userDefaultsData,
  refetch,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const { addToast, updateToast } = useToasts();
  const client = useApolloClient();
  const [urgencyLevel, setUrgencyLevel] = React.useState<number>(1);
  const [purchaseOrderItemAvailable, setPurchaseOrderItemAvailable] = useState<
    PurchaseOrderItemProps[] | undefined
  >();
  const [hideConfirmRequestDialog, { toggle: toggleConfirmDialog }] =
    useBoolean(true);
  const [hideConfirmAmendDialog, { toggle: toggleConfirmAmendDialog }] =
    useBoolean(true);
  const [hideConfirmStampDialog, { toggle: toggleConfirmStampDialog }] =
    useBoolean(true);
  const [requestComment, setRequestComment] = useState<string>();
  const [requiredDate, setRequiredDate] = useState<string | undefined>();
  const [showAddressCallout, setShowAddressCallout] = useState<boolean>(false);
  const [
    hideConfirmRemoveStampedDialog,
    { toggle: toggleRemoveStampedDialog },
  ] = useBoolean(true);
  const [requestAccountingTransaction, setRequestAccountingTransaction] =
    useState<string>();
  const [successBtnDisable, setSuccessBtnDisable] = useState<boolean>(true);
  const [showInvoiceScheduleModal, { toggle: toggleInvoiceScheduleModal }] =
    useBoolean(false);

  const { data: commonData } = useQuery<PurchaseOrderCommonData>(
    PURCHASE_ORDER_TYPES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const [
    purchaseOrderApprovalCreate,
    { loading: purchaseOrderApprovalLoading },
  ] = useMutation<
    PurchaseOrderApprovalCreate,
    PurchaseOrderApprovalCreateVariables
  >(PURCHASE_ORDER_APPROVAL_CREATE, {
    errorPolicy: 'all',
    onCompleted: () => {
      setUrgencyLevel(1);
    },
  });

  const [
    purchaseOrderApprovalRevoke,
    { loading: purchaseOrderApprovalRevokeLoading },
  ] = useMutation<
    PurchaseOrderApprovalRevoke,
    PurchaseOrderApprovalRevokeVariables
  >(PURCHASE_ORDER_APPROVAL_REVOKE, { errorPolicy: 'all' });

  const {
    formState: { isSubmitting, isDirty },
    getValues,
  } = useFormContext<PurchaseOrderValues>();

  const { purchaseOrderTypeId } = getValues();

  const [deletePurchaseOrder, { loading: purchaseOrderDeleteLoading }] =
    useMutation<PurchaseOrderDelete, PurchaseOrderDeleteVariables>(
      PURCHASE_ORDER_DELETE,
      {
        errorPolicy: 'all',
      }
    );

  const [updateStamper, { loading: purchaseOrderAccountingStamperLoading }] =
    useMutation<
      PurchaseOrderAccountingStamper,
      PurchaseOrderAccountingStamperVariables
    >(PURCHASE_ORDER_ACCOUNTING_STAMPER, { errorPolicy: 'all' });

  const [
    unStampTransaction,
    { loading: purchaseOrderAccountingUnstamperLoading },
  ] = useMutation<
    PurchaseOrderAccountingUnstamper,
    PurchaseOrderAccountingUnstamperVariables
  >(PURCHASE_ORDER_ACCOUNTING_UNSTAMPER, { errorPolicy: 'all' });

  const deletePO = async () => {
    const { errors } = await deletePurchaseOrder({
      variables: {
        input: {
          entityDelete: [
            {
              id: purchaseOrderData?.purchaseOrder?.id!,
              rowTimestamp: purchaseOrderData?.purchaseOrder?._rowTimestamp!,
            },
          ],
        },
      },
      update: (cache, { data }) => {
        if (data?.purchaseOrderDelete?.deletedEntities?.length) {
          const identity = cache.identify({
            ...purchaseOrderData?.purchaseOrder,
          });
          cache.evict({ id: identity });
          cache.gc();
          cache.modify({
            fields: {
              purchaseOrderSearch: (
                existingData: PurchaseOrderSearch_purchaseOrderSearch
              ) => {
                const newList = existingData.nodes.filter(
                  (ix) => ix.id !== purchaseOrderData?.purchaseOrder?.id!
                );
                return {
                  ...existingData,
                  nodes: newList,
                };
              },
              purchaseOrderSearchFilterTotals: (
                existingData: PurchaseOrderSearchFilterTotals_purchaseOrderSearchFilterTotals
              ) => {
                if (existingData?.totalCount1 !== 0) {
                  const {
                    controlTotalAmount,
                    _baseCurrencyAmount,
                    _spotCurrencyAmount,
                  } = {
                    ...purchaseOrderData?.purchaseOrder,
                  };
                  return {
                    ...existingData,
                    totalCount1: Number(existingData.totalCount1) - 1,
                    totalAmount1: new Decimal(existingData.totalAmount1 || '0')
                      .minus(controlTotalAmount || '0')
                      .toNumber(),
                    totalAmount2: new Decimal(existingData.totalAmount2 || '0')
                      .minus(_baseCurrencyAmount || '0')
                      .toNumber(),
                    totalAmount3: new Decimal(existingData.totalAmount3 || '0')
                      .minus(_spotCurrencyAmount || '0')
                      .toNumber(),
                  };
                } else {
                  return {
                    ...existingData,
                  };
                }
              },
            },
          });
        }
      },
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Record Deleted Successfully', {
        appearance: 'success',
      });
      history.replace('/purchase-orders');
    }
  };

  const {
    _isAccountingEntry,
    statusType,
    _isHistory,
    _isStampAllowed,
    _isTransactionCancelled,
    _isAccountingEntryStampedComplete,
    _accountingStampDate,
    _accountingStampUserName,
    _accountingStampTransactionReference,
    _isInvoiceScheduleViewable,
    _isStagedApprovalRequest,
  } = { ...purchaseOrderData?.purchaseOrder };

  const purchaseOrderStatus =
    !isNew && !_isAccountingEntryStampedComplete && purchaseOrderData
      ? `(${statusType?.statusType})`
      : null;

  const showStampEntry = _isAccountingEntry && !_isHistory && _isStampAllowed;

  const stampData: StampOptions | undefined =
    !isNew && _isAccountingEntryStampedComplete
      ? {
          _isTransactionCancelled,
          _isAccountingEntryStampedComplete,
          _accountingStampDate,
          _accountingStampUserName,
          _accountingStampTransactionReference,
        }
      : undefined;

  const inputsDisabled =
    !isNew && !purchaseOrderData?.purchaseOrder?._isUpdatable;

  const deliveryAddressExist =
    !!purchaseOrderData?.purchaseOrder?._purchaseOrderDeliveryId;

  const { approvalHistoriesByEntityId, approvalHistorySignaturesByEntityId } = {
    ...purchaseOrderData?.purchaseOrder,
  };

  const calloutId = useId(`deliveryAddress`);

  const _onRenderHeader: IPanelHeaderRenderer = () => {
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          history.replace('/purchase-orders');
        }}
      >
        <Header
          isNew={isNew}
          dirty={isDirty}
          isSubmitting={isSubmitting}
          dataLoading={dataLoading}
          purchaseOrderData={purchaseOrderData}
        />
        {purchaseOrderData && (
          <POCopy
            isNew={isNew}
            dirty={isDirty}
            isSubmitting={isSubmitting}
            dataLoading={dataLoading}
            purchaseOrderData={purchaseOrderData}
          />
        )}
        {purchaseOrderData?.purchaseOrder?.statusType?.statusType ===
          'Pending' && <UrgencyFolder purchaseOrderData={purchaseOrderData} />}
        {stampData && _isAccountingEntryStampedComplete && (
          <Stack className={styles.stamperViewContainer}>
            <StamperView invoiceDetails={stampData} />
          </Stack>
        )}
        {showStampEntry && (
          <Stack className={styles.stampButtonContainer}>
            <PrimaryButton
              iconProps={{
                iconName: 'StampSmall',
                styles: {
                  root: {
                    fill: 'white',
                  },
                },
              }}
              onClick={() => toggleConfirmStampDialog()}
              text="Stamp Entry"
            />
          </Stack>
        )}
        {!!_isStagedApprovalRequest && !isNew && (
          <PreHierarchyModal entityId={purchaseOrderData?.purchaseOrder?.id} />
        )}
        {!(_isAccountingEntryStampedComplete || showStampEntry) && (
          <ActionMessageModal
            visible={!isNew}
            entityType={EntityType.PurchaseOrder}
            disabled={!purchaseOrderData?.purchaseOrder?._isDeletable}
            onConfirm={deletePO}
          />
        )}
        <Text variant="mediumPlus" className={commonStyles.colorThemePrimary}>
          {purchaseOrderStatus}
        </Text>
      </PanelHeader>
    );
  };

  const showInvoiceSchedule = !isNew && _isInvoiceScheduleViewable;

  const isLoading =
    isCreating ||
    isUpdating ||
    purchaseOrderApprovalLoading ||
    purchaseOrderApprovalRevokeLoading ||
    purchaseOrderDeleteLoading ||
    purchaseOrderAccountingUnstamperLoading;

  const _onRenderFooter: IRenderFunction<IPanelProps> = () => {
    return (
      <Footer
        isNew={isNew}
        isLoading={isLoading}
        onSave={(saveAnother) => {
          isSaveAnother(saveAnother);
          onSave();
        }}
      >
        {!_isAccountingEntryStampedComplete && (
          <Revise purchaseOrder={purchaseOrderData} />
        )}

        {purchaseOrderData && (
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            {!isNew && (
              <Tracking isOpen purchaseOrderData={purchaseOrderData} />
            )}
            {showInvoiceSchedule && (
              <PrimaryButton
                disabled={isDirty}
                className={styles.disabledButton}
                text="Invoice Schedule"
                onClick={toggleInvoiceScheduleModal}
              />
            )}
            {!isNew && !!_isStagedApprovalRequest && (
              <PrimaryButton
                disabled={isDirty}
                className={styles.disabledButton}
                text="Request Approval"
                onClick={() => toggleConfirmDialog()}
              />
            )}
            {!isNew &&
              purchaseOrderData?.purchaseOrder?._isApprovalRevocable && (
                <PrimaryButton
                  disabled={isDirty}
                  className={styles.disabledButton}
                  text="Amend Transaction"
                  onClick={() => toggleConfirmAmendDialog()}
                />
              )}
            {_isAccountingEntryStampedComplete && (
              <PrimaryButton
                className={styles.disabledButton}
                text="Remove Stamp"
                onClick={() => toggleRemoveStampedDialog()}
              />
            )}
          </Stack>
        )}
      </Footer>
    );
  };

  return (
    <>
      <DraggablePanel
        initialWidth={PANEL_WIDTH}
        minWidth={PANEL_WIDTH}
        {...PanelCommonProps}
        isBlocking={isNew}
        isOpen
        onRenderHeader={() => _onRenderHeader()}
        onRenderFooter={_onRenderFooter}
        onDismiss={() => {
          history.replace('/purchase-orders');
        }}
        isLightDismiss
      >
        <>
          {!isNew && dataLoading ? (
            <ShimmerView />
          ) : (
            <>
              <Stack
                tokens={{
                  padding: '20px 0px',
                  childrenGap: 10,
                }}
              >
                {!isNew && purchaseOrderData?.purchaseOrder && (
                  <>
                    {purchaseOrderData?.purchaseOrder
                      ?._requiredApprovalDocuments && (
                      <MessageBar messageBarType={MessageBarType.error}>
                        {
                          purchaseOrderData?.purchaseOrder
                            ?._requiredApprovalDocuments
                        }
                      </MessageBar>
                    )}
                    {purchaseOrderData.purchaseOrder && (
                      <ActionMenu
                        onRemove={refetch}
                        purchaseOrder={purchaseOrderData.purchaseOrder}
                        onUpload={async (fileSelected, document, toastId) => {
                          const observer = client.subscribe({
                            query: DOCUMENT_UPLOAD_STATUS,
                            variables: {
                              documentId: document.document._documentFileId!,
                            },
                          });
                          const subscription = observer.subscribe(
                            (response) => {
                              const subscribedData =
                                response.data as OnDocumentUploadStatus;

                              const { status, document } = {
                                ...subscribedData.documentUploadStatus,
                              };

                              if (!document) {
                                if (
                                  status.type === UploadStatusType.VALIDATING
                                ) {
                                  updateToast(toastId!, {
                                    content: status.message
                                      ? `Validating files ${fileSelected.name} - ${status.message}`
                                      : `Validating files ${fileSelected.name}`,
                                    appearance: 'info',
                                    autoDismiss: false,
                                  });
                                } else if (
                                  status.type === UploadStatusType.EXTRACTING
                                ) {
                                  updateToast(toastId!, {
                                    content: status.message
                                      ? `Extracting data from ${fileSelected.name} - ${status.message}`
                                      : `Extracting data from ${fileSelected.name}`,
                                    appearance: 'info',
                                    autoDismiss: false,
                                  });
                                } else if (
                                  status.type === UploadStatusType.FAILURE
                                ) {
                                  subscription.unsubscribe();
                                  updateToast(toastId!, {
                                    content: status.message
                                      ? `Upload of ${fileSelected.name} failed - ${status.message}`
                                      : `Upload of ${fileSelected.name} failed`,
                                    appearance: 'error',
                                    autoDismiss: true,
                                  });
                                } else if (
                                  status.type === UploadStatusType.WARNING
                                ) {
                                  subscription.unsubscribe();
                                  updateToast(toastId!, {
                                    content: status.message
                                      ? `Warning for file ${fileSelected.name}: ${status.message}`
                                      : `Warning for file ${fileSelected.name}`,
                                    appearance: 'warning',
                                    autoDismiss: true,
                                  });
                                }
                              } else {
                                subscription.unsubscribe();
                                updateToast(toastId!, {
                                  content: status.message
                                    ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                                    : `Successfully uploaded ${fileSelected.name}`,
                                  appearance: 'success',
                                  autoDismiss: true,
                                });
                                refetch?.();
                              }
                            }
                          );
                        }}
                        secureRowLevels={commonData?.secureRowLevels}
                      />
                    )}
                  </>
                )}
                {purchaseOrderTypeId && (
                  <Stack
                    horizontal
                    horizontalAlign="end"
                    verticalAlign="center"
                    tokens={{ childrenGap: 4 }}
                  >
                    {deliveryAddressExist && (
                      <>
                        {showAddressCallout && (
                          <DeliveryAddressCallout
                            targetID={`#${calloutId}`}
                            purchaseOrder={purchaseOrderData}
                          />
                        )}
                        <Stack
                          className={styles.badge}
                          id={calloutId}
                          onMouseEnter={() => setShowAddressCallout(true)}
                          onMouseLeave={() => setShowAddressCallout(false)}
                        />
                      </>
                    )}
                    <ShippingAddress
                      isDisabled={inputsDisabled}
                      purchaseOrder={purchaseOrderData}
                    />
                  </Stack>
                )}
                <BasicForm
                  commonData={commonData}
                  inputsDisabled={inputsDisabled}
                  submitBasicForm={onSave}
                  onTypeChange={setPurchaseOrderItemAvailable}
                  userDefaultsData={userDefaultsData || []}
                  isNew={isNew}
                  purchaseOrderData={purchaseOrderData}
                />
                <Separator />
                {!isNew && (
                  <POItems
                    inputsDisabled={inputsDisabled}
                    purchaseOrderData={purchaseOrderData}
                    commonData={commonData}
                    _isProductionBusinessUnit={
                      purchaseOrderData?.purchaseOrder?.businessUnit
                        ?._isProductionBusinessUnit
                    }
                    purchaseOrderItemAvailable={purchaseOrderItemAvailable}
                  />
                )}
                {approvalHistorySignaturesByEntityId?.nodes?.length! > 0 && (
                  <Stack>
                    <Separator />
                    <Stack
                      className={styles.approvalHistoryContainer}
                      tokens={{ childrenGap: 20 }}
                    >
                      <Text variant="xLarge">Signatures</Text>
                      <SignatureView
                        signatureData={
                          approvalHistorySignaturesByEntityId?.nodes || []
                        }
                      />
                    </Stack>
                  </Stack>
                )}
                {approvalHistoriesByEntityId &&
                  approvalHistoriesByEntityId?.nodes?.length! > 0 &&
                  !isNew && (
                    <>
                      <Separator />
                      <Stack
                        className={styles.approvalHistoryContainer}
                        tokens={{ childrenGap: 20 }}
                      >
                        <Text variant="xLarge">Approval History</Text>
                        <Text
                          variant="medium"
                          className={styles.requestedByText}
                        >
                          Requested By:
                        </Text>
                        <ApprovalHistory data={approvalHistoriesByEntityId} />
                      </Stack>
                    </>
                  )}
              </Stack>
              <RevisionHistory purchaseOrder={purchaseOrderData} />
              <ConfirmDialog
                hidden={hideConfirmRequestDialog}
                title={CONFIRM_REQUEST_DIALOG_TITLE}
                subText={CONFIRM_REQUEST_DIALOG_SUBTEXT}
                onDismiss={toggleConfirmDialog}
                minWidth={500}
                onConfirm={async () => {
                  toggleConfirmDialog();
                  const inputVariables: ApprovalRequestInput = {
                    entityId: purchaseOrderData?.purchaseOrder?.id!,
                    rowTimestamp:
                      purchaseOrderData?.purchaseOrder?._rowTimestamp!,
                    comments: requestComment,
                  };
                  if (requiredDate) inputVariables.requiredDate = requiredDate;
                  const { errors } = await purchaseOrderApprovalCreate({
                    variables: {
                      input: {
                        entityApproval: [inputVariables],
                        urgencyLevel: urgencyLevel,
                      },
                    },
                  });
                  if (errors?.length)
                    addToast(errors[0].message, {
                      appearance: 'error',
                    });
                  else {
                    setRequestComment('');
                    setRequiredDate('');
                    refetch();
                    addToast('Request sent for approval', {
                      appearance: 'success',
                    });

                    const observer = client.subscribe<
                      PurchaseOrderStatementReportStatus,
                      PurchaseOrderStatementReportStatusVariables
                    >({
                      query: PO_REPORT_STATUS,
                      variables: {
                        id: purchaseOrderData?.purchaseOrder?.id!,
                      },
                    });
                    const subscription = observer.subscribe(
                      ({ data, errors }) => {
                        if (errors)
                          addToast(
                            'Errors received while Subscribing to document package',
                            { appearance: 'error' }
                          );
                        else {
                          const { document, status } = {
                            ...data?.purchaseOrderStatementReportStatus,
                          };
                          if (status === ReportStatusType.FAILURE) {
                            addToast('Report generation failed', {
                              appearance: 'success',
                            });
                          }
                          if (document) {
                            addToast('Document package created', {
                              appearance: 'success',
                            });
                          }
                        }
                        subscription.unsubscribe();
                      }
                    );
                  }
                }}
              >
                <Stack
                  tokens={{
                    childrenGap: 10,
                  }}
                >
                  <TextField
                    className={styles.marginT10}
                    multiline
                    rows={3}
                    value={requestComment}
                    placeholder="Please write your comment here (optional)"
                    resizable={false}
                    onChange={(_event, value) => setRequestComment(value || '')}
                  />
                  <TooltipHost content={FINALIZED_BY_DATE_TOOLTIP}>
                    <DatePicker
                      minDate={new Date()}
                      firstDayOfWeek={DayOfWeek.Sunday}
                      placeholder="Finalized by date (optional)"
                      ariaLabel="Date"
                      formatDate={onFormatDate}
                      firstWeekOfYear={1}
                      showMonthPickerAsOverlay
                      showGoToToday={false}
                      onSelectDate={(date) =>
                        setRequiredDate(dateFormat(date!.toString()))
                      }
                    />
                  </TooltipHost>
                  <Stack tokens={{ padding: '6px 0px 0px 0px' }}>
                    <UrgencyToggle
                      onToggle={(data) => {
                        setUrgencyLevel(data ? 0 : 1);
                      }}
                    />
                  </Stack>
                </Stack>
              </ConfirmDialog>
              <ConfirmDialog
                isAmendButton
                hidden={hideConfirmAmendDialog}
                title={CONFIRM_AMEND_DIALOG_TITLE}
                subText={CONFIRM_AMEND_DIALOG_SUBTEXT}
                onDismiss={toggleConfirmAmendDialog}
                onConfirm={async () => {
                  toggleConfirmAmendDialog();
                  const inputVariables: PurchaseOrderApprovalRevokeInput = {
                    entityId: purchaseOrderData?.purchaseOrder?.id!,
                    rowTimestamp:
                      purchaseOrderData?.purchaseOrder?._rowTimestamp!,
                  };
                  const { errors } = await purchaseOrderApprovalRevoke({
                    variables: {
                      input: inputVariables,
                    },
                  });
                  if (errors?.length)
                    addToast(errors[0].message, {
                      appearance: 'error',
                    });
                  else {
                    refetch();
                    addToast('Approval amended successfully', {
                      appearance: 'success',
                    });
                  }
                }}
              />
              <ConfirmDialog
                isConfirmPrimaryButton
                successBtnDisable={
                  successBtnDisable || purchaseOrderAccountingStamperLoading
                }
                hidden={hideConfirmStampDialog}
                title={
                  'Are you sure you want to stamp this purchase order as entered?'
                }
                onDismiss={toggleConfirmStampDialog}
                minWidth={500}
                onConfirm={async () => {
                  if (requestAccountingTransaction) {
                    let dataVariables: PurchaseOrderAccountingStamperVariables =
                      {
                        input: {
                          id: purchaseOrderData?.purchaseOrder?.id || '',
                          rowTimestamp:
                            purchaseOrderData?.purchaseOrder?._rowTimestamp ||
                            '',
                          transactionReference: requestAccountingTransaction,
                        },
                      };
                    const { errors } = await updateStamper({
                      variables: dataVariables,
                    });
                    if (errors?.length) {
                      toggleConfirmStampDialog();
                      addToast(errors[0].message, {
                        appearance: 'error',
                      });
                    } else {
                      addToast('Purchase order stamped successfully.', {
                        appearance: 'success',
                      });
                      refetch?.();
                      toggleConfirmStampDialog();

                      const observer = client.subscribe<
                        PurchaseOrderDocumentPackageStatus,
                        PurchaseOrderDocumentPackageStatusVariables
                      >({
                        query: PO_DOCUMENT_PACKAGE_STATUS,
                        variables: {
                          id: purchaseOrderData?.purchaseOrder?.id!,
                        },
                      });

                      const subscription = observer.subscribe(
                        ({ data, errors }) => {
                          if (errors)
                            addToast(
                              'Errors received while Subscribing to document package',
                              { appearance: 'error' }
                            );
                          else {
                            const { document, status } = {
                              ...data?.purchaseOrderDocumentPackageStatus,
                            };
                            if (
                              status ===
                              DocumentPackageStatusType.REGENERATION_FAILURE
                            ) {
                              addToast(
                                'Report generation failed. Document package was not created',
                                { appearance: 'error' }
                              );
                            }
                            if (status === DocumentPackageStatusType.FAILURE) {
                              addToast(
                                'Error while creating Document package ',
                                { appearance: 'error' }
                              );
                            }
                            if (document) {
                              addToast('Document package created', {
                                appearance: 'success',
                              });
                              client.cache.modify({
                                id: client.cache.identify({
                                  ...purchaseOrderData?.purchaseOrder,
                                }),
                                fields: {
                                  stampedEntityDocumentId: () => {
                                    return document.id;
                                  },
                                },
                              });
                            }
                          }
                          subscription.unsubscribe();
                        }
                      );
                    }
                  }
                }}
              >
                <Stack tokens={{ childrenGap: 10 }}>
                  <TextField
                    label="Transaction #"
                    value={requestAccountingTransaction}
                    placeholder="Enter the Accounting System Transaction #"
                    resizable={false}
                    onChange={(_event, value) => {
                      setRequestAccountingTransaction(value || '');
                      if (value) {
                        setSuccessBtnDisable(false);
                      } else {
                        setSuccessBtnDisable(true);
                      }
                    }}
                  />
                </Stack>
              </ConfirmDialog>
              <Stack tokens={{ padding: '6px 0px 0px 0px' }}></Stack>
              <DialogWrapper
                hidden={hideConfirmRemoveStampedDialog}
                onDismiss={toggleRemoveStampedDialog}
                dialogContentProps={{
                  title: CONFIRM_STAMP_REMOVE_DIALOG_TITLE,
                  type: DialogType.largeHeader,
                  subText: CONFIRM_STAMP_REMOVE_DIALOG_SUBTEXT,
                }}
              >
                <DialogFooter>
                  <DangerButton
                    text="Remove Stamp"
                    className={styles.unStampButton}
                    onClick={async () => {
                      toggleRemoveStampedDialog();
                      const inputVariables: PurchaseOrderAccountingUnstamperInput =
                        {
                          id: purchaseOrderData?.purchaseOrder?.id!,
                          rowTimestamp:
                            purchaseOrderData?.purchaseOrder?._rowTimestamp!,
                        };

                      const { errors } = await unStampTransaction({
                        variables: {
                          input: inputVariables,
                        },
                      });
                      if (errors?.length)
                        addToast(errors[0].message, {
                          appearance: 'error',
                        });
                      else {
                        refetch?.();
                        addToast('Purchase Order unstamped successfully', {
                          appearance: 'success',
                        });
                      }
                    }}
                  />
                  <DefaultButton
                    onClick={toggleRemoveStampedDialog}
                    text="Cancel"
                  />
                </DialogFooter>
              </DialogWrapper>
              {purchaseOrderData?.purchaseOrder?.id && (
                <InvoiceSchedule
                  isOpen={showInvoiceScheduleModal}
                  purchaseOrderData={purchaseOrderData}
                  onDismiss={toggleInvoiceScheduleModal}
                />
              )}
            </>
          )}
        </>
        <Prompt
          when={isDirty && !isSubmitting}
          message="Are you sure you want to leave your changes unsaved?"
        />
      </DraggablePanel>
    </>
  );
};
