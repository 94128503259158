import { useMutation } from '@apollo/client';
import {
  DayOfWeek,
  DefaultButton,
  Modal,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import {
  FormHookDatePicker,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import { TABLE_ROWS } from 'common/constants';
import { BudgetCreateInput, BudgetUpdateInput } from 'common/types/globalTypes';
import { dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { Budgets_budgets_nodes } from '../__generated__/Budgets';
import { BudgetItemFormValues } from '../types';
import { getBudgetUpdatePatch } from '../utils';
import { Header } from './Header';
import {
  BudgetUpdate,
  BudgetUpdateVariables,
} from './__generated__/BudgetUpdate';
import {
  budgetCreate,
  budgetCreateVariables,
} from './__generated__/budgetCreate';

const CREATE_BUDGET = loader('./BudgetCreate.graphql');
const UPDATE_BUDGET = loader('./BudgetUpdate.graphql');

const BUDGET = loader('../Budgets.graphql');

interface BudgetUpdateModalProps {
  isEditModal: boolean;
  selectedBudget: Budgets_budgets_nodes;
  onDismiss: () => void;
  setSelectedBudget: React.Dispatch<
    React.SetStateAction<Budgets_budgets_nodes | undefined>
  >;
  setSelectedBudgetId: React.Dispatch<React.SetStateAction<String | undefined>>;
}
export const BudgetUpdateModal: React.FC<BudgetUpdateModalProps> = ({
  isEditModal,
  onDismiss,
  selectedBudget,
  setSelectedBudget,
  setSelectedBudgetId,
}) => {
  const { addToast } = useToasts();
  const TITLE = isEditModal ? 'Edit Budget' : 'Create Budget';
  const {
    handleSubmit,
    formState: { isDirty },
  } = useFormContext<BudgetItemFormValues>();

  const [createBudget] = useMutation<budgetCreate, budgetCreateVariables>(
    CREATE_BUDGET,
    { errorPolicy: 'all' }
  );
  const [updateBudget] = useMutation<BudgetUpdate, BudgetUpdateVariables>(
    UPDATE_BUDGET,
    { errorPolicy: 'all' }
  );
  const onHandleSubmit = async (values: BudgetItemFormValues) => {
    if (isEditModal) onUpdateBudget(values);
    else onCreateBudget(values);
  };

  const onCreateBudget = async (values: BudgetItemFormValues) => {
    const dataVariables: BudgetCreateInput = {
      budget: {
        name: values.name!,
        isPrimary: !!values?.isPrimary,
        startingBudgetDate: values.startingBudgetDate
          ? dateFormat(values.startingBudgetDate)
          : null,
        endingBudgetDate: values.endingBudgetDate
          ? dateFormat(values.endingBudgetDate)
          : null,
      },
    };
    const { errors } = await createBudget({
      variables: { input: dataVariables },
      update(_, { data }) {
        if (data?.budgetCreate?.budget) {
          setSelectedBudget(data?.budgetCreate?.budget);
          setSelectedBudgetId(data?.budgetCreate?.budget?.id);
        }
      },

      refetchQueries: [
        {
          query: BUDGET,
          variables: {
            first: TABLE_ROWS,
          },
        },
      ],
    });

    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      addToast('Budget created successfully', {
        appearance: 'success',
      });
      onDismiss();
    }
  };
  const onUpdateBudget = async (values: BudgetItemFormValues) => {
    const budgetUpdatePatch = getBudgetUpdatePatch(values, selectedBudget);
    const dataVariables: BudgetUpdateInput = {
      id: selectedBudget.id,

      rowTimestamp: selectedBudget._rowTimestamp ?? '',
      budgetPatch: budgetUpdatePatch,
    };
    const { errors } = await updateBudget({
      variables: { input: dataVariables },
      update(_, { data }) {
        if (data?.budgetUpdate?.budget) {
          setSelectedBudget(data?.budgetUpdate?.budget);
        }
      },
      refetchQueries: [
        {
          query: BUDGET,
          variables: {
            first: TABLE_ROWS,
          },
        },
      ],
    });

    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      addToast('Budget updated successfully', {
        appearance: 'success',
      });
      onDismiss();
    }
  };

  return (
    <Modal isOpen isBlocking>
      <Stack style={{ width: 600 }}>
        <Header title={TITLE} />
        <Stack
          tokens={{
            padding: 20,
            childrenGap: 20,
          }}
        >
          <FormHookTextField
            name={'name'}
            label="Name"
            placeholder={'Enter name'}
          />
          <FormHookCheckBox label="Primary" name="isPrimary" />
          <FormHookDatePicker
            name="startingBudgetDate"
            label="Starting budget date"
            placeholder="Starting budget date..."
            ariaLabel="From date"
            firstDayOfWeek={DayOfWeek.Monday}
            showWeekNumbers
            firstWeekOfYear={1}
            showMonthPickerAsOverlay
            showGoToToday
            isRequired={false}
          />
          <FormHookDatePicker
            name="endingBudgetDate"
            label="Ending budget date"
            placeholder="Ending budget date..."
            ariaLabel="To date"
            firstDayOfWeek={DayOfWeek.Monday}
            showWeekNumbers
            firstWeekOfYear={1}
            showMonthPickerAsOverlay
            showGoToToday
          />
        </Stack>
        <Stack
          horizontal
          horizontalAlign="end"
          tokens={{
            childrenGap: 15,
            padding: 30,
          }}
        >
          <PrimaryButton
            text="Save"
            onClick={handleSubmit(onHandleSubmit)}
            disabled={!isDirty}
          />
          <DefaultButton
            text="Cancel"
            onClick={() => {
              if (!isEditModal) setSelectedBudget(undefined);
              onDismiss();
            }}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};
