import { useMutation, useQuery } from '@apollo/client';
import {
  DefaultButton,
  IDropdownOption,
  PrimaryButton,
  Separator,
  Stack,
} from '@fluentui/react';
import { FilterArrayType } from 'common/components/Filters';
import { DepartmentBudgetFilter } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { Budgets_budgets_nodes } from '../__generated__/Budgets';
import {
  DepartmentBudgets,
  DepartmentBudgetsVariables,
} from '../__generated__/DepartmentBudgets';
import { ColumnData, useColumns } from '../column.data';
import { DEPARTMENT_BUDGET_INITIAL_VALUES } from '../constant';
import { BudgetItemFormValues } from '../types';
import {
  budgetFilterVariable,
  getDefaultValues,
  getDepartmentBudgetCreatePatch,
  getDepartmentBudgetDeletePatch,
  getDepartmentBudgetUpdatePatch,
} from '../utils';
import { ActionBar } from './ActionBar';
import { ColumnsHeader } from './ColumnHeader';
import { FormField } from './FormField';
import {
  DepartmentBudgetUpdate,
  DepartmentBudgetUpdateVariables,
} from './__generated__/DepartmentBudgetUpdate';
import { useStyles } from './index.styles';
import { BudgetListCommonData } from './__generated__/BudgetListCommonData';
import { Prompt } from 'react-router-dom';
import { formatDropdownOptions } from 'common/utils';
import { ShimmerView } from './ShimmerView';
import { NoDataView } from 'common/components/DataPlaceholders';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';

const UPDATE_DEPARTMENT_BUDGET = loader('./DepartmentBudgetUpdate.graphql');
const DEPARTMENT_BUDGET = loader('../DepartmentBudgets.graphql');
const BUDGET_LIST_COMMON_DATA = loader('./BudgetListCommonData.graphql');

interface BudgetListViewProps {
  selectedBudget: Budgets_budgets_nodes | undefined;
  updateModalVisible: boolean;
  isEditModal: boolean;
}
export const BudgetListView: React.FC<BudgetListViewProps> = ({
  selectedBudget,
  updateModalVisible,
  isEditModal,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const { columnArray } = useColumns();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columnArray);
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>();

  const {
    reset,
    handleSubmit,
    formState: { isDirty, errors, isSubmitting },
    control,
    watch,
  } = useFormContext<BudgetItemFormValues>();

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'departmentBudgets',
  });
  const watchDepartmentBudgets = watch('departmentBudgets');
  const lastItem = watchDepartmentBudgets?.[fields.length - 1];
  const isValueExist = lastItem
    ? Object.entries(lastItem).reduce((prev, current) => {
        const isValue = current?.[1] !== null;
        return isValue || prev;
      }, false)
    : true;

  const { data: commonData } = useQuery<BudgetListCommonData>(
    BUDGET_LIST_COMMON_DATA,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const {
    userDepartments,
    departmentBudgetTypes,
    companyBusinessUnits,
    companyCurrencies,
  } = { ...commonData };

  const departmentsOptions: IDropdownOption[] =
    formatDropdownOptions(userDepartments?.nodes, {
      getKey: (item) => item.id,
      getText: (item) => item.userDepartment?.name || '',
      includeAll: true,
    }) || [];

  const businessUnitOptions: IDropdownOption[] =
    formatDropdownOptions(companyBusinessUnits?.nodes, {
      getKey: (item) => item.id,
      getText: (item) => item.name || '',
      includeAll: true,
    }) || [];

  const budgetTypeOptions: IDropdownOption[] =
    formatDropdownOptions(departmentBudgetTypes?.nodes, {
      getKey: (item) => item.id,
      getText: (item) => item.budgetType || '',
      includeAll: true,
    }) || [];

  const currencyOptions: IDropdownOption[] =
    formatDropdownOptions(companyCurrencies?.nodes, {
      getKey: (item) => item.id,
      getText: (item) => item.name || '',
      includeAll: true,
    }) || [];

  useEffect(() => {
    if (updateModalVisible && !isEditModal) {
      remove();
    }
  }, [updateModalVisible, isEditModal, remove]);

  useEffect(() => {
    if (isValueExist) {
      append(DEPARTMENT_BUDGET_INITIAL_VALUES, { shouldFocus: false });
    }
  }, [append, isValueExist]);

  const filters: DepartmentBudgetFilter | undefined = searchFilters?.length
    ? {
        and: budgetFilterVariable(searchFilters) as DepartmentBudgetFilter[],
        budgetId: { equalTo: selectedBudget?.id },
      }
    : { budgetId: { equalTo: selectedBudget?.id } };

  const { data: departmentBudgetData, loading: deptBudgetLoading } = useQuery<
    DepartmentBudgets,
    DepartmentBudgetsVariables
  >(DEPARTMENT_BUDGET, {
    variables: {
      filter: filters,
    },

    skip: !selectedBudget?.id,
  });
  const totalAmount = departmentBudgetData?.departmentBudgets?.nodes.reduce(
    (acc, item) => acc + Number(item.budgetAmount),
    0
  );
  let gridLength = 0;
  for (let i = 0; i < gridColumns.length; i++) {
    if (gridColumns[i].isVisible) {
      gridLength += (gridColumns[i]?.maxWidth || 0) + 30; //30 is children gap in columnHeader
      if (gridColumns[i].fieldName === 'budgetAmount') {
        break;
      }
    }
  }
  useEffect(() => {
    const defaultValues = getDefaultValues({
      budgetItem: selectedBudget,
      departmentBudgetData: departmentBudgetData,
    });
    reset(defaultValues);
  }, [selectedBudget, reset, departmentBudgetData]);

  const [updateDepartmentBudget] = useMutation<
    DepartmentBudgetUpdate,
    DepartmentBudgetUpdateVariables
  >(UPDATE_DEPARTMENT_BUDGET, { errorPolicy: 'all' });

  const onHandleSubmit = async (values: BudgetItemFormValues) => {
    const updatedDepartmentBudgetPatch = getDepartmentBudgetUpdatePatch(
      values.departmentBudgets,
      departmentBudgetData?.departmentBudgets?.nodes
    );
    const createdDepartmentBudgetPatch = getDepartmentBudgetCreatePatch(
      values.departmentBudgets
    );
    const deletedDepartmentBudgetPatch = getDepartmentBudgetDeletePatch(
      values.departmentBudgets,
      departmentBudgetData?.departmentBudgets?.nodes
    );
    let dataVariables: DepartmentBudgetUpdateVariables = {
      input: {
        budgetId: selectedBudget?.id!,
        departmentBudgetCreate: createdDepartmentBudgetPatch,
        departmentBudgetUpdate: updatedDepartmentBudgetPatch,
        departmentBudgetDelete: deletedDepartmentBudgetPatch,
      },
    };
    const { errors } = await updateDepartmentBudget({
      variables: dataVariables,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: DEPARTMENT_BUDGET,
          variables: {
            filter: { budgetId: { equalTo: selectedBudget?.id } },
          },
        },
      ],
    });

    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      addToast('Department budget updated successfully', {
        appearance: 'success',
      });
    }
  };

  return (
    <Stack
      style={{
        padding: '20px 50px 40px 10px',
      }}
    >
      <ActionBar
        setGridColumns={setGridColumns}
        gridColumns={gridColumns}
        onFilterChange={(filters) => {
          setSearchFilters(filters);
        }}
        departmentsOptions={departmentsOptions}
        businessUnitOptions={businessUnitOptions}
        budgetTypeOptions={budgetTypeOptions}
        currencyOptions={currencyOptions}
        isDirty={isDirty}
      />
      <NoDataView
        title={'No data available!'}
        show={!(departmentBudgetData || deptBudgetLoading)}
      />
      <ColumnsHeader columnsArray={gridColumns} />
      <Separator />
      {deptBudgetLoading && <ShimmerView />}

      <Stack
        className={styles.container}
        style={{
          overflow: 'auto',
        }}
      >
        {fields.map((field, index) => {
          const baseField = `departmentBudgets.${index}`;
          const paddingBottom = errors ? 20 : 10;
          return (
            <Stack
              key={index}
              horizontal
              tokens={{ childrenGap: 30 }}
              style={{ paddingBottom: paddingBottom }}
            >
              {gridColumns.map((value, key) => {
                if (value.isVisible)
                  return (
                    <FormField
                      baseField={baseField}
                      columnData={value}
                      key={field.id + key}
                      remove={remove}
                      index={index}
                      commonData={commonData}
                    />
                  );
                else return null;
              })}
            </Stack>
          );
        })}
      </Stack>
      <Stack
        style={{ width: gridLength }}
        className={styles.footerwrapper}
        tokens={{ padding: '10px 10px 10px 53px ' }}
      >
        <AmountTextView
          value={parseFloat(totalAmount?.toString()!).toFixed(2)}
          className={styles.amountTotal}
        />
      </Stack>
      <Stack
        horizontal
        horizontalAlign="end"
        tokens={{ childrenGap: 20 }}
        className={styles.stickyFooter}
      >
        <PrimaryButton
          text="Save"
          onClick={handleSubmit(onHandleSubmit)}
          disabled={!isDirty}
        />
        <DefaultButton
          text="Cancel"
          onClick={() => {
            reset();
          }}
        />
        <Prompt
          when={isDirty && !isSubmitting}
          message="Are you sure you want to leave your changes unsaved?"
        />
      </Stack>
    </Stack>
  );
};
