import {
  makeVar,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  DefaultButton,
  DialogFooter,
  DialogType,
  IDropdownOption,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { PdfDocument } from 'ap/signing/components/PdfDocument';
import {
  InvoiceSigningUpdate,
  InvoiceSigningUpdateVariables,
} from 'ap/signing/transactionSigning/view/__generated__/InvoiceSigningUpdate';
import { validationSchema } from 'common/components/Modules/TransactionEdit/validation';
import { ApprovalHistory } from 'common/components/ApprovalHistory';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { CustomDropdown } from 'common/components/CustomDropdown';
import { DialogWrapper } from 'common/components/DialogWrapper';
import { DocumentList } from 'common/components/DocumentList';
import { VIEW_DOC_MODAL_WIDTH } from 'common/components/DocumentList/DocumentViewModal';
import { DocumentPackage } from 'common/components/DocumentPackage';
import { DocumentPackageEmail } from 'common/components/DocumentPackageEmail/DocumentPackageEmail';
import { EmailCreateValues } from 'common/components/DocumentPackageEmail/DocumentPackageEmail/DocumentPackageEmailModal/FormModal/types';
import DraggablePanel from 'common/components/DraggablePanel';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { PanelHeader } from 'common/components/PanelHeader';
import { PdfViewModal } from 'common/components/PdfViewModal';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { TABLE_ROWS } from 'common/constants';
import {
  TransactionDocumentPackageStatus,
  TransactionDocumentPackageStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/__generated__/TransactionDocumentPackageStatus';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import { useCommonStyles } from 'common/styles';
import {
  DocumentPackageStatusType,
  EntityDeleteInput,
  IconState,
  InvoiceDistributionPatch,
  InvoiceDistributionUpdateTypeInput,
  InvoiceSigningAccountingUnstamperInput,
  InvoiceSigningApprovalRevokeInput,
  InvoicesOrderBy,
  UploadStatusType,
} from 'common/types/globalTypes';
import { PanelCommonProps } from 'common/types/utility';
import { getWindowDimensions } from 'common/utils/columnUtilities';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import { differenceBy, intersection, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  StampOptions,
  StamperView,
} from '../../../../common/components/StamperView';
import { AccountingView as AccountingEntryRows } from './AccountingView';
import { ActionsMenu } from './ActionMenu';
import { BasicForm } from './BasicForm';
import { FormCarbonAccount } from './CarbonAccounting';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { SignatureOptions, SignatureView } from './Signatures';
import {
  AccountingEntryInvoice,
  AccountingEntryInvoiceVariables,
} from './__generated__/AccountingEntryInvoice';
import { BatchTransactionOptions } from './__generated__/BatchTransactionOptions';
import {
  InvoiceEmailCreate,
  InvoiceEmailCreateVariables,
} from './__generated__/InvoiceEmailCreate';
import {
  InvoiceSigningAccountingStamper,
  InvoiceSigningAccountingStamperVariables,
} from './__generated__/InvoiceSigningAccountingStamper';
import {
  InvoiceSigningAccountingUnStamper,
  InvoiceSigningAccountingUnStamperVariables,
} from './__generated__/InvoiceSigningAccountingUnStamper';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import { AccountingDistributionRows, AccountingEntryValues } from './types';
import { getDefaultValues } from './utils';
import {
  InvoiceEmailStatus,
  InvoiceEmailStatusVariables,
} from './__generated__/InvoiceEmailStatus';
import { Accounting } from 'common/components/Modules/TransactionEdit/Accounting';
import { InvoiceTransactionTypes } from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoiceTransactionTypes';
import {
  AvailablePurchaseOrders,
  AvailablePurchaseOrdersVariables,
} from 'common/components/Modules/TransactionEdit/graphql/__generated__/AvailablePurchaseOrders';
import { LinkedTransaction } from 'common/components/Modules/TransactionEdit/FormView/LinkedTransaction';
import {
  InvoiceSigningApprovalRevoke,
  InvoiceSigningApprovalRevokeVariables,
} from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoiceSigningApprovalRevoke';
import { setUserDefaults } from 'utility/cache/ui';
import { ACCOUNTING_INITIAL_VALUES } from './constants';

const INVOICE_DETAILS = loader('./AccountingEntryInvoice.graphql');
const UPDATE_STAMPER = loader('./InvoiceSigningAccountingStamper.graphql');
const INVOICE_SIGNING_APPROVAL_REVOKE = loader(
  '../../../../common/components/Modules/TransactionEdit/graphql/InvoiceSigningApprovalRevoke.graphql'
);
const INVOICES = loader(
  '../../../../common/components/Modules/TransactionEdit/graphql/InvoicesSigningSearch.graphql'
);
const TRANSACTION_DATA = loader(
  '../../../../common/components/Modules/TransactionEdit/graphql/InvoiceTransactionTypes.graphql'
);
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);
const UPDATE_INVOICE_SIGNING = loader(
  '../../transactionSigning/view//InvoiceSigningUpdate.graphql'
);
const BATCH_TRANSACTION_OPTIONS = loader('./BatchTransactionOptions.graphql');
const UNSTAMP_TRANSACTION = loader(
  './InvoiceSigningAccountingUnStamper.graphql'
);
const AVAILABLE_PURCHASE_ORDER = loader(
  '../../../../common/components/Modules/TransactionEdit/graphql/AvailablePurchaseOrders.graphql'
);
const INVOICE_EMAIL_CREATE = loader('./InvoiceEmailCreate.graphql');

const DOCUMENT_PACKAGE_STATUS = loader(
  '../../../../common/graphql/DocumentPackageSubscription/TransactionPackageStatus.graphql'
);
const FETCH_EMAIL_STATUS = loader('./InvoiceEmailStatus.graphql');

interface OnRenderFooter {
  errors: any;
  isDirty: boolean;
  isSubmitting: boolean;
}

interface BatchOptions extends IDropdownOption {
  key: string | number;
  text: string;
  isoCode: string;
  corporatePeriodId: string | undefined;
}

export const setBatchSelected = makeVar<BatchOptions | undefined>(undefined);

export const AccountingEntryView: React.FC = () => {
  const userDefaults = useReactiveVar(setUserDefaults);

  const { accountingEntryId } = useParams<{
    accountingEntryId: string | undefined;
  }>();
  const { addToast, updateToast } = useToasts();
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const panelHeading = 'Accounting Entry';
  const history = useHistory();
  const client = useApolloClient();
  const currentSelectedBatch = useReactiveVar(setBatchSelected);

  const [
    currentSelectedBatchReimbursement,
    setCurrentSelectedBatchReimbursement,
  ] = useState<string | null>(null);
  const isOpen = true;
  const isNew = !accountingEntryId;
  const [hideConfirmRequestDialog, setHideConfirmRequestDialog] =
    useState<boolean>(true);
  const [requestAccountingTransaction, setRequestAccountingTransaction] =
    React.useState<string>();
  const [
    accountingTransactionReferenceReimbursement,
    setAccountingTransactionReferenceReimbursement,
  ] = React.useState<string>();
  const [corporatePeriodId, setCorporatePeriodId] = React.useState<
    string | undefined
  >();
  const [stampData, setStampData] = useState<StampOptions>();
  const [receiptTotal, setReceiptTotal] = useState<string | null>(null);
  const [accountingPeriodOptions, setaccountingPeriodOptions] = React.useState<
    IDropdownOption[]
  >([]);
  const [signatureData, setSignatureData] = useState<SignatureOptions[]>([]);
  const [hideConfirmAmendDialog, { toggle: toggleConfirmAmendDialog }] =
    useBoolean(true);
  const [hideConfirmRemoveStampdDialog, { toggle: toggleRemoveStampdDialog }] =
    useBoolean(true);
  const [successBtnDisable, setSuccessBtnDisable] = useState<boolean>(true);
  const CONFIRM_AMEND_DIALOG_TITLE = 'Are you sure?';
  const CONFIRM_AMEND_DIALOG_SUBTEXT =
    'This will remove the Transaction signing from the approval cycle and require re-approval.';
  const CONFIRM_STAMP_REMOVE_DIALOG_TITLE = 'Are you sure?';
  const CONFIRM_STAMP_REMOVE_DIALOG_SUBTEXT =
    'This will remove the stamp and remove the transaction from the batch.';
  const toggleConfirmDialog = () => {
    setHideConfirmRequestDialog((prevState) => !prevState);
    setRequestAccountingTransaction(undefined);
    setSuccessBtnDisable(true);
  };

  const { width } = getWindowDimensions();
  const documentViewPosition = width
    ? width - (1100 + VIEW_DOC_MODAL_WIDTH)
    : 50;

  const [
    getInvoiceDetails,
    { loading: invoiceDetailsDataLoading, data: invoiceDetailsData, refetch },
  ] = useLazyQuery<AccountingEntryInvoice, AccountingEntryInvoiceVariables>(
    INVOICE_DETAILS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const { data: transactionTypeData, loading: transactionTypeLoading } =
    useQuery<InvoiceTransactionTypes>(TRANSACTION_DATA, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  const { data: availablePurchaseOrders } = useQuery<
    AvailablePurchaseOrders,
    AvailablePurchaseOrdersVariables
  >(AVAILABLE_PURCHASE_ORDER, {
    skip: !invoiceDetailsData?.invoice?.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    variables: { invoiceId: invoiceDetailsData?.invoice?.id! },
  });
  const [updateTransaction, { loading: updateTransactionLoading }] =
    useMutation<InvoiceSigningUpdate, InvoiceSigningUpdateVariables>(
      UPDATE_INVOICE_SIGNING,
      { errorPolicy: 'all' }
    );

  const [unStampTransaction] = useMutation<
    InvoiceSigningAccountingUnStamper,
    InvoiceSigningAccountingUnStamperVariables
  >(UNSTAMP_TRANSACTION, { errorPolicy: 'all' });

  const [
    invoiceSigningApprovalRevoke,
    { loading: invoiceSigningApprovalRevokeLoading },
  ] = useMutation<
    InvoiceSigningApprovalRevoke,
    InvoiceSigningApprovalRevokeVariables
  >(INVOICE_SIGNING_APPROVAL_REVOKE, { errorPolicy: 'all' });

  const { data: batchTransactionOptions } = useQuery<BatchTransactionOptions>(
    BATCH_TRANSACTION_OPTIONS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  const [updateStamper] = useMutation<
    InvoiceSigningAccountingStamper,
    InvoiceSigningAccountingStamperVariables
  >(UPDATE_STAMPER, { errorPolicy: 'all' });

  const [invoiceEmailCreate, { loading, data }] = useMutation<
    InvoiceEmailCreate,
    InvoiceEmailCreateVariables
  >(INVOICE_EMAIL_CREATE, { errorPolicy: 'all' });

  useEffect(() => {
    if (accountingEntryId && accountingEntryId !== '') {
      getInvoiceDetails({
        variables: {
          id: accountingEntryId,
        },
      });
    }
  }, [getInvoiceDetails, accountingEntryId]);

  const _onSubmitValues = async (values: EmailCreateValues) => {
    if (!!accountingEntryId) {
      const { errors } = await invoiceEmailCreate({
        variables: {
          input: {
            entityId: accountingEntryId,
            ...values,
          },
        },
        refetchQueries: [
          {
            query: INVOICE_DETAILS,
            variables: {
              id: accountingEntryId,
            },
          },
        ],
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Email sent successfully.', {
          appearance: 'success',
        });
        fetchEmailStatus({
          variables: {
            id: accountingEntryId!,
          },
        });
      }
    }
  };

  const isSuccessful = !!data?.invoiceEmailCreate;

  const formMethods = useForm<AccountingEntryValues>({
    // mode: 'onChange',
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });
  const {
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    reset,
  } = { ...formMethods };

  useEffect(() => {
    const accP =
      transactionTypeData?.companyCorporatePeriods?.nodes.map((item) => ({
        disabled: item.isEntryAllowed ? false : true,
        key: item.id,
        text:
          item._periodYear +
          `(${
            item.startDate! ? dateFormat(dateConvertions(item.startDate!)) : ''
          } - ${
            item.endDate! ? dateFormat(dateConvertions(item.endDate!)) : ''
          })`,
      })) || [];
    setaccountingPeriodOptions(accP);
    if (!currentSelectedBatch) {
      if (invoiceDetailsData?.invoice?.corporatePeriodId)
        setCorporatePeriodId(invoiceDetailsData?.invoice?.corporatePeriodId);
    }
  }, [transactionTypeData, invoiceDetailsData, currentSelectedBatch]);

  useEffect(() => {
    if (!currentSelectedBatch) {
      if (invoiceDetailsData?.invoice?.corporatePeriodId)
        setCorporatePeriodId(invoiceDetailsData?.invoice?.corporatePeriodId);
    }
  }, [invoiceDetailsData, hideConfirmRequestDialog, currentSelectedBatch]);

  useEffect(() => {
    if (
      invoiceDetailsData?.invoice &&
      invoiceDetailsData?.invoice.approvalHistorySignaturesByEntityId.nodes
        .length > 0
    ) {
      const signatureOptions: SignatureOptions[] =
        invoiceDetailsData?.invoice.approvalHistorySignaturesByEntityId.nodes.map(
          (item) =>
            ({
              actionDate: item.actionDate,
              additionalInformation: item.additionalInformation,
              digitalSignature: item.digitalSignature,
              id: item.id,
              isApproved: item.isApproved,
              isRejected: item.isRejected,
              isRequested: item.isRequested,
              userName: item.userName,
              entityDocumentId: item.entityDocumentId,
              userOccupationTitle: item.userOccupationTitle,
              userSignatureDocument: {
                _downloadLink: item.userSignatureDocument?._downloadLink,
                fileIndexInformation:
                  item.userSignatureDocument?.fileIndexInformation,
                fileReference: item.userSignatureDocument?.fileReference,
                iconType: item.userSignatureDocument?.iconType,
                id: item.userSignatureDocument?.id,
              },
            } as SignatureOptions)
        );
      setSignatureData(signatureOptions);
    }
  }, [invoiceDetailsData]);

  useEffect(() => {
    if (invoiceDetailsData) {
      const stampOptions: StampOptions = {
        _accountingStampDate: invoiceDetailsData.invoice?._accountingStampDate,
        _accountingStampTransactionReference:
          invoiceDetailsData.invoice?._accountingStampTransactionReference,
        _accountingStampUserName:
          invoiceDetailsData.invoice?._accountingStampUserName,
        _isAccountingEntryStampedComplete:
          invoiceDetailsData.invoice?._isAccountingEntryStampedComplete,
        _isTransactionCancelled:
          invoiceDetailsData.invoice?._isTransactionCancelled,
      };
      setStampData(stampOptions);
    }
  }, [invoiceDetailsData]);

  const isLoading =
    updateTransactionLoading || invoiceSigningApprovalRevokeLoading;

  const showStampEntry =
    invoiceDetailsData?.invoice?._isAccountingEntry &&
    !invoiceDetailsData.invoice._isHistory &&
    invoiceDetailsData.invoice._isUpdatable;

  const onHandleSubmit = async (values: AccountingEntryValues) => {
    const initialValues = getDefaultValues(invoiceDetailsData, userDefaults);
    const { _rowTimestamp, invoiceDistributions } = values;
    const newInvoiceDistributionRows =
      invoiceDistributions &&
      (invoiceDistributions
        ?.filter((row) => {
          if (row.id) {
            return false;
          }
          const patch = Object.entries(row).reduce((res, [key, val]) => {
            if (
              (key === 'referenceCode6' &&
                val === userDefaults?.referenceCode6) ||
              (key === 'referenceCode7' &&
                val === userDefaults?.referenceCode7) ||
              (key === 'projectReference' &&
                val === userDefaults?.projectReference) ||
              (key === 'setReference' && val === userDefaults?.setReference) ||
              (key === '_accountName' &&
                val === userDefaults?.lookupAccounts?.lookupName)
            )
              return res;
            if (
              val &&
              val !==
                ACCOUNTING_INITIAL_VALUES[
                  key as keyof AccountingDistributionRows
                ]
            ) {
              return { ...res, [key]: val };
            }
            return res;
          }, {});

          return !isEmpty(patch);
        })
        .map(
          ({
            id,
            _rowTimestamp,
            _createdDate,
            _isDeletable,
            _isUpdatable,
            _ff1Name,
            _ff2Name,
            _ff3Name,
            _ff4Name,
            _accountName,
            ...addr
          }) => addr
        ) as InvoiceDistributionPatch[]);

    const deletedInvoiceDistributionRows =
      initialValues.invoiceDistributions &&
      (differenceBy(
        initialValues.invoiceDistributions,
        invoiceDistributions || [],
        'id'
      ).map((addr) => ({
        id: addr.id!,
        rowTimestamp: addr._rowTimestamp!,
      })) as EntityDeleteInput[]);

    const updatedInvoiceDistributions =
      initialValues.invoiceDistributions &&
      invoiceDistributions &&
      intersection(
        initialValues.invoiceDistributions.map((addr) => addr.id),
        invoiceDistributions.filter((addr) => addr.id).map((addr) => addr.id)
      ).reduce((arr, targetId) => {
        const initialInvoiceDistributions =
          initialValues.invoiceDistributions!.find(
            (addr) => addr.id === targetId
          )!;
        const { id, _rowTimestamp, _accountName, ...updatedAddress } =
          invoiceDistributions!.find((addr) => addr.id === targetId)!;
        const patch = Object.entries(updatedAddress).reduce(
          (res, [key, val]) => {
            if (
              val !==
              initialInvoiceDistributions[
                key as keyof AccountingDistributionRows
              ]
            )
              return { ...res, [key]: val };
            return res;
          },
          {}
        );
        if (!isEmpty(patch))
          return [
            ...arr,
            {
              id,
              rowTimestamp: _rowTimestamp,
              invoiceDistributionPatch: patch,
            },
          ];
        return arr;
      }, [] as InvoiceDistributionUpdateTypeInput[]);

    if (accountingEntryId && _rowTimestamp) {
      const { errors } = await updateTransaction({
        variables: {
          input: {
            id: accountingEntryId,
            rowTimestamp: _rowTimestamp,
            invoiceDistributionsUpdate:
              updatedInvoiceDistributions &&
              updatedInvoiceDistributions.length > 0
                ? updatedInvoiceDistributions
                : undefined,
            invoiceDistributionsCreate:
              newInvoiceDistributionRows &&
              newInvoiceDistributionRows.length > 0
                ? newInvoiceDistributionRows
                : undefined,
            invoiceDistributionsDelete:
              deletedInvoiceDistributionRows &&
              deletedInvoiceDistributionRows.length > 0
                ? deletedInvoiceDistributionRows
                : undefined,
            // invoicePatch: !isEmpty(invoicePatch) ? invoicePatch : undefined,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: INVOICE_DETAILS,
            variables: {
              id: accountingEntryId,
            },
          },
        ],
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        addToast('Transaction edited successfully', {
          appearance: 'success',
        });
      }
    }
  };

  const _onRenderHeader = (dirty: boolean, isSubmitting: boolean) => {
    return (
      <PanelHeader
        hasHeaderText={false}
        title={panelHeading}
        onClose={() => {
          history.replace('/signing');
        }}
      >
        <Stack
          grow
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">{panelHeading}</Text>
            {invoiceDetailsData?.invoice?.transactionType?.transactionType && (
              <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
                {invoiceDetailsData?.invoice?.transactionType?.transactionType}
              </Text>
            )}
            <DocumentPackage
              paddingLeft={documentViewPosition}
              documentPackageId={
                invoiceDetailsData?.invoice?._documentPackageId
              }
            />
            <DocumentPackageEmail
              emailDocument={invoiceDetailsData?.invoice?._emailDocument}
              emailDocumentsByEntityId={
                invoiceDetailsData?.invoice?.emailDocumentsByEntityId?.nodes ||
                []
              }
              isSuccessful={isSuccessful}
              loading={loading}
              onSubmitValues={_onSubmitValues}
              dataLoading={invoiceDetailsDataLoading}
              showEmailStatus={showEmailStatus}
              setShowEmailStatus={setShowEmailStatus}
            />
            <UnsavedIndicator
              visible={
                !isNew && !invoiceDetailsDataLoading && dirty && !isSubmitting
              }
            />
          </Stack>
          <Stack horizontal>
            <LinkedTransaction
              _linkedInvoiceId={invoiceDetailsData?.invoice?._linkedInvoiceId!}
            />
            {stampData &&
              invoiceDetailsData?.invoice?._isAccountingEntry &&
              invoiceDetailsData?.invoice?._isHistory && (
                <StamperView invoiceDetails={stampData} />
              )}
            {showStampEntry && (
              <PrimaryButton
                iconProps={{
                  iconName: 'StampSmall',
                  styles: {
                    root: {
                      fill: 'white',
                    },
                  },
                }}
                onClick={() => toggleConfirmDialog()}
                text="Stamp Entry"
              />
            )}
          </Stack>
        </Stack>
        <PdfViewModal visible={!!invoiceDetailsData}>
          <PdfDocument
            invoiceData={invoiceDetailsData}
            transactionTypeData={transactionTypeData}
          />
        </PdfViewModal>
      </PanelHeader>
    );
  };

  const _onRenderFooter = (props: OnRenderFooter) => {
    return (
      <FooterActionBar
        disabled={
          transactionTypeLoading || {
            save: !props.isDirty || Object.keys(props.errors).length > 0,
            cancel: !props.isDirty,
          }
        }
        isSubmitting={props.isSubmitting}
        onCancel={() => history.replace('/signing')}
        onSave={handleSubmit(onHandleSubmit)}
        hideCreateButton={true}
        isLoading={isLoading}
        children={
          <Stack>
            {invoiceDetailsData?.invoice?._isApprovalRevocable && (
              <PrimaryButton
                className={styles.diabledButton}
                text="Amend Transaction"
                onClick={() => toggleConfirmAmendDialog()}
              />
            )}
            {invoiceDetailsData?.invoice?._isAccountingEntryStampedComplete && (
              <PrimaryButton
                className={styles.diabledButton}
                text="Remove Stamp"
                onClick={() => toggleRemoveStampdDialog()}
              />
            )}
            <DialogWrapper
              hidden={hideConfirmRemoveStampdDialog}
              onDismiss={toggleRemoveStampdDialog}
              dialogContentProps={{
                title: CONFIRM_STAMP_REMOVE_DIALOG_TITLE,
                type: DialogType.largeHeader,
                subText: CONFIRM_STAMP_REMOVE_DIALOG_SUBTEXT,
              }}
            >
              <DialogFooter>
                <PrimaryButton
                  text="Unstamp"
                  className={styles.unstampButton}
                  onClick={async () => {
                    toggleRemoveStampdDialog();
                    const inputVariables: InvoiceSigningAccountingUnstamperInput =
                      {
                        id: invoiceDetailsData?.invoice?.id!,
                        rowTimestamp:
                          invoiceDetailsData?.invoice?._rowTimestamp!,
                      };

                    const { errors } = await unStampTransaction({
                      variables: {
                        input: inputVariables,
                      },
                    });
                    if (errors?.length)
                      addToast(errors[0].message, {
                        appearance: 'error',
                      });
                    else {
                      refetch?.();
                      addToast('Unstamp successful', {
                        appearance: 'success',
                      });
                    }
                  }}
                />
                <DefaultButton
                  onClick={toggleRemoveStampdDialog}
                  text="Cancel"
                />
              </DialogFooter>
            </DialogWrapper>

            <ConfirmDialog
              isAmendButton
              hidden={hideConfirmAmendDialog}
              title={CONFIRM_AMEND_DIALOG_TITLE}
              subText={CONFIRM_AMEND_DIALOG_SUBTEXT}
              onDismiss={toggleConfirmAmendDialog}
              onConfirm={async () => {
                toggleConfirmAmendDialog();
                const inputVariables: InvoiceSigningApprovalRevokeInput = {
                  entityId: invoiceDetailsData?.invoice?.id!,
                  rowTimestamp: invoiceDetailsData?.invoice?._rowTimestamp!,
                };
                const { errors } = await invoiceSigningApprovalRevoke({
                  variables: {
                    input: inputVariables,
                  },
                  awaitRefetchQueries: true,
                  refetchQueries: [
                    {
                      query: INVOICES,
                      variables: {
                        isSigningSearch: false,
                        isHistorySearch: false,
                        first: TABLE_ROWS,
                        orderBy: [
                          InvoicesOrderBy._CREATED_DATE_DESC,
                          InvoicesOrderBy.PRIMARY_KEY_ASC,
                        ],
                      },
                    },
                  ],
                });
                if (errors?.length)
                  addToast(errors[0].message, {
                    appearance: 'error',
                  });
                else {
                  history.replace('/signing');
                  addToast('Approval amended successfully', {
                    appearance: 'success',
                  });
                }
              }}
            />
          </Stack>
        }
      />
    );
  };

  const { _receiptsTotal } = { ...invoiceDetailsData?.invoice };
  useEffect(() => {
    setReceiptTotal(_receiptsTotal!);
  }, [_receiptsTotal]);

  const batchOptions: BatchOptions[] =
    batchTransactionOptions?.batchTransactions?.nodes.map((item) => ({
      key: item.id,
      text:
        `${item.description}  ${
          item.currency?.isoCode ? `(${item.currency?.isoCode})` : ''
        } ` || '',
      description: item.description,
      isoCode: item.currency?.isoCode!,
      corporatePeriodId: item.corporatePeriodId || '',
    })) || [];

  const isReimburseAmountExist: boolean = invoiceDetailsData?.invoice
    ?.reimburseAmount
    ? true
    : false;
  const [showEmailStatus, setShowEmailStatus] = useState(false);

  const [fetchEmailStatus, { stopPolling }] = useLazyQuery<
    InvoiceEmailStatus,
    InvoiceEmailStatusVariables
  >(FETCH_EMAIL_STATUS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    pollInterval: 60000,
    onCompleted(data) {
      if (data?.invoice?._emailDocument?._sendIconState === IconState.SENT) {
        stopPolling?.();
        setShowEmailStatus(true);
      }
    },
  });
  useEffect(() => {
    const defaultValues = getDefaultValues(invoiceDetailsData, userDefaults);
    reset(defaultValues);
  }, [invoiceDetailsData, reset, userDefaults]);

  const fetchEmailStatusMemo = useCallback(() => {
    fetchEmailStatus({
      variables: {
        id: accountingEntryId!,
      },
    });
  }, [fetchEmailStatus, accountingEntryId]);

  useEffect(() => {
    if (
      invoiceDetailsData?.invoice?._emailDocument?._sendIconState ===
      IconState.PENDING
    ) {
      fetchEmailStatusMemo();
    }
  }, [invoiceDetailsData, fetchEmailStatusMemo]);

  return (
    <>
      <FormProvider {...formMethods}>
        <DraggablePanel
          initialWidth={1100}
          minWidth={1100}
          {...PanelCommonProps}
          isBlocking={false}
          onRenderHeader={() => _onRenderHeader(isDirty, isSubmitting)}
          onRenderFooter={() =>
            _onRenderFooter({ isDirty, isSubmitting, errors })
          }
          isOpen={isOpen}
          onDismiss={() => {
            history.replace('/signing');
          }}
          isLightDismiss
        >
          {invoiceDetailsDataLoading && (
            <Stack
              className={styles.shimmerViewMainContainer}
              tokens={{ childrenGap: 20 }}
            >
              <Stack
                className={styles.headerContainer}
                tokens={{ childrenGap: 10 }}
              >
                <ShimmerView />
              </Stack>
            </Stack>
          )}
          <Stack className={styles.marginTop20}>
            <ConfirmDialog
              isConfirmPrimaryButton
              successBtnDisable={successBtnDisable}
              hidden={hideConfirmRequestDialog}
              title={
                'Are you sure you want to stamp this transaction as Entered?'
              }
              isDraggable
              onDismiss={toggleConfirmDialog}
              minWidth={500}
              onConfirm={async () => {
                if (requestAccountingTransaction) {
                  let dataVariables: InvoiceSigningAccountingStamperVariables =
                    {
                      input: {
                        id: invoiceDetailsData?.invoice?.id || '',
                        rowTimestamp:
                          invoiceDetailsData?.invoice?._rowTimestamp || '',
                        batchTransactionId:
                          currentSelectedBatch?.key.toString(),
                        corporatePeriodId: corporatePeriodId,
                        transactionReference: requestAccountingTransaction,
                        reimbursementBatchTransactionId:
                          isReimburseAmountExist &&
                          currentSelectedBatchReimbursement
                            ? currentSelectedBatchReimbursement
                            : null,
                        reimbursementTransactionReference:
                          isReimburseAmountExist &&
                          accountingTransactionReferenceReimbursement
                            ? accountingTransactionReferenceReimbursement
                            : null,
                      },
                    };
                  const { errors } = await updateStamper({
                    variables: dataVariables,
                  });
                  if (errors?.length)
                    addToast(errors[0].message, {
                      appearance: 'error',
                    });
                  else {
                    addToast('Stamp successful', {
                      appearance: 'success',
                    });
                    toggleConfirmDialog();
                    refetch?.();

                    const observer = client.subscribe<
                      TransactionDocumentPackageStatus,
                      TransactionDocumentPackageStatusVariables
                    >({
                      query: DOCUMENT_PACKAGE_STATUS,
                      variables: {
                        id: invoiceDetailsData?.invoice?.id!,
                      },
                    });

                    const subscription = observer.subscribe(
                      ({ data, errors }) => {
                        if (errors)
                          addToast(
                            'Errors received while Subscribing to document package',
                            { appearance: 'error' }
                          );
                        else {
                          const { document, status } = {
                            ...data?.transactionDocumentPackageStatus,
                          };

                          if (
                            status ===
                            DocumentPackageStatusType.REGENERATION_FAILURE
                          ) {
                            addToast(
                              'Report generation failed. Document package was not created',
                              { appearance: 'error' }
                            );
                          }
                          if (status === DocumentPackageStatusType.FAILURE) {
                            addToast('Error while creating Document package ', {
                              appearance: 'error',
                            });
                          }
                          if (document) {
                            addToast('Document package created', {
                              appearance: 'success',
                            });
                            client.cache.modify({
                              id: `${invoiceDetailsData?.invoice?.__typename}:${invoiceDetailsData?.invoice?.id}`,
                              fields: {
                                _documentPackageId: () => {
                                  return document.id;
                                },
                              },
                            });
                          }
                        }

                        subscription.unsubscribe();
                      }
                    );
                  }
                }
              }}
            >
              <Stack tokens={{ childrenGap: 10 }}>
                <TextField
                  value={requestAccountingTransaction}
                  placeholder="Enter Accounting System Transaction #"
                  resizable={false}
                  onChange={(_event, value) => {
                    setRequestAccountingTransaction(value || '');
                    if (value) {
                      setSuccessBtnDisable(false);
                    } else {
                      setSuccessBtnDisable(true);
                    }
                  }}
                />
                <CustomDropdown
                  label="Batch"
                  placeholder="Select"
                  selectedKey={
                    currentSelectedBatch?.key ? currentSelectedBatch?.key : null
                  }
                  options={batchOptions}
                  onChange={(_, option) => {
                    const selectedOption = option as BatchOptions;
                    setBatchSelected(selectedOption);
                    setCorporatePeriodId(
                      selectedOption.corporatePeriodId || undefined
                    );
                  }}
                  onClear={() => {
                    setBatchSelected(undefined);
                    setCorporatePeriodId(undefined);
                  }}
                  notifyOnReselect
                />
                {isReimburseAmountExist && (
                  <>
                    <Stack style={{ marginTop: 20 }}>
                      <TextField
                        label="Reimbursement Transaction #"
                        value={accountingTransactionReferenceReimbursement!}
                        placeholder="Enter Accounting System Transaction # (Reimbursement)"
                        resizable={false}
                        onChange={(_event, value) => {
                          setAccountingTransactionReferenceReimbursement(
                            value || ''
                          );
                        }}
                      />
                    </Stack>
                    <CustomDropdown
                      label="Batch (Reimbursement)"
                      placeholder="Select"
                      selectedKey={currentSelectedBatchReimbursement}
                      options={batchOptions}
                      onChange={(_, option) => {
                        const selectedOption = option as BatchOptions;
                        setCurrentSelectedBatchReimbursement(
                          selectedOption.key.toString()
                        );
                      }}
                      onClear={() => {
                        setCurrentSelectedBatchReimbursement(null);
                      }}
                      notifyOnReselect
                    />
                  </>
                )}
                <CustomDropdown
                  label="Accounting Period"
                  placeholder="Select"
                  selectedKey={corporatePeriodId ? corporatePeriodId : null}
                  options={accountingPeriodOptions}
                  onChange={(_, option) => {
                    setCorporatePeriodId(option?.key.toString());
                  }}
                  onClear={() => setCorporatePeriodId(undefined)}
                  notifyOnReselect
                />
              </Stack>
            </ConfirmDialog>
            <Stack
              className={styles.headerContainer}
              tokens={{ childrenGap: 10 }}
            >
              {invoiceDetailsData?.invoice && (
                <ActionsMenu
                  secureRowLevels={transactionTypeData?.secureRowLevels}
                  invoice={invoiceDetailsData.invoice}
                  // invoiceDetails={invoiceDetailsData}
                  reloaddata={() => refetch?.()}
                  onUpload={async (fileSelected, document, toastId) => {
                    const observer = client.subscribe({
                      query: DOCUMENT_UPLOAD_STATUS,
                      variables: {
                        documentId: document.document._documentFileId!,
                      },
                    });
                    const subscription = observer.subscribe((data) => {
                      const subscribedData =
                        data.data as OnDocumentUploadStatus;

                      const { status, document } = {
                        ...subscribedData.documentUploadStatus,
                      };

                      if (status.type === UploadStatusType.VALIDATING) {
                        updateToast(toastId!, {
                          content: status.message
                            ? `Validating files ${fileSelected.name} - ${status.message}`
                            : `Validating files ${fileSelected.name}`,
                          appearance: 'info',
                          autoDismiss: false,
                        });
                      } else if (status.type === UploadStatusType.EXTRACTING) {
                        updateToast(toastId!, {
                          content: status.message
                            ? `Extracting data from ${fileSelected.name} - ${status.message}`
                            : `Extracting data from ${fileSelected.name}`,
                          appearance: 'info',
                          autoDismiss: false,
                        });
                      } else if (status.type === UploadStatusType.FAILURE) {
                        subscription.unsubscribe();
                        updateToast(toastId!, {
                          content: status.message
                            ? `Upload of ${fileSelected.name} failed - ${status.message}`
                            : `Upload of ${fileSelected.name} failed`,
                          appearance: 'error',
                          autoDismiss: true,
                        });
                      } else if (status.type === UploadStatusType.WARNING) {
                        updateToast(toastId!, {
                          content: status.message
                            ? `Warning for file ${fileSelected.name}: ${status.message}`
                            : `Warning for file ${fileSelected.name}`,
                          appearance: 'warning',
                          autoDismiss: true,
                        });
                      } else {
                        subscription.unsubscribe();
                        updateToast(toastId!, {
                          content: status.message
                            ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                            : `Successfully uploaded ${fileSelected.name}`,
                          appearance: 'success',
                          autoDismiss: true,
                        });

                        const cacheData = client.readQuery<
                          AccountingEntryInvoice,
                          AccountingEntryInvoiceVariables
                        >({
                          query: INVOICE_DETAILS,
                          variables: {
                            id: accountingEntryId!,
                          },
                        });

                        const updatedData: AccountingEntryInvoice = {
                          invoice: {
                            ...cacheData?.invoice!,
                            entityDocumentsByEntityId: {
                              ...cacheData?.invoice?.entityDocumentsByEntityId
                                .nodes,
                              nodes: [
                                { ...document! },
                                ...cacheData?.invoice?.entityDocumentsByEntityId
                                  .nodes!,
                              ],
                            },
                          },
                        };

                        client.writeQuery<
                          AccountingEntryInvoice,
                          AccountingEntryInvoiceVariables
                        >({
                          query: INVOICE_DETAILS,
                          data: updatedData,
                          variables: {
                            id: accountingEntryId!,
                          },
                        });
                      }
                    });
                  }}
                />
              )}
            </Stack>
            <Stack className={styles.marginTop10}>
              <Separator />
            </Stack>
            <Stack className={styles.paddingTop10}>
              <BasicForm
                invoiceDetails={invoiceDetailsData}
                receiptTotal={receiptTotal}
              />
            </Stack>
          </Stack>
          {invoiceDetailsData &&
            invoiceDetailsData?.invoice?.invoiceDistributions.nodes.length !==
              0 &&
            !invoiceDetailsData?.invoice?._isUpdatable && (
              <Stack className={styles.marginTop20}>
                <Stack className={styles.marginTop10}>
                  <Separator />
                </Stack>
                <AccountingEntryRows invoiceDetails={invoiceDetailsData} />
              </Stack>
            )}
          {transactionTypeData && invoiceDetailsData?.invoice?._isUpdatable && (
            <>
              <Separator />

              <Accounting
                invoiceData={invoiceDetailsData?.invoice!}
                invoiceDistributionStatusTypes={transactionTypeData}
                availablePurchaseOrders={availablePurchaseOrders}
              />

              {/* <AccountingView
                        invoiceDistributions={
                          invoiceDetailsData.invoice.invoiceDistributions.nodes
                        }
                        aggregate={
                          invoiceDetailsData?.invoice?.invoiceDistributions
                            .aggregates || null
                        }
                        purchaseOrderRefs={
                          purchaseOrderRefs?.availablePurchaseOrders?.nodes
                        }
                        disableCondition={!isNew ? invoiceDetails : undefined}
                        deleteRow={() => { }}
                        invoiceDistributionStatusTypes={transactionTypeData!}
                        userDefaultsData={
                          userDefaultsData?.userDefaults?.nodes || []
                        }
                      /> */}
            </>
          )}

          {invoiceDetailsData &&
            invoiceDetailsData?.invoice?.entityEnvironmentalsByEntityId.nodes
              .length !== 0 && (
              <Stack className={styles.marginTop20}>
                <Stack className={styles.marginTop10}>
                  <Separator />
                </Stack>
                <FormCarbonAccount invoiceDetails={invoiceDetailsData} />
              </Stack>
            )}

          {invoiceDetailsData?.invoice?.entityDocumentsByEntityId.nodes
            .length! > 0 &&
            invoiceDetailsData && (
              <Stack>
                <Separator />
                <Stack
                  className={styles.tagsContainer}
                  tokens={{ childrenGap: 20 }}
                >
                  <Text variant="xLarge">Documents</Text>
                  {invoiceDetailsData?.invoice?.entityDocumentsByEntityId.nodes
                    .length && (
                    <Stack className={styles.fileListContainer}>
                      <DocumentList
                        paddingLeft={documentViewPosition}
                        isNew={isNew}
                        columns={columns}
                        data={
                          invoiceDetailsData?.invoice?.entityDocumentsByEntityId
                            .nodes!
                        }
                      />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            )}

          {invoiceDetailsData?.invoice?.approvalHistorySignaturesByEntityId
            .nodes.length! > 0 && (
            <Stack>
              <Separator />
              <Stack
                className={styles.tagsContainer}
                tokens={{ childrenGap: 20 }}
              >
                <Text variant="xLarge">Signatures</Text>
                <SignatureView signatureData={signatureData} />
              </Stack>
            </Stack>
          )}
          {invoiceDetailsData?.invoice &&
            !!invoiceDetailsData?.invoice?.approvalHistoriesByEntityId?.nodes
              .length && (
              <Stack>
                <Separator />
                <Stack
                  className={styles.tagsContainer}
                  tokens={{ childrenGap: 20 }}
                >
                  <Text variant="xLarge">Approval History</Text>
                  <Text variant="medium" className={styles.requestedByText}>
                    Requested By:
                  </Text>
                  <ApprovalHistory
                    data={
                      invoiceDetailsData.invoice.approvalHistoriesByEntityId
                        ?.nodes || []
                    }
                  />
                </Stack>
              </Stack>
            )}
          <Prompt
            when={isDirty && !isSubmitting}
            message="Are you sure you want to leave your changes unsaved?"
          />
        </DraggablePanel>
      </FormProvider>
    </>
  );
};
