import {
  NetworkStatus,
  useApolloClient,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import {
  IColumn,
  Icon,
  IconButton,
  ScrollablePane,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
  TooltipHost,
} from '@fluentui/react';
import clsx from 'clsx';
import { ActiveLink } from 'common/components/ActiveRowLink';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { DownloadButton } from 'common/components/DownloadButton';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';

import { useCommonStyles } from 'common/styles';
import {
  EntityDocumentFilter,
  EntityDocumentsOrderBy,
  FileCabinetPoolsOrderBy,
} from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection } from 'common/utils/commonTypes';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import { fileType, fileTypeColor } from 'common/utils/fileType';
import { DocumentLoaderShimmer } from 'documents/documentAssignment/folder/list/Shimmer';
import { DocumentPoolDocumentsVariables } from 'documents/documentAssignment/folder/list/__generated__/DocumentPoolDocuments';
import { Tabs } from 'documents/fileCabinet/Tabs';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { DocumentAssignmentLocationState } from 'utility/locationStates';
import { MoveToFolder } from '../MoveToFolder';
import { filterObject } from './Filters/ListFilterCallout';
import { GroupHeader, filterOptionProps } from './GroupHeader';
import {
  FileCabinetFolderDocuments,
  FileCabinetFolderDocumentsVariables,
  FileCabinetFolderDocuments_fileCabinetPool_entityDocumentsByDocumentPoolIdAndEntityTypeId_nodes,
} from './__generated__/FileCabinetFolderDocuments';
import {
  FileCabinetPools,
  FileCabinetPoolsVariables,
  FileCabinetPools_fileCabinetPools_nodes,
} from './__generated__/FileCabinetPools';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import {
  getFiltersFromState,
  toFilterVariable,
  toOrderByVariable,
} from './utils';

const FILE_CABINET_POOL_DATA = loader('./FileCabinetPools.graphql');
const FILE_CABINET_FOLDER_DOCUMENTS = loader(
  './FileCabinetFolderDocuments.graphql'
);
export type DocumentEntity =
  FileCabinetFolderDocuments_fileCabinetPool_entityDocumentsByDocumentPoolIdAndEntityTypeId_nodes & {
    usedTotal: string;
    remainingTotal: string;
    isoCode: string;
    _uploadDate: string;
  };
export const FolderList: React.FC = () => {
  const { cache } = useApolloClient();
  const { addToast } = useToasts();
  let documentListData: DocumentEntity[] = [];
  const styles = useStyles();
  const [gridColumns, setGridColumns] = useState<IColumn[]>(columns);
  const stickyHeaderRef = useRef<Sticky>(null);
  const commonStyles = useCommonStyles();
  const history = useHistory<DocumentAssignmentLocationState | undefined>();
  const scrollToRef = useRef<HTMLDivElement>(null);
  const [selectedPool, setSelectedPool] = useState<
    FileCabinetPools_fileCabinetPools_nodes | undefined
  >();
  const [filterOptions, setFilterOptions] = useState<
    filterOptionProps | undefined
  >({ filterTypes: [filterObject], startsWith: true });
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });
  const [selectedList, setSelectedList] = useState<DocumentEntity[]>([]);

  const { loading: fileCabinetLoading, data: fileCabinetData } = useQuery<
    FileCabinetPools,
    FileCabinetPoolsVariables
  >(FILE_CABINET_POOL_DATA, {
    variables: {
      orderBy: [FileCabinetPoolsOrderBy.NAME_ASC],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });


  const [
    getEntityDocuments,
    {
      data: folderDocumentsData,
      loading: entityDocumentsLoading,
      variables: entityDocumentVariables,
      networkStatus,
      fetchMore,
      refetch: reFetchDocumentsList,
    },
  ] = useLazyQuery<
    FileCabinetFolderDocuments,
    FileCabinetFolderDocumentsVariables
  >(FILE_CABINET_FOLDER_DOCUMENTS, {
    variables: {
      id: selectedPool?.id!,
      filter: {
        _isLoading: {
          equalTo: false,
        },
      },
      first: 20,
      orderBy: [EntityDocumentsOrderBy._UPLOAD_DATE_DESC],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { nodes, pageInfo } = {
    ...folderDocumentsData?.fileCabinetPool
      ?.entityDocumentsByDocumentPoolIdAndEntityTypeId,
  };

  const onFilterChange = (filterOptions: filterOptionProps) => {
    const { filterTypes: filters, startsWith } = {
      ...filterOptions,
    };

    const searchOption = startsWith
      ? 'startsWithInsensitive'
      : 'includesInsensitive';

    const filtersApplied =
      filters?.length > 0
        ? ({
            and: toFilterVariable(filters, searchOption),
          } as EntityDocumentFilter)
        : undefined;
    setFilterOptions(filterOptions);
    if (filtersApplied) {
      getEntityDocuments({
        variables: {
          ...entityDocumentVariables,
          id: selectedPool?.id!,
          filter: {
            ...filtersApplied,
          },
        },
      });
    } else {
      const { filter, ...variables } = { ...entityDocumentVariables };
      getEntityDocuments({
        variables: {
          ...variables,
          id: selectedPool?.id!,
        },
      });
    }
  };

  const _headerOnClick = (pool: FileCabinetPools_fileCabinetPools_nodes) => {
    const { andFilters } = getFiltersFromState();
    setFilterOptions({
      filterTypes: [...andFilters],
      startsWith: true,
    });
    const filtersApplied: EntityDocumentFilter | undefined =
      !!andFilters?.length
        ? ({
            and: toFilterVariable(andFilters, 'startsWithInsensitive'),
          } as EntityDocumentFilter)
        : undefined;
    if (selectedPool?.id !== pool.id) {
      if (filtersApplied) {
        getEntityDocuments({
          variables: {
            ...entityDocumentVariables,
            id: pool.id,
            filter: {
              ...filtersApplied,
            },
          },
        });
      } else {
        const { filter, ...variables } = { ...entityDocumentVariables };
        getEntityDocuments({
          variables: {
            ...variables,
            id: pool.id,
          },
        });
      }
    }
    setSelectedPool((prevState) => {
      return prevState?.id === pool.id ? undefined : pool;
    });
  };

  const documentPoolIdRef = useRef('');
  const updateDocumentPoolIdRef = (newItems: string) => {
    documentPoolIdRef.current = newItems;
  };

  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;

  useEffect(() => {
    if (
      fileCabinetData &&
      scrollToRef.current &&
      history.location.state?.poolId &&
      stickyHeaderRef.current?.root
    ) {
      // [ref].scrollIntoView is broken in Chrome when using smooth behavior
      scrollToRef.current.offsetParent?.scrollTo({
        top:
          scrollToRef.current.offsetTop -
          stickyHeaderRef.current.root?.getBoundingClientRect().height,
        behavior: 'smooth',
      });
      const pool = fileCabinetData.fileCabinetPools?.nodes.find(
        (ele) => ele.id === history?.location?.state?.poolId
      );
      if (pool) {
        _headerOnClick(pool);
        // setDefaultDocumentTypeId(documentPool?.defaultDocumentTypeId!);
        // we only want to use location state for initial scroll after data load, not any
        // subsequent renders, so this clears it
        history.replace(history.location.pathname, undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileCabinetData]);

  const transformedData = refetching
    ? undefined
    : nodes?.map(
        (doc) =>
          ({
            ...doc,
            documentType: doc._documentType,
            usedTotal: doc?.documentAppliedAmounts?.usedTotal,
            remainingTotal: doc?.documentAppliedAmounts?.remainingTotal,
            isoCode: doc?.currency?.isoCode,
            _uploadDate: getGlobalDateFormat(doc._uploadDate || ''),
            indexTransactionDate: doc.indexTransactionDate
              ? dateFormat(dateConvertions(doc.indexTransactionDate))
              : null,
          } as DocumentEntity)
      );
  if (transformedData) documentListData = transformedData;

  const documentsListheight = documentListData.length * 35 + 70;
  const sectionHeight =
    documentsListheight > 630
      ? 630
      : documentListData.length === 0
      ? 150
      : documentsListheight;

  const _renderItemColumn = (
    item: DocumentEntity | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof DocumentEntity
      ] as string;

      switch (column?.key) {
        case '_documentType':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <ActiveLink
                key={item?.id}
                to={`/doc/fileCabinet/document/${item.id}/${selectedPool?.id}`}
              >
                {fieldContent}
              </ActiveLink>
            </Stack>
          );
        case '_uploadDate':
        case '_exportDate':
          return (
            <Stack
              className={styles.onrenderColumnStack}
              verticalAlign="center"
            >
              {fieldContent ? (
                <Text className={styles.contentColumnAlignRight}>
                  {getGlobalDateFormat(fieldContent)}
                </Text>
              ) : null}
            </Stack>
          );
        case 'usedTotal':
        case 'indexAmount':
        case 'remainingTotal':
          return (
            <Stack
              verticalAlign="center"
              className={clsx(styles.textAlignEnd, styles.onrenderColumnStack)}
            >
              <AmountTextView value={fieldContent} />
            </Stack>
          );
        case 'fileReference':
          return (
            <Stack
              verticalAlign={'center'}
              horizontal
              className={styles.onrenderColumnStack}
              tokens={{ childrenGap: 10 }}
            >
              <Icon
                className={fileTypeColor(item.iconType || 'OTHER')}
                iconName={fileType(item.iconType || 'OTHER')}
              />
              <Text>{fieldContent}</Text>
            </Stack>
          );
        case 'view':
          const viewDocumentVisible =
            item._isProtected! || item._fileViewer !== 'browser';
          return (
            <Stack
              className={styles.columnHeight}
              tokens={{ childrenGap: 10 }}
              horizontal
              verticalAlign="center"
            >
              <TooltipHost content="View" id="tooltipId">
                <IconButton
                  disabled={viewDocumentVisible}
                  iconProps={{ iconName: 'View' }}
                  onClick={() =>
                    setDocViewState({
                      isOpen: true,
                      title: item.fileReference,
                      entityDocumentId: item.id,
                      _fileType: item._fileType!,
                    })
                  }
                />
              </TooltipHost>
            </Stack>
          );
        case 'download':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
              horizontalAlign={'center'}
            >
              <DownloadButton entityDocumentId={item.id} />
            </Stack>
          );

        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              {fieldContent && <Text>{fieldContent}</Text>}
            </Stack>
          );
      }
    }
  };

  const _onColumnClick = (clickedColumn: ColumnData): void => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    getEntityDocuments({
      variables: {
        ...entityDocumentVariables,
        id: documentPoolIdRef.current,
        orderBy: toOrderByVariable({
          column: clickedColumn.key,
          direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
        }),
      },
    });
  };

  const loadMore = useCallback(async () => {
    const newVariables: DocumentPoolDocumentsVariables = {
      ...entityDocumentVariables,
      id: selectedPool?.id!,
      after: pageInfo?.endCursor,
    };
    await fetchMore?.({
      variables: { ...newVariables },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMore, entityDocumentVariables, pageInfo, selectedPool?.id]);
 

  return (
    <ScrollablePane>
      <Stack className={styles.container}>
        <Sticky
          stickyPosition={StickyPositionType.Header}
          ref={stickyHeaderRef}
        >
          <Stack
            tokens={{ childrenGap: 20 }}
            className={commonStyles.listHeaderContainer}
          >
            <Stack
              horizontal
              horizontalAlign="space-between"
              verticalAlign="center"
              className={commonStyles.listTitleContainer}
            >
              <Text variant="xLarge">File Cabinet - Folders</Text>
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{
                  childrenGap: 10,
                }}
              >
                {selectedList.length > 0 && (
                  <MoveToFolder
                    poolSelected={selectedPool}
                    documentsSelected={selectedList}
                    onSuccess={(receivingFolderId, movedDocumentsCount) => {
                      if (receivingFolderId && movedDocumentsCount) {
                        selectedList.forEach((ele) => {
                          const identity = cache.identify({
                            ...ele,
                          });
                          cache.evict({ id: identity });
                          cache.gc();
                        });
                        const poolIdentity = cache.identify({
                          ...selectedPool,
                        });
                        cache.modify({
                          id: poolIdentity,
                          fields: {
                            fileCabinetPoolTotals(existing) {
                              return {
                                ...existing,
                                availableDocuments:
                                  existing.availableDocuments -
                                  movedDocumentsCount,
                              };
                            },
                          },
                        });
                        const receivingPool =
                          fileCabinetData?.fileCabinetPools?.nodes.find(
                            (ele) => ele.id === receivingFolderId
                          );
                        if (receivingPool) {
                          const receivingPoolIdentity = cache.identify({
                            ...receivingPool,
                          });
                          if (receivingPoolIdentity) {
                            cache.modify({
                              id: receivingPoolIdentity,
                              fields: {
                                fileCabinetPoolTotals(existing) {
                                  return {
                                    ...existing,
                                    availableDocuments:
                                      existing.availableDocuments +
                                      movedDocumentsCount,
                                  };
                                },
                              },
                            });
                            addToast(
                              `${movedDocumentsCount} files moved to "${receivingPool.name}"`,
                              { appearance: 'success' }
                            );
                          }
                          if (selectedList.length === documentListData.length) {
                            reFetchDocumentsList?.({
                              ...entityDocumentVariables,
                            });
                          }
                        }
                      }
                    }}
                  />
                )}
              </Stack>
            </Stack>
            <Stack tokens={{ childrenGap: 10 }}>
              <Stack
                horizontal
                verticalAlign="end"
                horizontalAlign="space-between"
              >
                <Tabs />
              </Stack>
            </Stack>
          </Stack>
        </Sticky>
        {fileCabinetLoading && <DocumentLoaderShimmer />}
        {fileCabinetData?.fileCabinetPools?.nodes.map((documentPool, index) => {
          const { fileCabinetPoolTotals } = { ...documentPool };
          const { availableDocuments, selectedDocuments } = {
            ...fileCabinetPoolTotals,
          };
          const totalCount =
            (availableDocuments || 0) + (selectedDocuments || 0);
          return (
            <Stack key={index.toString()}>
              <div
                ref={
                  documentPool.id === history.location.state?.poolId
                    ? scrollToRef
                    : undefined
                }
              >
                <GroupHeader
                  isOpen={selectedPool?.id === documentPool.id}
                  availableCount={totalCount || 0}
                  filterOptions={filterOptions!}
                  onFilterChange={(filterOptions) => {
                    onFilterChange(filterOptions);
                  }}
                  documentPool={documentPool}
                  onHeaderClick={(documentPoolItem) => {
                    setFilterOptions({
                      filterTypes: [filterObject],
                      startsWith: true,
                    });
                    if (totalCount) _headerOnClick(documentPoolItem);
                    updateDocumentPoolIdRef(documentPoolItem.id);
                  }}
                />
              </div>
              {selectedPool?.id === documentPool.id && availableDocuments && (
                <Stack style={{ height: sectionHeight }}>
                  <InfiniteList
                    items={documentListData}
                    loading={entityDocumentsLoading}
                    hasNextPage={pageInfo?.hasNextPage}
                    columns={gridColumns}
                    onRenderItemColumn={_renderItemColumn}
                    onColumnHeaderClick={(_, column) => {
                      if (column) _onColumnClick(column);
                    }}
                    onLoadMore={loadMore}
                    onSelectionChanged={setSelectedList}
                  />
                </Stack>
              )}
            </Stack>
          );
        })}
      </Stack>
      <DocumentViewModal
        centerAlign
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        {...docViewState}
      />
    </ScrollablePane>
  );
};
