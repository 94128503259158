import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  DefaultButton,
  DirectionalHint,
  IDropdownOption,
  IPanelHeaderRenderer,
  IconButton,
  PrimaryButton,
  ProgressIndicator,
  Separator,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { DocumentViewModalState } from 'common/components/DocumentList';

import { TransactionModal } from 'ap/paymentCycle/view/TransactionModal';
import {
  DocumentViewModal,
  VIEW_DOC_MODAL_WIDTH,
} from 'common/components/DocumentList/DocumentViewModal';
import DraggablePanel from 'common/components/DraggablePanel';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { PanelHeader } from 'common/components/PanelHeader';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { CompanyCurrencies } from 'common/graphql/__generated__/CompanyCurrencies';
import {
  DocumentPoolFiles,
  DocumentPoolFilesVariables,
  DocumentPoolFiles_documentPools_nodes,
} from 'common/graphql/__generated__/DocumentPoolFiles';
import { useCommonStyles } from 'common/styles';
import {
  DocumentPoolsOrderBy,
  TransactionLayout,
} from 'common/types/globalTypes';
import { PanelCommonProps } from 'common/types/utility';
import { formatDropdownOptions } from 'common/utils';
import { getWindowDimensions } from 'common/utils/columnUtilities';
import {
  GetDocumentPoolCommonData,
  GetDocumentPoolCommonDataVariables,
} from 'documents/__generated__/GetDocumentPoolCommonData';
import { BuildTransaction } from 'documents/documentAssignment/BuildTransactions';
import { DeleteDocument } from 'documents/documentAssignment/DeleteDocument';
import { VerifyDocuments } from 'documents/documentAssignment/VerifyDocuments';
import {
  EntityDocumentVerify,
  EntityDocumentVerifyVariables,
} from 'documents/documentAssignment/VerifyDocuments/__generated__/EntityDocumentVerify';
import { DocumentTypeOptions } from 'documents/personalPool/UploadDocumentsForm';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ReactComponent as ApprovedIcon } from '../../../../../assets/svgs/ApproveStatusIcons/Approved.svg';
import { GetEntityDocument } from '../__generated__/GetEntityDocument';
import { EntityDocumentValues } from '../types';
import { Accounting } from './AccountingView';
import { ActionsMenu } from './ActionMenu';
import { BasicFormView } from './BasicForm';
import { CustomDropDown } from './CustomDropdown';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { TrackingTransactionModal } from './TrackingModal';
import { useStyles } from './index.styles';
import { PaymentAccountingView } from './PaymentAccountingView';
import { ResetAccountingDetails } from './ResetAccountingDetails';
const DOCUMENT_POOL_DATA = loader(
  '../../../../../common/graphql/DocumentPool.graphql'
);
const GENERAL_DATA = loader('../../../../GetDocumentPoolCommonData.graphql');
const CURRENCY_DATA = loader(
  '../../../../../common/graphql/CompanyCurrencies.graphql'
);
const VERIFY_DOCUMENTS = loader(
  '../../../VerifyDocuments/EntityDocumentVerify.graphql'
);
interface FormViewProps {
  updateDocLoading: boolean;
  selectedDocumentId: string;
  selectedPoolId: string | undefined;
  onSave: () => void;
  isNew: boolean;
  documentData: GetEntityDocument | undefined;
  dataLoading: boolean;
  refetch: () => void;
}

export const FormView: React.FC<FormViewProps> = ({
  updateDocLoading,
  selectedDocumentId,
  selectedPoolId,
  onSave,
  isNew,
  documentData,
  dataLoading,
  refetch,
}) => {
  const styles = useStyles();
  const history = useHistory();
  const commonStyles = useCommonStyles();
  const { cache } = useApolloClient();

  const { documentId } = useParams<{ documentId: string | undefined }>();
  const [isAccountingDocument, setIsAccountingDocument] =
    useState<boolean>(false);
  const [openTrackingModal, setOpenTrackingModal] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<DocumentTypeOptions>();
  const [fileSelected, setFileSelected] = useState<File[]>([]);
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  const { addToast } = useToasts();

  const [showIsAccountingDocumentInputs, setShowIsAccountingDocumentInputs] =
    useState<boolean>(true);

  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });

  const {
    formState: { isSubmitting, isDirty, errors },
  } = useFormContext<EntityDocumentValues>();

  const clearData = () => {
    setIsAccountingDocument(false);
    setDocumentType(undefined);
    setFileSelected([]);
  };

  const ENTITY_DOCUMENT = loader('../GetEntityDocument.graphql');
  const { width } = getWindowDimensions();
  const documentViewPosition = width
    ? width - (900 + VIEW_DOC_MODAL_WIDTH)
    : 50;

  const [verifyDocuments] = useMutation<
    EntityDocumentVerify,
    EntityDocumentVerifyVariables
  >(VERIFY_DOCUMENTS);
  const { data: DocumentFilesData } = useQuery<
    DocumentPoolFiles,
    DocumentPoolFilesVariables
  >(DOCUMENT_POOL_DATA, {
    variables: {
      orderBy: [DocumentPoolsOrderBy.NAME_ASC],
      filter: {
        defaultDocumentTypes: {
          isReportingDocument: {
            equalTo: false,
          },
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [dataExtractModelOptions, setDataExtractModelOptions] = useState<
    IDropdownOption[]
  >([]);

  const [hideExtractionDropdown, setHideExtractionDropdown] =
    useState<boolean>(false);
  const [transactionModalVisible, setTransactionModalVisible] = useState(false);

  const [documentOptions, setDocumentOptions] = useState<DocumentTypeOptions[]>(
    []
  );

  const [defaultDocumentTypeId, setDefaultDocumentTypeId] = useState<number>();
  const [isDocumentTypeSelector, setIsDocumentTypeSelector] =
    useState<boolean>(false);
  const [extractionTypeId, setExtractionTypeId] = useState<number | null>(null);

  const { data: GeneralData, loading } = useQuery<
    GetDocumentPoolCommonData,
    GetDocumentPoolCommonDataVariables
  >(GENERAL_DATA, {
    variables: {
      isDocumentUpload: true,
    },
  });

  const { data: currencyData } = useQuery<CompanyCurrencies>(CURRENCY_DATA);
  const currencyTypes = formatDropdownOptions(
    currencyData?.companyCurrencies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.isoCode + '-' + item.name!,
      includeAll: false,
    }
  );

  const filterDocumentOptions = (
    documentPoolItem: DocumentPoolFiles_documentPools_nodes
  ) => {
    const dataArr = GeneralData?.documentPoolAvailableDocumentTypes?.nodes
      .filter(
        (defaultDocumentTypeId) =>
          defaultDocumentTypeId.id === documentPoolItem.defaultDocumentTypeId
      )
      .map((item) => ({
        key: item.id,
        text: item?.documentType! || '',
        isAccountingDocument: item?.isAccountingDocument!,
        isSigningRequired: item?.isSigningRequired!,
        extractionTypes: item.extractionTypes,
      }));

    return dataArr;
  };

  const withoutFilterDocumentOptions = () => {
    const documentTypes =
      GeneralData?.documentPoolAvailableDocumentTypes?.nodes.map((item) => ({
        key: item.id,
        text: item?.documentType! || '',
        isAccountingDocument: item?.isAccountingDocument!,
        isSigningRequired: item?.isSigningRequired!,
        extractionTypes: item.extractionTypes,
      })) || [];
    return documentTypes;
  };

  useEffect(() => {
    if (documentData && documentData.entityDocument?.id) {
      setIsAccountingDocument(
        documentData.entityDocument.documentTypes?.isAccountingDocument || false
      );
    }
  }, [documentData]);

  useEffect(() => {
    if (DocumentFilesData && !loading) {
      const documentPool = DocumentFilesData?.documentPools?.nodes.find(
        (item) => item.id === documentData?.entityDocument?._documentPoolId
      );

      if (documentPool?.defaultDocumentTypeId) {
        if (documentPool.isDocumentTypeSelector) {
          setDocumentOptions(filterDocumentOptions(documentPool) || []);
        } else {
          setDocumentOptions(withoutFilterDocumentOptions);
        }
      } else {
        const data = withoutFilterDocumentOptions();

        setDocumentOptions(data);
      }
      setDefaultDocumentTypeId(documentPool?.defaultDocumentTypeId!);
      setIsDocumentTypeSelector(documentPool?.isDocumentTypeSelector!);
      setExtractionTypeId(documentPool?.extractionTypeId!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DocumentFilesData, loading]);

  useEffect(() => {
    if (defaultDocumentTypeId && documentData?.entityDocument) {
      const optionMap = documentOptions?.map(
        (item) => item as DocumentTypeOptions
      );
      let optionFilter: DocumentTypeOptions[] | undefined;
      if (documentData && documentData.entityDocument.id) {
        optionFilter = optionMap?.filter(
          (item) =>
            item.key === documentData.entityDocument?.entityDocumentTypeId
        );
      } else {
        optionFilter = optionMap?.filter(
          (item) => item.key === defaultDocumentTypeId
        );
      }
      if (optionFilter && optionFilter[0]) {
        setDocumentType(optionFilter[0]);
        setIsAccountingDocument(optionFilter[0].isAccountingDocument || false);
        if (optionFilter![0].isAccountingDocument) {
          setFileSelected([]);
        }
      }
    } else {
    }
  }, [defaultDocumentTypeId, documentOptions, documentData]);

  const onVerifyClick = async () => {
    const { id, _rowTimestamp } = { ...documentData?.entityDocument };
    const { errors } = await verifyDocuments({
      variables: {
        input: {
          entitySelection: [{ id, rowTimestamp: _rowTimestamp }],
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: documentData?.entityDocument?.id
        ? [
            {
              query: ENTITY_DOCUMENT,
              variables: {
                documentId: documentData?.entityDocument?.id,
              },
            },
          ]
        : [],
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else
      addToast('Documents verified successfully', {
        appearance: 'success',
      });
  };

  // CUSTOM RENDER HEADER
  const _onRenderHeader: IPanelHeaderRenderer = () => {
    const { _isVerified, documentTypes, _isUpdatable } = {
      ...documentData?.entityDocument,
    };
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          clearData();
          history.replace('/doc/documentAssignment');
        }}
      >
        <>
          <Stack grow horizontal horizontalAlign="space-between">
            <Stack tokens={{ childrenGap: 10 }}>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <VerifyDocuments
                  document={documentData}
                  _onConfirm={onVerifyClick}
                  isDisabled={isDirty}
                />
                {_isVerified && (
                  <TooltipHost
                    directionalHint={DirectionalHint.leftCenter}
                    content={'Document Verified'}
                  >
                    <ApprovedIcon />
                  </TooltipHost>
                )}
                <Text
                  className={_isVerified ? commonStyles.themeGreen : ''}
                  variant="xLarge"
                >
                  Document
                </Text>

                <Text
                  variant="xLarge"
                  className={commonStyles.colorThemePrimary}
                >
                  {documentTypes?.documentType}
                </Text>

                <UnsavedIndicator
                  visible={!isNew && isDirty && !isSubmitting && !!_isUpdatable}
                />
              </Stack>
              {/* {documentData?.entityDocument?._isSelected && (
                <Stack className={styles.historyContainer}>
                  <Text className={styles.historyText}>History</Text>
                </Stack>
              )} */}

              <Stack
                horizontal
                tokens={{ childrenGap: 5 }}
                verticalAlign="center"
              >
                {documentData?.entityDocument?._transactionHistoryId && (
                  <TooltipHost
                    content={'View Transaction'}
                    directionalHint={DirectionalHint.leftCenter}
                  >
                    <IconButton
                      iconProps={{ iconName: 'DocumentApproval' }}
                      onClick={() => setTransactionModalVisible(true)}
                      text="History"
                    />
                  </TooltipHost>
                )}
                {documentData?.entityDocument?._isSelected && (
                  <Text className={commonStyles.colorThemePrimary}>
                    History
                  </Text>
                )}
              </Stack>
            </Stack>
            {documentData?.entityDocument?.id && (
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <IconButton
                  iconProps={{ iconName: 'View' }}
                  onClick={() => {
                    const { fileReference, id, _fileType } = {
                      ...documentData.entityDocument,
                    };
                    setDocViewState({
                      isOpen: true,
                      title: fileReference,
                      entityDocumentId: id,
                      _fileType: _fileType!,
                    });
                  }}
                />
                <DeleteDocument
                  selectedPoolId={selectedPoolId}
                  document={documentData}
                />
              </Stack>
            )}
          </Stack>
        </>
      </PanelHeader>
    );
  };

  const isDocumentProtected = documentData
    ? !documentData.entityDocument?._isUpdatable
    : false;
  const disableCustomDropdown =
    isDisabled || isDocumentTypeSelector || documentOptions?.length === 1;

  const multipleIsAccountingDocumentSelected =
    isAccountingDocument && fileSelected.length > 1;
  useEffect(() => {
    if (multipleIsAccountingDocumentSelected) {
      setShowIsAccountingDocumentInputs(false);
    } else setShowIsAccountingDocumentInputs(true);
  }, [multipleIsAccountingDocumentSelected]);

  const [isSigningDocument, setIsSigningDocument] = useState(false);

  useEffect(() => {
    const tempIsSigningDocument =
      documentData?.entityDocument?.documentTypes?.isSigningRequired &&
      !documentData.entityDocument.documentTypes.isAccountingDocument;
    setIsSigningDocument(!!tempIsSigningDocument);
  }, [documentData]);

  const onTrackingClick = () => {
    setDocViewState({ isOpen: false, _fileType: 'pdf' });
    setOpenTrackingModal(true);
  };

  const showAccountingArea =
    documentData?.entityDocument?._entryLayout ===
      TransactionLayout.DOCUMENT_ACCOUNTING_ENTRY ||
    documentData?.entityDocument?._entryLayout ===
      TransactionLayout.DOCUMENT_TIME_CARD_ACCOUNTING_ENTRY;
  const showPaymentAccountingArea =
    documentData?.entityDocument?._entryLayout ===
      TransactionLayout.DOCUMENT_PAYMENT ||
    documentData?.entityDocument?._entryLayout ===
      TransactionLayout.DOCUMENT_PAYMENT_REGISTER;

  useEffect(() => {
    setOpenTrackingModal(false);
  }, [documentId]);

  return (
    <DraggablePanel
      {...PanelCommonProps}
      initialWidth={900}
      minWidth={900}
      isBlocking={false}
      onRenderHeader={_onRenderHeader}
      onRenderFooter={() => (
        <FooterActionBar
          showButtons={{
            showCreateNewButton: false,
            showOnSaveButton: false,
          }}
          disabled={
            updateDocLoading || {
              save: !isDirty || Object.keys(errors).length > 0,
              cancel: !isDirty,
            }
          }
          isSubmitting={isSubmitting || resetLoading}
          onCancel={() => history.replace('/doc/documentAssignment')}
          onSave={() => {
            onSave();
          }}
          children={
            <Stack className={styles.footerStyles}>
              {updateDocLoading && <ProgressIndicator />}
              <Stack
                horizontalAlign="space-between"
                horizontal
                tokens={{ childrenGap: 6, padding: 25 }}
              >
                <Stack
                  horizontal
                  tokens={{
                    childrenGap: 20,
                    padding: '0px 0px 0px 20px',
                  }}
                >
                  <PrimaryButton
                    disabled={
                      !isDirty ||
                      Object.keys(errors).length > 0 ||
                      isSubmitting ||
                      (!documentData?.entityDocument?.id &&
                        fileSelected.length === 0)
                    }
                    text={'Save'}
                    onClick={() => {
                      onSave();
                      if (!documentData?.entityDocument?.id) {
                        clearData();
                      }
                    }}
                  />
                  <DefaultButton
                    onClick={() => {
                      clearData();
                      history.replace('/doc/documentAssignment');
                    }}
                    text="Cancel"
                  />
                </Stack>

                <Stack
                  horizontal
                  tokens={{
                    childrenGap: 20,
                  }}
                >
                  <BuildTransaction
                    allowMultiFileBundleToggle={false}
                    companyDepartments={GeneralData?.companyDepartments}
                    companyBusinessUnits={GeneralData?.companyBusinessUnits}
                    selectedList={[documentData?.entityDocument!]}
                    onBuildComplete={() => {
                      if (documentData) {
                        cache.modify({
                          id: `DocumentPool:${selectedPoolId}`,
                          fields: {
                            _documentFileCount: (existingData) => {
                              return {
                                ...existingData,
                                selectedDocuments:
                                  existingData.selectedDocuments + 1,
                                availableDocuments:
                                  existingData.availableDocuments - 1,
                              };
                            },
                          },
                        });
                      }
                    }}
                    onCloseDocumentView={() =>
                      setDocViewState({ isOpen: false, _fileType: 'pdf' })
                    }
                  />
                  {documentData && (
                    <Stack>
                      <ResetAccountingDetails
                        document={documentData}
                        onResetLoading={setResetLoading}
                      />
                    </Stack>
                  )}
                  {documentData?.entityDocument?.documentTypes
                    ?._isAppliableDocumentType && (
                    <PrimaryButton
                      text={'Tracking sheet'}
                      onClick={onTrackingClick}
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
          }
        />
      )}
      isOpen
      onDismiss={() => {
        history.replace('/doc/documentAssignment');
      }}
      isLightDismiss
    >
      <>
        {!isNew && dataLoading ? (
          <ShimmerView />
        ) : (
          <>
            <Stack
              tokens={{ childrenGap: 20 }}
              style={{ padding: '30px 25px 150px 25px' }}
            >
              {!!documentData && (
                <ActionsMenu
                  invoiceDetails={documentData}
                  secureRowLevels={GeneralData?.secureRowLevels}
                />
              )}
              <Separator />
              {documentOptions && (
                <CustomDropDown
                  setFileSelected={() => setFileSelected([])}
                  isEdit={selectedDocumentId ? true : false}
                  defaultDocumentTypeId={defaultDocumentTypeId}
                  documentData={documentData?.entityDocument!}
                  isDocumentProtected={isDocumentProtected}
                  documentOptions={documentOptions}
                  setDocumentType={(data) => setDocumentType(data)}
                  setIsAccountingDocument={(isAccounting) =>
                    setIsAccountingDocument(isAccounting!)
                  }
                  disabled={disableCustomDropdown}
                  onDocumentTypeChange={setDataExtractModelOptions}
                  hideExtractionDropdown={() => setHideExtractionDropdown(true)}
                  setIsSigningDocument={setIsSigningDocument}
                />
              )}

              <BasicFormView
                isAccountingDocument={isAccountingDocument}
                isDocumentProtected={isDocumentProtected}
                showIsAccountingDocumentInputs={showIsAccountingDocumentInputs}
                documentData={documentData?.entityDocument}
                documentType={documentType}
                companyCurrencies={currencyTypes}
                onInputFieldsDisabled={setIsDisabled}
                dataExtractModelOptions={dataExtractModelOptions}
                isEdit={selectedDocumentId ? true : false}
                isDocumentTypeSelector={isDocumentTypeSelector}
                hideExtractionDropdown={hideExtractionDropdown}
                isExtractionTypeSelected={extractionTypeId ? true : false}
                isSigningDocument={isSigningDocument}
              />
              {showAccountingArea && (
                <>
                  <Stack tokens={{ padding: '20px 0px' }}>
                    <Text variant="xLarge">Accounting Details</Text>
                  </Stack>
                  <Accounting
                    documentData={documentData}
                    documentIsUpdatable={
                      documentData?.entityDocument?._isUpdatable!
                    }
                  />
                </>
              )}
              {showPaymentAccountingArea && (
                <>
                  <Stack tokens={{ padding: '20px 0px' }}>
                    <Text variant="xLarge">Accounting Details</Text>
                    <PaymentAccountingView
                      documentData={documentData}
                      documentIsUpdatable={
                        documentData?.entityDocument?._isUpdatable!
                      }
                    />
                  </Stack>
                </>
              )}
            </Stack>
            <DocumentViewModal
              paddingTop={0}
              paddingLeft={documentViewPosition}
              onDismiss={() =>
                setDocViewState({ isOpen: false, _fileType: 'pdf' })
              }
              {...docViewState}
            />
            <Prompt
              when={isDirty && !isSubmitting}
              message="Are you sure you want to leave your changes unsaved?"
            />
            {transactionModalVisible && (
              <TransactionModal
                entityId={documentData?.entityDocument?._transactionHistoryId!}
                isOpen={transactionModalVisible}
                onDismiss={() => setTransactionModalVisible(false)}
              />
            )}
            {openTrackingModal &&
              documentData &&
              documentData.entityDocument &&
              documentData?.entityDocument?._documentFileId && (
                <TrackingTransactionModal
                  isOpen={openTrackingModal}
                  onClose={() => {
                    setOpenTrackingModal(false);
                  }}
                  onRetireCallback={() => {
                    setOpenTrackingModal(false);
                    refetch();
                  }}
                  documentData={documentData}
                  entityDocument={documentData.entityDocument}
                  documentFileId={documentData?.entityDocument?._documentFileId}
                />
              )}
          </>
        )}
      </>
    </DraggablePanel>
  );
};
