import {
  DayOfWeek,
  DirectionalHint,
  Icon,
  IDropdownOption,
  Label,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';

import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import {
  FormHookAmount,
  FormHookAutoComplete,
  FormHookDatePicker,
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { ApprovalState, TransactionLayout } from 'common/types/globalTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import Decimal from 'decimal.js';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  InvoiceDetails,
  InvoiceDetails_invoice_lookupName,
} from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoiceDetails';
import { BankDetails } from './BankDetails';
import { useStyles } from './index.styles';
import { ReimburseAccountField } from './ReimburseAccount';
import { SelectField } from './SelectField';
import { getLabelsAndPlaceholder, LabelParams } from './utils';
import { TransactionSigningValues } from 'common/components/Modules/TransactionEdit/types';
import { InvoiceTransactionTypes, InvoiceTransactionTypes_cardHolders_nodes, InvoiceTransactionTypes_companyDepartments_nodes } from '../graphql/__generated__/InvoiceTransactionTypes';

type LookupName = InvoiceDetails_invoice_lookupName;
interface BasicFormProps {
  invoiceDetails?: InvoiceDetails | undefined;
  transactionTypeData: InvoiceTransactionTypes | null | undefined;
  submitBasicForm: () => void;
  disableCardHolder?: boolean;
  receiptTotal?: string | null;
  userDefaultsData: UserDefaults_userDefaults_nodes[];
}
interface TransactionTypeOptions extends IDropdownOption {
  key: string | number;
  text: string;
  layoutType: string | undefined | null;
}

interface CardHolderOptions extends IDropdownOption {
  key: string | number;
  text: string;
  departmentId: string | null;
}

interface CurrencyTypeOptions extends IDropdownOption {
  key: string | number;
  text: string;
  isoCode: string | null;
}

type DepartmentOptionProps = IDropdownOption &
  InvoiceTransactionTypes_companyDepartments_nodes;

export interface LabelParam {
  vendor: string;
  vendorPlaceHolder?: string | undefined;
  invoiceNumber: string | undefined;
  invoiceDate: string | undefined;
  invoiceTotal: string | undefined;
}

export const BasicForm: React.FC<BasicFormProps> = ({
  invoiceDetails,
  transactionTypeData,
  disableCardHolder,
  receiptTotal,
  userDefaultsData,
}) => {
  const { setValue, getValues, trigger } =
    useFormContext<TransactionSigningValues>();
  const {
    companyBusinessUnits,
    companyCurrencies,
    invoiceTransactionTypes,
    companyDepartments,
    companyCorporatePeriods,
  } = {
    ...transactionTypeData,
  };
  const watch = useWatch<TransactionSigningValues>();

  const {
    id,
    businessUnitId,
    statusType,
    _isUpdatable,
    _isDocumentsExist,
    lookupName,
    _elapsedTriggerDays,
  } = { ...invoiceDetails?.invoice };

  const values = getValues();
  const isNew = id ? false : true;
  const style = useStyles();
  const [cardHolderOption, setCardHolderOption] = useState<
    InvoiceTransactionTypes_cardHolders_nodes | undefined
  >();
  const [transactionLayoutType, setTransactionLayoutType] = useState<
    string | undefined | null
  >();
  const [labelsState, setLabelsState] = useState<LabelParams | undefined>();

  const transactionTypeOptions = useMemo(
    () =>
      invoiceTransactionTypes?.nodes.map((item) => ({
        key: item.id,
        text: item.transactionType || '',
        layoutType: item.layoutType,
      })) || [],
    [invoiceTransactionTypes]
  );

  const businessUnitOptions = useMemo(
    () =>
      companyBusinessUnits?.nodes.map((item) => ({
        key: item.id,
        text: item.name || '',
      })) || [],
    [companyBusinessUnits]
  );

  const currencyOptions = useMemo(
    () =>
      companyCurrencies?.nodes.map((item) => ({
        key: item.id,
        text: item.isoCode! + '-' + item.name || '',
        isoCode: item.isoCode,
      })) || [],
    [companyCurrencies]
  );

  const departmentOptions = useMemo(
    () =>
      companyDepartments?.nodes.map(
        (item) =>
          ({
            key: item.id,
            text: item.name || '',
            disabled: !item._isTransSelectable,
            ...item,
          } as DepartmentOptionProps)
      ) || [],
    [companyDepartments]
  );

  const accountingPeriodOptions = useMemo(
    () =>
      companyCorporatePeriods?.nodes.map((item) => ({
        disabled: item.isEntryAllowed ? false : true,
        key: item.id,
        text:
          item._periodYear +
            ' (' +
            dateFormat(dateConvertions(item.startDate)) +
            ' - ' +
            dateFormat(dateConvertions(item.endDate)) +
            ')' || '',
      })) || [],

    [companyCorporatePeriods]
  );

  const [totalSum, setTotalSum] = useState<Decimal | null>(new Decimal(0));
  const [showBankDetails, setShowBankDetails] = useState<boolean>(false);
  const [isPCardTransaction, setIsPCardTransaction] = useState<boolean>(false);
  const [isUserAssigned, setIsUserAssigned] = useState<
    boolean | null | undefined
  >(true);
  const [isUserControlled, setIsUserControlled] = useState<
    boolean | null | undefined
  >(null);
  const cardHolderOptions = useMemo(
    () =>
      transactionTypeData?.cardHolders?.nodes
        .filter((item) =>
          values.departmentId !== null
            ? item.departmentId === values.departmentId
            : true
        )
        .map((item) => ({
          key: item.id,
          text: item.cardFullName || '',
        })) || [],
    [transactionTypeData, values.departmentId]
  );

  const isBusinessUnitExist = businessUnitId ? true : false;
  // Disable condition based on approval state
  const disableBasedOnApprovalState =
    statusType?.approvalState === ApprovalState.APPROVED ||
    statusType?.approvalState === ApprovalState.PENDING;
  // Disable condition based on _isUpdatable
  let disableBasedOnIsUpdatable: boolean = false;
  if (_isUpdatable === false) disableBasedOnIsUpdatable = true;
  // Final disable condition
  const isDisabled = disableBasedOnApprovalState
    ? disableBasedOnApprovalState
    : disableBasedOnIsUpdatable;

  const [clearVendorReference, setClearVendorReference] =
    useState<boolean>(false);

  useEffect(() => {
    setTransactionLayoutType(values.transactionType?.layoutType);
  }, [values.transactionType]);

  useEffect(() => {
    setShowBankDetails(false);
    setIsPCardTransaction(false);
    const labelsPlaceholder = getLabelsAndPlaceholder(
      transactionLayoutType,
      setIsPCardTransaction,
      setShowBankDetails
    );
    setLabelsState(labelsPlaceholder);
  }, [transactionLayoutType]);

  useEffect(() => {
    let distributionAmount: null | number | string = 0;
    if (!!watch.invoiceDistributions?.length) {
      const total = watch.invoiceDistributions.reduce((sum, current) => {
        if (!!current.distributionAmount) {
          try {
            distributionAmount = current.distributionAmount || 0;
            return new Decimal(distributionAmount).plus(sum);
          } catch (error) {
            return sum;
          }
        } else {
          return sum;
        }
      }, new Decimal(0));
      setTotalSum(total);
    }
  }, [watch.invoiceDistributions]);

  let companyCardHoldersOptions: CardHolderOptions[] = [];

  if (transactionTypeData) {
    companyCardHoldersOptions =
      transactionTypeData.companyCardHolders?.nodes.map((ele) => ({
        key: ele.id,
        text: `${ele.cardFullName} (${ele.cardNumber})`,
        departmentId: ele.departmentId,
      })) || [];
  }

  const onTransactionTypeChange = async (
    selectedOption: TransactionTypeOptions
  ) => {
    setTransactionLayoutType(selectedOption.layoutType);
    await setValue('vendorReference', null);
    await setValue('cardHolderId', null);
    await setValue(
      'layoutType',
      selectedOption.layoutType ? selectedOption.layoutType : null
    );
    setClearVendorReference(true);
    if (isNew && selectedOption.layoutType === TransactionLayout.PAY_CARD)
      await setValue('controlTotalAmount', null);
    if (
      isNew &&
      (selectedOption.layoutType === TransactionLayout.SIGNING_DOCUMENT ||
        selectedOption.layoutType === TransactionLayout.SIGNING_AMOUNT_DOCUMENT)
    ) {
      await setValue('controlTotalAmount', '0.00');
      await setValue('currencyId', 0);
    }
    trigger();
  };

  const setUserDefaultValues = async () => {
    if (isNew && userDefaultsData) {
      await setValue('businessUnitId', userDefaultsData[0].businessUnitId);
      await setValue('departmentId', userDefaultsData[0].departmentId);
      await setValue(
        'corporatePeriodId',
        userDefaultsData[0].corporatePeriodId
      );
      await setValue('currencyId', userDefaultsData[0].currencyId);
      trigger();
    }
  };

  useEffect(() => {
    if (departmentOptions && values.departmentId) {
      if (values.departmentId) {
        const departmentIndex = departmentOptions.findIndex(
          (item) => item.id === values.departmentId
        );
        if (
          departmentIndex &&
          departmentOptions[departmentIndex] &&
          departmentOptions[departmentIndex].userDepartment
        ) {
          setIsUserAssigned(
            departmentOptions[departmentIndex].userDepartment?._isUserAssigned
          );
          setIsUserControlled(
            departmentOptions[departmentIndex].userDepartment?._isUserControlled
          );
        }
      }
    }
  }, [departmentOptions, values]);

  useEffect(() => {
    if (values.cardHolderId) {
      const findData = transactionTypeData?.cardHolders?.nodes.find(
        (data) => data.id === values.cardHolderId
      );
      setCardHolderOption(findData);
    }
  }, [values.cardHolderId, transactionTypeData]);

  let controlledAmount = 0;
  try {
    if (values?.controlTotalAmount) {
      controlledAmount = parseFloat(values?.controlTotalAmount);
    }
  } catch (error) {}

  const isSigningDocument =
    transactionLayoutType === TransactionLayout.SIGNING_DOCUMENT ||
    transactionLayoutType === TransactionLayout.SIGNING_AMOUNT_DOCUMENT;

  return (
    <>
      <Stack
        horizontal
        tokens={{ childrenGap: 50 }}
        className={style.mainContainer}
      >
        <Stack
          grow={4}
          tokens={{ childrenGap: 20, padding: '10px 0px 0px 0px' }}
        >
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1}>
              <FormHookDropdown
                label="Transaction Type"
                placeholder="Select"
                options={transactionTypeOptions}
                name="transactionTypeId"
                disabled={_isDocumentsExist! || isDisabled}
                onChange={(_event, option) => {
                  const selectedOption = option as TransactionTypeOptions;
                  onTransactionTypeChange(selectedOption);
                  setUserDefaultValues();
                }}
                required
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <FormHookDropdown
                label="Business Unit"
                placeholder="Select"
                options={businessUnitOptions}
                name="businessUnitId"
                disabled={isBusinessUnitExist || isDisabled}
                required
              />
            </Stack.Item>
          </Stack>
          {isPCardTransaction && (
            <FormHookDropdown
              label="Card Holder"
              placeholder="Select"
              options={companyCardHoldersOptions}
              name="cardHolderId"
              disabled={disableCardHolder || isDisabled}
              onChange={(_event, option) => {
                const selectedOption = option as CardHolderOptions;
                setValue('departmentId', selectedOption.departmentId);
              }}
              required
            />
          )}
          {transactionLayoutType !== TransactionLayout.CREDIT_CARD_INVOICE && (
            <FormHookAutoComplete
              name="vendorReference"
              label={labelsState?.vendor}
              placeholder={
                labelsState?.vendor
                  ? labelsState?.vendor
                  : labelsState?.vendorPlaceHolder
              }
              disabled={isDisabled}
              lookUpCalloutWidth={400}
              transactionTypeId={values.transactionTypeId!}
              id={'vendorFormikField'}
              lookupData={lookupName}
              clearTextField={clearVendorReference}
            />
          )}

          {transactionLayoutType === TransactionLayout.CREDIT_CARD_INVOICE && (
            <SelectField
              name="vendorReference"
              options={cardHolderOptions}
              label={labelsState?.vendor}
              disabled={isDisabled}
              info={cardHolderOption?.cardNumber}
              placeholder={'Enter card holder'}
              isCheck={values.cardHolderId !== null}
              onSelect={(item) => {
                setValue('vendorReference', item.text);
                setValue('cardHolderId', item.key.toString());
              }}
              onRemoveSelection={() => {
                setValue('cardHolderId', null);
              }}
            />
          )}

          <FormHookTextField
            name="description"
            label="Description"
            placeholder="Description"
            multiline={isSigningDocument}
            rows={isSigningDocument ? 3 : undefined}
            disabled={isDisabled}
            required
          />
          {!isSigningDocument && (
            <>
              {showBankDetails ? (
                <BankDetails disabled={isDisabled} />
              ) : (
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  <Stack.Item grow={1}>
                    <FormHookTextField
                      name="invoiceNumber"
                      label={labelsState?.invoiceNumber}
                      placeholder={labelsState?.invoiceNumber}
                      disabled={isDisabled}
                    />
                  </Stack.Item>
                  <Stack.Item grow={1}>
                    <Stack />
                  </Stack.Item>
                </Stack>
              )}
            </>
          )}

          {transactionLayoutType ===
            TransactionLayout.PETTY_CASH_ELAPSED_DAYS && (
            <Stack horizontal tokens={{ childrenGap: 20 }}>
              <Stack.Item grow={1}>
                <FormHookDatePicker
                  name="approvalTriggerDate"
                  placeholder="Select a date"
                  ariaLabel="Receipts Dated"
                  label={'Receipts Dated'}
                  firstDayOfWeek={DayOfWeek.Sunday}
                  showWeekNumbers
                  firstWeekOfYear={1}
                  showMonthPickerAsOverlay
                  disabled={isDisabled}
                  showGoToToday
                />
                {!isNew && _elapsedTriggerDays && _elapsedTriggerDays > 0 && (
                  <Text className={style.textColor}>
                    Days elapsed {_elapsedTriggerDays}
                  </Text>
                )}
              </Stack.Item>
              <Stack.Item grow={1}>
                <Stack />
              </Stack.Item>
            </Stack>
          )}

          {transactionLayoutType === TransactionLayout.PETTY_CASH && (
            <Stack horizontal tokens={{ childrenGap: 20 }}>
              <Stack.Item grow={1}>
                <FormHookAmount
                  name="reimburseAmount"
                  label="Reimburse amount"
                  placeholder="Enter Reimburse Amount"
                  disabled={isDisabled}
                  decimalScale={2}
                  allowNegative={true}
                />
              </Stack.Item>
              <Stack.Item grow={1}>
                <ReimburseAccountField
                  name="reimburseAccountReference"
                  label="Account"
                  placeholder="Enter Account"
                  disabled={isDisabled}
                  businessUnitId={watch.businessUnitId || null}
                  id={`reimburseAccountReference`}
                />
              </Stack.Item>
            </Stack>
          )}
        </Stack>
        <Stack grow={2} tokens={{ childrenGap: 20 }}>
          <Stack>
            <Label>
              <TooltipHost
                directionalHint={DirectionalHint.leftCenter}
                content={
                  isUserAssigned
                    ? ''
                    : isUserControlled
                    ? 'Approver exists but no user is assigned to this department'
                    : 'No user is assigned to this department'
                }
              >
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  {!isUserAssigned && (
                    <Icon
                      iconName={'UserWarning'}
                      className={style.iconColor}
                    />
                  )}
                  <Label disabled={isDisabled}>Department</Label>
                </Stack>
              </TooltipHost>
            </Label>
            <FormHookDropdown
              placeholder="Select"
              options={departmentOptions}
              name="departmentId"
              disabled={isDisabled}
            />
          </Stack>
          {transactionLayoutType ===
            TransactionLayout.SIGNING_AMOUNT_DOCUMENT && (
            <FormHookAmount
              disabled={isDisabled}
              name="controlTotalAmount"
              label={'Weekly Limit'}
            />
          )}
          {transactionLayoutType ===
            TransactionLayout.SIGNING_AMOUNT_DOCUMENT && (
            <FormHookDropdown
              label="Currency"
              placeholder="Select"
              options={currencyOptions}
              onChange={(_event, options) => {
                const selectedOptions = options as CurrencyTypeOptions;
                setValue('currency.isoCode', selectedOptions.isoCode);
                setValue(
                  'currencyId',
                  parseInt(selectedOptions.key.toString())
                );
              }}
              name="currencyId"
              disabled={isDisabled}
              required
            />
          )}
          {!isSigningDocument && (
            <>
              <FormHookDatePicker
                name="invoiceDate"
                placeholder="Select a date"
                ariaLabel="Invoice date"
                label={labelsState?.invoiceDate}
                firstDayOfWeek={DayOfWeek.Sunday}
                showWeekNumbers
                firstWeekOfYear={1}
                showMonthPickerAsOverlay
                disabled={isDisabled}
                showGoToToday
              />
              <FormHookDropdown
                label="Accounting Period"
                placeholder="Select"
                options={accountingPeriodOptions}
                name="corporatePeriodId"
                disabled={isDisabled}
              />
              <FormHookDropdown
                label="Currency"
                placeholder="Select"
                options={currencyOptions}
                onChange={(_event, options) => {
                  const selectedOptions = options as CurrencyTypeOptions;
                  setValue('currency.isoCode', selectedOptions.isoCode);
                  setValue(
                    'currencyId',
                    parseInt(selectedOptions.key.toString())
                  );
                }}
                name="currencyId"
                disabled={isDisabled}
                required
              />
            </>
          )}
          {receiptTotal && (
            <TextField
              value={receiptTotal!}
              label={'Receipts Total'}
              placeholder={'Receipts Total'}
              disabled
            />
          )}
          {!isSigningDocument && (
            <>
              <FormHookAmount
                name="controlTotalAmount"
                label={labelsState?.invoiceTotal}
                placeholder={labelsState?.invoiceTotal}
                disabled={isPCardTransaction || isDisabled}
                fixedDecimalScale
                decimalScale={2}
                allowNegative={true}
                required
              />
              {totalSum && totalSum.minus(controlledAmount || 0).abs() && (
                <Stack horizontal tokens={{ childrenGap: 30 }}>
                  {totalSum && (
                    <Stack horizontal>
                      <Text className={style.textColor}>{`Allocated:`}</Text>
                      <AmountTextView
                        className={clsx(style.textColor, style.amount)}
                        value={totalSum.toString()}
                      />
                    </Stack>
                  )}
                  {
                    <Stack horizontal>
                      <Text className={style.textColor}>{`Difference:`}</Text>
                      <AmountTextView
                        className={clsx(style.textColor, style.amount)}
                        value={totalSum
                          .minus(controlledAmount || 0)
                          .abs()
                          .toString()}
                      />
                    </Stack>
                  }
                </Stack>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
};
