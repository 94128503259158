import { useLazyQuery } from '@apollo/client';
import {
  RefCode2,
  RefCode2Variables,
} from 'documents/documentAssignment/folder/view/FormView/AccountingView/CustomFields/AutoCompleteRefCode2/__generated__/RefCode2';
import { loader } from 'graphql.macro';
import React, { useRef, useState } from 'react';
import {
  AutoCompleteFF,
  AutoCompleteFFTextFieldProps,
  refType,
} from '../AutoCompleteFF';
import { useController } from 'react-hook-form';

const REFERENCE_CODE2 = loader('../../../graphql/Reference2Codes.graphql');

export const AutoCompleteFF2: React.FC<AutoCompleteFFTextFieldProps> = ({
  columnData,
  index,
  ffValue,
  ...props
}) => {
  const ref = useRef<refType>();
  const [fieldValue, setFieldValue] = useState<string | null>(null);
  const { field } = useController({
    name: props.name,
  });
  const { value, onChange } = { ...field };
  const [input, setInput] = useState<string>(value);

  const [fetchReferenceCodes, { data, loading }] = useLazyQuery<
    RefCode2,
    RefCode2Variables
  >(REFERENCE_CODE2, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    onCompleted: (data) => {
      if (data.reference2Codes?.nodes.length) {
        const position = data.reference2Codes.nodes.findIndex(
          (item) =>
            item.referenceCode.toUpperCase() === fieldValue?.toUpperCase()
        );
        if (position !== -1) {
          onChange(fieldValue?.toUpperCase());
          ref?.current?.onClearFieldValue();
        }
      } else ref?.current?.onClearFieldValue();
    },
  });
  return (
    <>
      <AutoCompleteFF
        width={columnData?.maxWidth}
        id={`${columnData?.key}${index}`}
        onValueChange={(data) => {
          setFieldValue(data);
          fetchReferenceCodes({
            variables: {
              Ref2CodeFilter: {
                referenceCode: {
                  startsWithInsensitive: data,
                },
              },
            },
          });
        }}
        ffValue={ffValue}
        index={index}
        {...props}
        loading={loading}
        referenceCodeData={data?.reference2Codes?.nodes}
        ref={ref}
        input={input}
        setInput={setInput}
      />
    </>
  );
};
