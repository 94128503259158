import {
  ApprovalHistoryItemsOrderBy,
  TransactionLayout,
} from 'common/types/globalTypes';

export const isLayoutPaycycle = (layoutType: TransactionLayout) => {
  switch (layoutType) {
    case TransactionLayout.CHECK_PAYMENT:
    case TransactionLayout.WIRE_PAYMENT:
    case TransactionLayout.SERVICE_PAYMENT:
    case TransactionLayout.ACH_PAYMENT:
      return true;
    default:
      return false;
  }
};
export const isVendorLink = (layoutType: TransactionLayout) => {
  return !(
    layoutType === TransactionLayout.PO_SYSTEM_APPROVAL ||
    layoutType === TransactionLayout.TA_SYSTEM_APPROVAL ||
    layoutType === TransactionLayout.BATCH_TRANSACTION_APPROVAL ||
    layoutType === TransactionLayout.MISCELLANEOUS_APPROVAL ||
    isLayoutPaycycle(layoutType)
  );
};

export const getApprovalOrderBy = (layoutType: string | null | undefined) => {
  switch (layoutType) {
    case TransactionLayout.BATCH_TRANSACTION_APPROVAL:
      return [ApprovalHistoryItemsOrderBy.ITEM_DESCRIPTION_5_ASC];
    case TransactionLayout.PO_SYSTEM_APPROVAL:
      return [
        ApprovalHistoryItemsOrderBy.REFERENCE_CODE_1_ASC,
        ApprovalHistoryItemsOrderBy.ITEM_DESCRIPTION_3_ASC,
        ApprovalHistoryItemsOrderBy.ITEM_UNIT_1_ASC,
        ApprovalHistoryItemsOrderBy.ITEM_DESCRIPTION_1_ASC,
        ApprovalHistoryItemsOrderBy.ITEM_DESCRIPTION_2_ASC,
      ];
    case TransactionLayout.TA_SYSTEM_APPROVAL:
      return [
        ApprovalHistoryItemsOrderBy.ITEM_DESCRIPTION_2_ASC,
        ApprovalHistoryItemsOrderBy.ITEM_DESCRIPTION_1_ASC,
      ];
    case TransactionLayout.AP_INVOICE:
      return [ApprovalHistoryItemsOrderBy._CREATED_DATE_ASC];
    case TransactionLayout.PO_SYSTEM_APPROVAL_ACCOUNTING:
      return [ApprovalHistoryItemsOrderBy.ITEM_UNIT_2_ASC];
    default:
      return [
        ApprovalHistoryItemsOrderBy.ITEM_DESCRIPTION_3_ASC,
        ApprovalHistoryItemsOrderBy.ITEM_UNIT_1_ASC,
        ApprovalHistoryItemsOrderBy.ITEM_DESCRIPTION_1_ASC,
        ApprovalHistoryItemsOrderBy.ITEM_DESCRIPTION_2_ASC,
      ];
  }
};
