import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  inputContainers: {
    marginTop: 12,
    marginBottom: 24,
  },
  inputContainerLabel: {
    fontWeight: 'bold',
    marginLeft: 14,
    padding: 10,
    flex: 1,
  },
  inputContainerLabel2: {
    fontWeight: 'bold',
    marginLeft: 14,
    padding: 10,
  },
  textInputConatiner: {
    flex: 1,
    marginRight: 24,
    marginLeft: 24,
    paddingBottom: 8,
    overflow: 'hidden',
  },
  textNotesInputConatiner: {
    flex: 1,
    marginRight: 24,
    marginLeft: 24,
    paddingBottom: 8,
  },
  twoInputContainer: {
    height: 60,
  },
  firstInputContainer: {
    flex: 1,
  },
  secondInputContainer: {
    flex: 1,
  },
  columnIcon: {
    height: 65,
    width: 1,
    backgroundColor: '#a9a9a9',
  },
  currency: {
    marginRight: 6,
    marginLeft: 24,
    paddingBottom: 8,
    fontWeight: 'bold',
  },
  poNumber: {
    fontSize: FontSizes.size20,
  },
  flex50: {
    flex: 0.5,
  },
}));
