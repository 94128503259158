import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import {
  Checkbox,
  FontSizes,
  FontWeights,
  ICheckboxStyles,
  IDetailsRowProps,
  IRenderFunction,
  IconButton,
  PrimaryButton,
  SelectionMode,
  Stack,
  Text,
} from '@fluentui/react';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { InfiniteList } from 'common/components/InfiniteList';
import { RequestMessageModal } from 'common/components/RequestMessageModal';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalRequestInput,
  EntityDeleteInput,
  TravelerFilter,
  TravelersOrderBy,
} from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { filterOptionProps, ListFilters } from '../Filters';
import { _renderItemColumn } from './coloumn.render';
import { columns } from './column.data';
import { toFilterVariable, toOrderByVariable } from './utils';
import {
  Travelers,
  TravelersVariables,
  Travelers_travelers_nodes,
} from './__generated__/Travelers';
import { useStyles } from './index.styles';
import { EntityAction, EntityType } from 'common/types/utility';
import {
  TravelerDelete,
  TravelerDeleteVariables,
} from 'common/graphql/__generated__/TravelerDelete';
import {
  TravelerApprovalCreate,
  TravelerApprovalCreateVariables,
} from 'common/graphql/__generated__/TravelerApprovalCreate';
import clsx from 'clsx';
import { ExportTravelers } from './ExportTravelers';
const TRAVELERS = loader('./Travelers.graphql');
const TRAVELER_DELETE = loader(
  '../../../../common/graphql/TravelerDelete.graphql'
);
const TRAVELER_APPROVAL_CREATE = loader(
  '../../../../common/graphql/TravelerApprovalCreate.graphql'
);

export type TravelerRowItem = Travelers_travelers_nodes & {
  name: string | undefined | null;
  userOccupationTitle: string | null | undefined;
  travelPolicyDescription: string | null | undefined;
  state: string | undefined | null;
  country: string | undefined;
  cityName: string | null;
  addressLine1: string | null;
  status: string | null | undefined;
  departmentName: string | null;
};

const checkBoxStyle: ICheckboxStyles = {
  label: {
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.size10,
    color: '#a9a9a9',
  },
};

export const TravelersList: React.FC = () => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const { addToast } = useToasts();
  const result: number[] = [];
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [filterOptions, setFilterOptions] = useState<filterOptionProps>({
    filterTypes: [],
    startsWith: true,
  });
  const [selectedRows, setSelectedRows] = useState<TravelerRowItem[]>([]);
  const [isAllRequestChecked, setIsAllRequestlChecked] = useState(false);
  const [requestedIndices, setRequestedIndices] = useState<number[]>([]);

  const {
    data: travelersData,
    loading,
    variables,
    networkStatus,
    refetch,
    fetchMore,
  } = useQuery<Travelers, TravelersVariables>(TRAVELERS, {
    variables: {
      first: TABLE_ROWS,
      orderBy: [
        TravelersOrderBy.COMPANY_OR_LAST_NAME_ASC,
        TravelersOrderBy.FIRST_NAME_ASC,
      ],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [deleteTraveler] = useMutation<TravelerDelete, TravelerDeleteVariables>(
    TRAVELER_DELETE,
    { errorPolicy: 'all' }
  );

  const [travelerApprovalCreate, { loading: travelerApprovalCreateLoading }] =
    useMutation<TravelerApprovalCreate, TravelerApprovalCreateVariables>(
      TRAVELER_APPROVAL_CREATE,
      { errorPolicy: 'all' }
    );

  const refetching = loading && networkStatus !== NetworkStatus.fetchMore;

  const transformedData: TravelerRowItem[] | undefined = refetching
    ? undefined
    : travelersData?.travelers?.nodes?.map((traveler) => {
        const { country, stateRegion, addressLine1, cityName } = {
          ...traveler?.travelerAddressesByTravelerId?.nodes[0],
        };
        return {
          ...traveler,
          travelPolicyDescription: traveler.travelPolicy?.description,
          userOccupationTitle: traveler.userOccupation?.userOccupationTitle,
          status: traveler.statusType?.statusType,
          country: country?.country,
          state: stateRegion?.stateRegionDescription,
          name: traveler._sortName,
          addressLine1: addressLine1,
          cityName: cityName,
          departmentName: traveler.department?.name || '',
        };
      });

  const showRequestAll = transformedData?.some(
    (traveler) => traveler._isStagedApprovalRequest
  );

  const _onColumnClick = useCallback(
    async (_ev?: React.MouseEvent<HTMLElement>, clickedColumn?: ColumnData) => {
      if (clickedColumn) {
        const { newColumns, desc } = getSortedColumns(
          clickedColumn,
          gridColumns
        );

        setGridColumns(newColumns);
        const sort: SortOrder = {
          column: clickedColumn.key,
          direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
        };
        refetch({ after: null, orderBy: toOrderByVariable(sort) });
      }
    },
    [gridColumns, refetch]
  );
  const _onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }
    const item: TravelerRowItem = { ...props.item };
    const { _urgencyLevel } = { ...item };
    const newProps: IDetailsRowProps | undefined = props
      ? {
          ...props,
          className: clsx(
            styles.rowBaseStyle,
            _urgencyLevel === 0 ? commonStyles.urgentRow : styles.rowBaseStyle
          ),
        }
      : undefined;

    return <>{defaultRender?.(newProps)}</>;
  };

  const onRefresh = () => {
    refetch();
  };

  const onFilterChange = (filterOptions: filterOptionProps) => {
    setFilterOptions(filterOptions);
    const filterArray: TravelerFilter | undefined = filterOptions.filterTypes
      ?.length
      ? ({ and: toFilterVariable(filterOptions) } as TravelerFilter)
      : undefined;
    refetch({ ...variables, filter: filterArray! });
  };

  const onLoadMore = () => {
    const getTravelersVariables: TravelersVariables = {
      ...variables,
      after: travelersData?.travelers?.pageInfo.endCursor,
    };
    fetchMore({ variables: getTravelersVariables });
  };

  const _deleteTraveler = async () => {
    const deletableData: EntityDeleteInput[] = selectedRows
      .filter((item) => item._isDeletable)
      .map((obj) => {
        return { id: obj.id, rowTimestamp: obj._rowTimestamp! };
      });
    const { errors } = await deleteTraveler({
      variables: { input: { entityDelete: deletableData } },
      update: (cache, { data }) => {
        const deletedIds = data?.travelerDelete?.deletedEntities?.map(
          (entity) => entity?.id
        );
        if (deletedIds) {
          const filteredList = travelersData?.travelers?.nodes.filter(
            (emp) => deletedIds.indexOf(emp.id) === -1
          );
          const newData: Travelers = {
            travelers: {
              pageInfo: travelersData?.travelers?.pageInfo!,
              nodes: filteredList!,
            },
          };
          cache.writeQuery<Travelers, TravelersVariables>({
            query: TRAVELERS,
            variables,
            data: newData,
          });
        }
      },
    });
    if (errors?.length)
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
    else
      addToast('Traveler deleted Successfully', {
        appearance: 'success',
      });
  };

  useEffect(() => {
    if (requestedIndices.length > 0) {
      const stagedRowsLength = selectedRows.filter(
        (item) => item._isStagedApprovalRequest
      ).length;
      if (stagedRowsLength !== requestedIndices.length && isAllRequestChecked) {
        setIsAllRequestlChecked(false);
      } else {
        if (requestedIndices.length === selectedRows.length) {
          setIsAllRequestlChecked(true);
        } else {
          if (isAllRequestChecked) setIsAllRequestlChecked(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Travelers</Text>
          <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 10 }}>
            <ExportTravelers
              selectedRows={selectedRows}
              isSelectedAllRows={
                selectedRows.length === transformedData?.length
              }
            />
            <ListFilters
              filterOptions={filterOptions}
              onFilterChange={onFilterChange}
            />
            <IconButton
              iconProps={{ iconName: 'refresh' }}
              onClick={onRefresh}
            />
            {showRequestAll && (
              <RequestMessageModal
                entityType={EntityType.Traveler}
                action={EntityAction.Request}
                disabled={
                  !selectedRows.some(
                    (selected) => selected._isStagedApprovalRequest
                  )
                }
                visible={selectedRows.length > 0}
                multiple={{
                  validCount: selectedRows.filter(
                    (selected) => selected._isStagedApprovalRequest
                  ).length,
                  invalidNames: selectedRows
                    .filter((selected) => !selected._isStagedApprovalRequest!)
                    .map((cannotDelete) => cannotDelete.name!),
                }}
                buttonProps={{ text: 'Request Approval' }}
                onConfirm={async (
                  requestComment: string | null,
                  requiredDate: string | null
                ) => {
                  const inputVariables: ApprovalRequestInput[] = selectedRows
                    .filter((item) => item._isStagedApprovalRequest)
                    .map((requestInput) => ({
                      entityId: requestInput.id!,
                      rowTimestamp: requestInput._rowTimestamp!,
                      comments: requestComment,
                      requiredDate: requiredDate ? requiredDate : undefined,
                    }));
                  if (inputVariables.length > 0) {
                    const { errors } = await travelerApprovalCreate({
                      variables: {
                        input: {
                          entityApproval: inputVariables,
                        },
                      },
                      awaitRefetchQueries: true,
                      refetchQueries: [
                        {
                          query: TRAVELERS,
                          variables: variables,
                        },
                      ],
                    });
                    if (errors?.length)
                      addToast(errors[0].message, {
                        appearance: 'error',
                      });
                    else {
                      addToast('Request sent for approvals', {
                        appearance: 'success',
                      });
                      setIsAllRequestlChecked(false);
                      setRequestedIndices([]);
                    }
                  }
                }}
                isLoading={travelerApprovalCreateLoading}
              />
            )}
            <ActionMessageModal
              entityType={EntityType.Traveler}
              action={EntityAction.Remove}
              disabled={!selectedRows.some((selected) => selected._isDeletable)}
              visible={selectedRows.length > 0}
              multiple={{
                validCount: selectedRows.filter(
                  (selected) => selected._isDeletable
                ).length,
                invalidNames: selectedRows
                  .filter((selected) => !selected._isDeletable)
                  .map((cannotDelete) => cannotDelete._sortName || ''),
              }}
              onConfirm={async () => await _deleteTraveler()}
            />
            <PrimaryButton
              onClick={() => {
                history.push('/account-management/travelers/traveler');
              }}
              iconProps={{ iconName: 'Add' }}
              text="New Traveler"
            />
          </Stack>
        </Stack>
        <Stack
          horizontal
          tokens={{ childrenGap: 12 }}
          className={styles.checkboxContainer}
        >
          {showRequestAll && (
            <Checkbox
              boxSide="end"
              styles={checkBoxStyle}
              checked={isAllRequestChecked}
              label="Request All"
              onChange={(_, value) => {
                setIsAllRequestlChecked(!isAllRequestChecked!);
                if (value) {
                  transformedData?.forEach((data, index) =>
                    data._isStagedApprovalRequest ? result.push(index) : null
                  );
                  setRequestedIndices(result);
                } else {
                  setRequestedIndices([]);
                }
              }}
            />
          )}
        </Stack>
      </Stack>

      <InfiniteList<TravelerRowItem>
        loading={loading}
        hasNextPage={travelersData?.travelers?.pageInfo.hasNextPage}
        items={transformedData}
        selectionMode={SelectionMode.multiple}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onColumnHeaderClick={_onColumnClick}
        onSelectionChanged={setSelectedRows}
        requestedIndices={requestedIndices}
        onLoadMore={onLoadMore}
        onRenderRow={_onRenderRow}
      />
    </>
  );
};
