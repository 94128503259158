import {
  Icon,
  IRenderFunction,
  ITextFieldProps,
  Link,
  Spinner,
  Stack,
  Text,
  TextField,
  TooltipHost,
  useTheme,
} from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import { RefCode1_reference1Codes_nodes } from 'documents/documentAssignment/folder/view/FormView/AccountingView/CustomFields/AutoCompleteRefCode1/__generated__/RefCode1';
import _ from 'lodash';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useController } from 'react-hook-form';
import { useStyles } from './index.styles';
import { ReferenceCodeOptionsType } from './interface';
import { LookUpCallout } from './Lookup';
import { RequiredNameProps } from 'common/types/utility';
import { DocumentNode } from 'graphql';
import { ColumnData } from 'common/components/SearchBar';

export type AutoCompleteFFTextFieldProps =
  RequiredNameProps<ITextFieldProps> & {
    index: number;
    query?: DocumentNode;
    columnData?: ColumnData;
    ffValue: string | undefined | null;
    referenceCode: string | null | undefined;
    setReferenceCode: React.Dispatch<
      React.SetStateAction<string | null | undefined>
    >;
    disabled?: boolean;
  };

export interface refType {
  onClearFieldValue: () => void;
}
interface AutoCompleteFFProps extends AutoCompleteFFTextFieldProps {
  onValueChange: (value: string) => void;
  loading: boolean;
  referenceCodeData: RefCode1_reference1Codes_nodes[] | undefined;
  ref: React.MutableRefObject<refType>;
  ffValue: string | undefined | null;
  input: string;
  setInput: React.Dispatch<React.SetStateAction<string>>;
}

export const AutoCompleteFF = forwardRef((props: AutoCompleteFFProps) => {
  const {
    id,
    referenceCodeData,
    width,
    disabled,
    onValueChange,
    loading,
    ref,
    referenceCode,
    setReferenceCode,
    input,
    setInput,
  } = { ...props };
  const theme = useTheme();
  const styles = useStyles();
  const {
    field,
    fieldState: { error },
  } = useController({
    name: props.name,
  });
  useImperativeHandle(ref, () => ({
    onClearFieldValue,
  }));
  const { onChange, value } = { ...field };
  const [calloutVisible, setCalloutVisible] = useState(false);

  const [referenceCodeOptions, setReferenceCodeOptions] = useState<
    ReferenceCodeOptionsType[]
  >([]);

  useEffect(() => {
    if (referenceCodeData && referenceCodeData?.length > 0) {
      const refOptions: ReferenceCodeOptionsType[] =
        referenceCodeData.map((item, index) => ({
          key: index,
          text: item.name,
          referenceCode: item.referenceCode,
        })) || [];
      setReferenceCodeOptions(refOptions);
    }
  }, [referenceCodeData]);
  const onClearFieldValue = () => {
    setReferenceCode('');
  };
  const commonStyles = useCommonStyles();
  const onRenderSuffix: IRenderFunction<ITextFieldProps> = (props) => {
    if (loading) return <Spinner />;
    else
      return (
        <button
          className={styles.customDropdownIcon}
          onClick={() => {
            callbackLookUp('', () => {
              if (!calloutVisible) setCalloutVisible(true);
            });
          }}
          tabIndex={-1}
        >
          <Icon iconName="ChevronDown" />
        </button>
      );
  };
  const textfieldProps: ITextFieldProps | undefined = { onRenderSuffix };
  const callbackLookUp = useCallback(
    _.debounce((value, queryCalled) => {
      onValueChange(value);
      queryCalled();
    }, 350),
    [value]
  );
  return (
    <Stack style={{ marginTop: error?.message ? 0 : 3 }}>
      <TooltipHost content={referenceCode!}>
        <Stack horizontal horizontalAlign="space-between" style={{}}>
          <TextField
            {...props}
            autoComplete={'off'}
            errorMessage={error?.message}
            value={input || ''}
            onBlur={() => {
              if (input !== value) setInput('');
            }}
            onChange={(_event, value) => {
              if (value) {
                setInput(value.toUpperCase());
                callbackLookUp(value.toUpperCase(), () => {
                  if (!calloutVisible) setCalloutVisible(true);
                });
              } else {
                onChange(null);
                setInput('');
                setCalloutVisible(false);
              }
            }}
            underlined
            disabled={disabled}
            {...textfieldProps}
            styles={{
              suffix: { backgroundColor: theme.palette.white },
              fieldGroup: { width: width },
            }}
          />
        </Stack>
        <Text style={{ color: theme.palette.white }}>{error?.message}</Text>
      </TooltipHost>
      {calloutVisible && !loading && referenceCodeOptions.length! > 0 && (
        <LookUpCallout
          lookUpCalloutWidth={300}
          onDismiss={() => setCalloutVisible(false)}
          targetID={`#${id!}`}
        >
          {referenceCodeOptions.map((data, index) => {
            return (
              <Link
                onClick={() => {
                  onChange(data.referenceCode);
                  setInput(data.referenceCode ?? '');
                  setReferenceCode(data.text);
                  setCalloutVisible(false);
                }}
                className={styles.resultItem}
                key={index.toString()}
              >
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  <Text className={commonStyles.colorThemePrimary}>
                    {data.referenceCode}
                  </Text>
                  <Text style={{ width: 220 }} nowrap>
                    {data.text}
                  </Text>
                </Stack>
              </Link>
            );
          })}
        </LookUpCallout>
      )}
    </Stack>
  );
});
