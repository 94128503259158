import { useLazyQuery } from '@apollo/client';
import {
  RefCode3,
  RefCode3Variables,
} from 'documents/documentAssignment/folder/view/FormView/AccountingView/CustomFields/AutoCompleteRefCode3/__generated__/RefCode3';
import { loader } from 'graphql.macro';
import React, { useRef, useState } from 'react';
import {
  AutoCompleteFF,
  AutoCompleteFFTextFieldProps,
  refType,
} from '../AutoCompleteFF';
import { useController } from 'react-hook-form';
const REFERENCE_CODE3 = loader('../../../graphql/Reference3Codes.graphql');

export const AutoCompleteFF3: React.FC<AutoCompleteFFTextFieldProps> = ({
  columnData,
  index,
  ...props
}) => {
  const ref = useRef<refType>();
  const [fieldValue, setFieldValue] = useState<string | null>(null);
  const { field } = useController({
    name: props.name,
  });
  const { value,onChange } = { ...field };
  const [input, setInput] = useState<string>(value);
  const [fetchReferenceCodes, { data, loading }] = useLazyQuery<
    RefCode3,
    RefCode3Variables
  >(REFERENCE_CODE3, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    onCompleted: (data) => {
      if (data.reference3Codes?.nodes.length) {
        const position = data.reference3Codes.nodes.findIndex(
          (item) =>
            item.referenceCode.toUpperCase() === fieldValue?.toUpperCase()
        );
          if (position !== -1) {
            onChange(fieldValue?.toUpperCase());
            ref?.current?.onClearFieldValue();
          }
      } else ref?.current?.onClearFieldValue();
    },
  });
  return (
    <>
      <AutoCompleteFF
        width={columnData?.maxWidth}
        id={`${columnData?.key}${index}`}
        onValueChange={(data) => {
          setFieldValue(data);
          fetchReferenceCodes({
            variables: {
              Ref3CodeFilter: {
                referenceCode: {
                  startsWithInsensitive: data,
                },
              },
            },
          });
        }}
        index={index}
        {...props}
        loading={loading}
        referenceCodeData={data?.reference3Codes?.nodes}
        ref={ref}
        input={input}
        setInput={setInput}
      />
    </>
  );
};
