import {
  ColumnActionsMode,
  IColumn,
  IDetailsColumnStyles,
} from '@fluentui/react';
import { TransactionLayout } from 'common/types/globalTypes';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';

const commonProps: Partial<IColumn> = {
  isResizable: true,
  columnActionsMode: ColumnActionsMode.disabled,
};

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

const columnsPO = new Map<string, IColumn>([
  [
    'itemDescription1',
    {
      key: 'itemDescription1',
      name: 'Item#',
      fieldName: 'itemDescription1',
      minWidth: 100,
      ...commonProps,
    },
  ],
  [
    'itemDescription2',
    {
      key: 'itemDescription2',
      name: 'Description',
      fieldName: 'itemDescription2',
      minWidth: 200,
      ...commonProps,
    },
  ],
  [
    'itemDescription3',
    {
      key: 'itemDescription3',
      name: 'Account#',
      fieldName: 'itemDescription3',
      minWidth: 120,
      ...commonProps,
    },
  ],
  [
    'itemUnit1',
    {
      key: 'itemUnit1',
      name: 'Qty',
      fieldName: 'itemUnit1',
      minWidth: 100,
      styles: rightAlignHeaderStyle,
      ...commonProps,
    },
  ],
  [
    'itemDescription4',
    {
      key: 'itemDescription4',
      name: 'Term',
      fieldName: 'itemDescription4',
      minWidth: 100,
      ...commonProps,
    },
  ],
  [
    'itemDescription5',
    {
      key: 'itemDescription5',
      name: 'Unit Price',
      fieldName: 'itemDescription5',
      minWidth: 100,
      styles: rightAlignHeaderStyle,
      ...commonProps,
    },
  ],
  [
    'itemAmount1',
    {
      key: 'itemAmount1',
      name: 'Total',
      fieldName: 'itemAmount1',
      minWidth: 100,
      styles: rightAlignHeaderStyle,
      ...commonProps,
    },
  ],
]);
const columns = new Map<string, IColumn>([
  [
    'itemDescription1',
    {
      key: 'itemDescription1',
      name: 'Item#',
      fieldName: 'itemDescription1',
      minWidth: 110,
      maxWidth: 110,
      ...commonProps,
    },
  ],
  [
    'itemDescription2',
    {
      key: 'itemDescription2',
      name: 'Description',
      fieldName: 'itemDescription2',
      minWidth: 210,
      maxWidth: 210,
      ...commonProps,
    },
  ],
  [
    'referenceCode6',
    {
      key: 'referenceCode6',
      name: 'CO',
      fieldName: 'referenceCode6',
      minWidth: 50,
      maxWidth: 50,
      ...commonProps,
    },
  ],
  [
    'referenceCode7',
    {
      key: 'referenceCode7',
      name: 'LOC',
      fieldName: 'referenceCode7',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'itemDescription3',
    {
      key: 'itemDescription3',
      name: 'Account',
      fieldName: 'itemDescription3',
      minWidth: 70,
      maxWidth: 70,
      ...commonProps,
    },
  ],
  [
    'itemDescription4',
    {
      key: 'itemDescription4',
      name: 'Project',
      fieldName: 'itemDescription4',
      minWidth: 60,
      maxWidth: 60,
      ...commonProps,
    },
  ],
  [
    'setReference',
    {
      key: 'setReference',
      name: 'Set',
      fieldName: 'setReference',
      minWidth: 45,
      maxWidth: 45,
      ...commonProps,
    },
  ],

  [
    'itemAmount1',
    {
      key: 'itemAmount1',
      name: 'Amount',
      fieldName: 'itemAmount1',
      minWidth: 110,
      maxWidth: 110,
      styles: rightAlignHeaderStyle,
      ...commonProps,
    },
  ],

  [
    'referenceCode5',
    {
      key: 'referenceCode5',
      name: 'Ins',
      fieldName: 'referenceCode5',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],

  [
    'referenceCode1',
    {
      key: 'referenceCode1',
      name: 'FF1',
      fieldName: 'referenceCode1',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'referenceCode2',
    {
      key: 'referenceCode2',
      name: 'FF2',
      fieldName: 'referenceCode2',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'referenceCode3',
    {
      key: 'referenceCode3',
      name: 'FF3',
      fieldName: 'referenceCode3',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'referenceCode4',
    {
      key: 'referenceCode4',
      name: 'FF4',
      fieldName: 'referenceCode4',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
]);
const columnsGL = new Map<string, IColumn>([
  [
    'itemDescription1',
    {
      key: 'itemDescription1',
      name: 'Item#',
      fieldName: 'itemDescription1',
      minWidth: 110,
      maxWidth: 110,
      ...commonProps,
    },
  ],
  [
    'itemDescription2',
    {
      key: 'itemDescription2',
      name: 'Description',
      fieldName: 'itemDescription2',
      minWidth: 210,
      maxWidth: 210,
      ...commonProps,
    },
  ],
  [
    'referenceCode6',
    {
      key: 'referenceCode6',
      name: 'CO',
      fieldName: 'referenceCode6',
      minWidth: 50,
      maxWidth: 50,
      ...commonProps,
    },
  ],
  [
    'referenceCode7',
    {
      key: 'referenceCode7',
      name: 'LOC',
      fieldName: 'referenceCode7',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'itemDescription4',
    {
      key: 'itemDescription4',
      name: 'Project',
      fieldName: 'itemDescription4',
      minWidth: 60,
      maxWidth: 60,
      ...commonProps,
    },
  ],
  [
    'itemDescription3',
    {
      key: 'itemDescription3',
      name: 'Account',
      fieldName: 'itemDescription3',
      minWidth: 70,
      maxWidth: 70,
      ...commonProps,
    },
  ],

  [
    'setReference',
    {
      key: 'setReference',
      name: 'Set',
      fieldName: 'setReference',
      minWidth: 45,
      maxWidth: 45,
      ...commonProps,
    },
  ],
  [
    'referenceCode1',
    {
      key: 'referenceCode1',
      name: 'FF1',
      fieldName: 'referenceCode1',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'referenceCode2',
    {
      key: 'referenceCode2',
      name: 'FF2',
      fieldName: 'referenceCode2',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'referenceCode3',
    {
      key: 'referenceCode3',
      name: 'FF3',
      fieldName: 'referenceCode3',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'referenceCode4',
    {
      key: 'referenceCode4',
      name: 'FF4',
      fieldName: 'referenceCode4',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'referenceCode5',
    {
      key: 'referenceCode5',
      name: 'Ins',
      fieldName: 'referenceCode5',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'itemAmount1',
    {
      key: 'itemAmount1',
      name: 'Amount',
      fieldName: 'itemAmount1',
      minWidth: 110,
      maxWidth: 110,
      styles: rightAlignHeaderStyle,
      ...commonProps,
    },
  ],
]);
const columnsPSL = new Map<string, IColumn>([
  [
    'itemDescription1',
    {
      key: 'itemDescription1',
      name: 'Item#',
      fieldName: 'itemDescription1',
      minWidth: 110,
      maxWidth: 110,
      ...commonProps,
    },
  ],
  [
    'itemDescription2',
    {
      key: 'itemDescription2',
      name: 'Description',
      fieldName: 'itemDescription2',
      minWidth: 210,
      maxWidth: 210,
      ...commonProps,
    },
  ],
  [
    'itemDescription3',
    {
      key: 'itemDescription3',
      name: 'Account',
      fieldName: 'itemDescription3',
      minWidth: 70,
      maxWidth: 70,
      ...commonProps,
    },
  ],
  [
    'itemDescription4',
    {
      key: 'itemDescription4',
      name: 'Episode',
      fieldName: 'itemDescription4',
      minWidth: 60,
      maxWidth: 60,
      ...commonProps,
    },
  ],
  [
    'referenceCode7',
    {
      key: 'referenceCode7',
      name: 'LOC',
      fieldName: 'referenceCode7',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'setReference',
    {
      key: 'setReference',
      name: 'Set',
      fieldName: 'setReference',
      minWidth: 45,
      maxWidth: 45,
      ...commonProps,
    },
  ],
  [
    'referenceCode5',
    {
      key: 'referenceCode5',
      name: 'Ins',
      fieldName: 'referenceCode5',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'referenceCode1',
    {
      key: 'referenceCode1',
      name: 'FF1',
      fieldName: 'referenceCode1',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'referenceCode2',
    {
      key: 'referenceCode2',
      name: 'FF2',
      fieldName: 'referenceCode2',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'referenceCode3',
    {
      key: 'referenceCode3',
      name: 'FF3',
      fieldName: 'referenceCode3',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'referenceCode4',
    {
      key: 'referenceCode4',
      name: 'FF4',
      fieldName: 'referenceCode4',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'itemAmount1',
    {
      key: 'itemAmount1',
      name: 'Amount',
      fieldName: 'itemAmount1',
      minWidth: 110,
      maxWidth: 110,
      styles: rightAlignHeaderStyle,
      ...commonProps,
    },
  ],
]);

export const getColumns = (
  POLayoutType: TransactionLayout | null | undefined,
  userDefaults: UserDefaults_userDefaults_nodes | undefined
) => {
  let columnArray = [];
  let existingData;

  if (POLayoutType !== TransactionLayout.PO_SYSTEM_APPROVAL_ACCOUNTING) {
    columnArray = Array.from(columnsPO, (data) => data[1]);
    return { columnArray };
  }
  const { layoutType } = {
    ...userDefaults?.distributionLayoutType,
  };
  switch (layoutType) {
    case TransactionLayout.DEFAULT_DISTRIBUTION_LAYOUT:
      columns.delete('itemDescription4');
      columnArray = Array.from(columns, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.GL_LOC_EPISODE_DISTRIBUTION_LAYOUT:
      columnsGL.delete('referenceCode6');
      existingData = columnsGL.get('itemDescription4');
      columnsGL.set('itemDescription4', {
        ...existingData,
        name: 'Episode',
      } as IColumn);
      columnArray = Array.from(columnsGL, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.GL_LOC_DISTRIBUTION_LAYOUT:
      columnsGL.delete('referenceCode6');
      columnsGL.delete('itemDescription4');
      columnArray = Array.from(columnsGL, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.EPISODIC_DISTRIBUTION_LAYOUT:
      existingData = columns.get('itemDescription4');
      columns.set('itemDescription4', {
        ...existingData,
        name: 'Episode',
      } as IColumn);
      columnArray = Array.from(columns, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.GL_CO_LOC_EPISODE_DISTRIBUTION_LAYOUT:
      existingData = columnsGL.get('itemDescription4');
      columnsGL.set('itemDescription4', {
        ...existingData,
        name: 'Episode',
      } as IColumn);
      columnArray = Array.from(columnsGL, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.GL_CO_LOC_DISTRIBUTION_LAYOUT:
      columnsGL.delete('itemDescription4');
      columnArray = Array.from(columnsGL, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.PSL_ENTRY_EPISODIC:
      columnArray = Array.from(columnsPSL, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.PSL_ENTRY_STANDARD:
      columnsPSL.delete('itemDescription4');
      columnArray = Array.from(columnsPSL, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.PSL_ENTRY_SERIES:
      existingData = columnsPSL.get('itemDescription4');
      columnsPSL.set('itemDescription4', {
        ...existingData,
        name: 'Series',
      } as IColumn);
      columnArray = Array.from(columnsPSL, (data) => data[1]);
      return { columnArray };
    default:
      columns.delete('referenceCode6');
      columns.delete('referenceCode7');
      columnArray = Array.from(columns, (data) => data[1]);
      return { columnArray };
  }
};
